import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ECciMsgNotifyAudioSetting,
    ETaskType,
    IComingCall_ClickToCallParams,
    IComingCall_OBPreviewDialerParams_WithJsCallId,
    IComingCallParams_WithJsCallId,
    ICurrentCall,
    IUseAudioParams,
} from '../types';

export interface IState {
    audioStatus: IUseAudioParams;
    incomingCalls: Array<IComingCallParams_WithJsCallId | IComingCall_ClickToCallParams>;
    incomingCall_OBPreviewDialer: Array<IComingCall_OBPreviewDialerParams_WithJsCallId>;
    currentCall: ICurrentCall;
    zoomApps: {
        currentRunningAppId: string;
    };
    messageNotificationSetting: ECciMsgNotifyAudioSetting | null;
    messageNotificationSettingFromPolicy: ECciMsgNotifyAudioSetting | null;
}

const initialState: IState = {
    audioStatus: null,
    incomingCalls: [],
    incomingCall_OBPreviewDialer: [],
    currentCall: null,
    zoomApps: {
        currentRunningAppId: '',
    },
    messageNotificationSetting: null,
    messageNotificationSettingFromPolicy: null,
};

const contactCenterSlice = createSlice({
    name: 'contact-center',
    initialState,
    reducers: {
        addIncomingCall(
            state,
            { payload }: PayloadAction<IComingCallParams_WithJsCallId | IComingCall_ClickToCallParams>,
        ) {
            const { taskSid } = payload;
            const found = state.incomingCalls.find((i) => i.taskSid === taskSid);
            if (found) {
                Object.assign(found, payload);
            } else {
                state.incomingCalls.push(payload);
            }
        },

        addIncomingCall_OBPreviewDialer(
            state,
            { payload }: PayloadAction<IComingCall_OBPreviewDialerParams_WithJsCallId>,
        ) {
            const { taskSid } = payload;
            const found = state.incomingCall_OBPreviewDialer.find((i) => i.taskSid === taskSid);
            if (found) {
                Object.assign(found, payload);
            } else {
                state.incomingCall_OBPreviewDialer.push(payload);
            }
        },

        updateIncomingCall(
            state,
            { payload }: PayloadAction<{ taskSid: string } & Partial<IComingCallParams_WithJsCallId>>,
        ) {
            const { taskSid } = payload;
            const found = state.incomingCalls.find((i) => i.taskSid === taskSid);
            if (found) {
                Object.assign(found, payload);
            }
        },

        updateIncomingCall_OBPreviewDialer(
            state,
            { payload }: PayloadAction<{ taskSid: string } & Partial<IComingCall_OBPreviewDialerParams_WithJsCallId>>,
        ) {
            const { taskSid } = payload;
            const found = state.incomingCall_OBPreviewDialer.find((i) => i.taskSid === taskSid);
            if (found) {
                Object.assign(found, payload);
            }
        },

        upsertIncomingCall(state, { payload }: PayloadAction<IComingCallParams_WithJsCallId>) {
            const { taskSid } = payload;
            const found = state.incomingCalls.find((i) => i.taskSid === taskSid);
            if (found) {
                Object.assign(found, payload);
            }
        },

        removeIncomingCall(state, { payload }: PayloadAction<{ taskSid: string }>) {
            const { taskSid } = payload;
            const index = state.incomingCalls.findIndex((i) => i.taskSid === taskSid);
            if (index > -1) {
                state.incomingCalls.splice(index, 1);
            }
        },

        removeIncomingCall_OBPreviewDialer(state, { payload }: PayloadAction<{ taskSid: string }>) {
            const { taskSid } = payload;
            const index = state.incomingCall_OBPreviewDialer.findIndex((i) => i.taskSid === taskSid);
            if (index > -1) {
                state.incomingCall_OBPreviewDialer.splice(index, 1);
            }
        },

        clearAllIncomingCalls(state) {
            state.incomingCalls = [];
        },

        clearAllIncomingCalls_OBPreviewDialer(state) {
            state.incomingCall_OBPreviewDialer = [];
        },

        stopPlayToneForThisTaskType(state, { payload }: PayloadAction<{ taskType: ETaskType }>) {
            const { taskType } = payload;
            state.incomingCalls
                .filter((call) => taskType === call.taskType)
                .forEach((call) => {
                    call.canPlayRingTone = false;
                });
        },

        stopPlayToneOfOBPreview_Dialer_ForThisTaskType(state, { payload }: PayloadAction<{ taskType: string }>) {
            const { taskType } = payload;
            state.incomingCall_OBPreviewDialer
                .filter((call) => taskType === call.taskType)
                .forEach((call) => {
                    call.canPlayRingTone = false;
                });
        },

        setCurrentCall(state, { payload }: PayloadAction<ICurrentCall>) {
            if (payload) {
                state.currentCall = Object.assign({}, state.currentCall, payload);
            } else {
                state.currentCall = null;
            }
        },

        setAudioStatus(state, { payload }: PayloadAction<IUseAudioParams | null>) {
            if (!payload) {
                state.audioStatus = null;
            } else {
                state.audioStatus = Object.assign({}, payload);
            }
        },

        setCurrentRunningAppId(state, { payload }: PayloadAction<string>) {
            state.zoomApps.currentRunningAppId = payload;
        },

        setMessageNotificationSetting(state, { payload }: PayloadAction<ECciMsgNotifyAudioSetting | null>) {
            state.messageNotificationSetting = payload;
        },

        setMessageNotificationSettingFromPolicy(state, { payload }: PayloadAction<ECciMsgNotifyAudioSetting | null>) {
            state.messageNotificationSettingFromPolicy = payload;
        },
    },
});

const {
    upsertIncomingCall,
    removeIncomingCall,
    removeIncomingCall_OBPreviewDialer,
    addIncomingCall,
    addIncomingCall_OBPreviewDialer,
    updateIncomingCall,
    updateIncomingCall_OBPreviewDialer,
    setCurrentCall,
    clearAllIncomingCalls,
    clearAllIncomingCalls_OBPreviewDialer,
    stopPlayToneForThisTaskType,
    stopPlayToneOfOBPreview_Dialer_ForThisTaskType,
    setCurrentRunningAppId,
    setMessageNotificationSetting,
    setMessageNotificationSettingFromPolicy,
} = contactCenterSlice.actions;

export const actions = contactCenterSlice.actions;

export {
    upsertIncomingCall,
    removeIncomingCall,
    removeIncomingCall_OBPreviewDialer,
    setCurrentCall,
    addIncomingCall,
    addIncomingCall_OBPreviewDialer,
    updateIncomingCall,
    updateIncomingCall_OBPreviewDialer,
    clearAllIncomingCalls,
    clearAllIncomingCalls_OBPreviewDialer,
    stopPlayToneForThisTaskType,
    stopPlayToneOfOBPreview_Dialer_ForThisTaskType,
    setCurrentRunningAppId,
    setMessageNotificationSetting,
    setMessageNotificationSettingFromPolicy,
};

export default contactCenterSlice.reducer;
