import { PresenceType } from '@zoom/zpns-contact-presence';
import {
    CONTACTS_AWAY,
    CONTACTS_BUSY,
    CONTACTS_AVAILABLE,
    CONTACTS_MOBILE,
    CONTACTS_PRESENTING,
    CONTACTS_OOO,
    CONTACTS_OFFLINE,
    CONTACTS_DND_LOWER,
    CONTACTS_IN_ZOOM_MEETING,
    CONTACTS_CALENDAR_EVENT,
    CONTACTS_ON_A_CALL,
    On_CALL_WITH_F,
} from '../../resource';
import type { IUserInfo } from '../../store/common/common-store';
import { getBrowserName } from '../../store/common/common-utils';
import { getDomainFromJid } from '../../utils';

export const NSCommand = {
    Invite: 0,
    Old_Decline: 1,
    Accepted: 2,
    Decline: 3,
    Cancel: 4,
};

export function getPresenceTxt(cls: string, callPartner?: string) {
    switch (cls) {
        case PresenceType.away:
            return CONTACTS_AWAY;
        case PresenceType.busy:
            return CONTACTS_BUSY;
        case PresenceType.available:
            return CONTACTS_AVAILABLE;
        case PresenceType.mobile:
            return CONTACTS_MOBILE;
        case PresenceType.presenting:
            return CONTACTS_PRESENTING;
        case PresenceType.zoommeeting:
            return CONTACTS_IN_ZOOM_MEETING;
        case PresenceType.calendar:
            return CONTACTS_CALENDAR_EVENT;
        case PresenceType.pbx:
            if (callPartner) {
                return On_CALL_WITH_F(callPartner);
            }
            return CONTACTS_ON_A_CALL;
        case PresenceType.dnd:
            return CONTACTS_DND_LOWER;
        case PresenceType.ooo:
        case 'outofoffice':
            return CONTACTS_OOO;
        default:
            return CONTACTS_OFFLINE;
    }
}

export const getXmppConfigFromUserInfo = (
    userInfo: IUserInfo,
): { XMPPServer: string; PINGServer: string; deviceName: string } => {
    const { xmppDomain, jid } = userInfo;
    const serverAddr = `wss://${xmppDomain}/xmpp-websocket`;
    const pingAddr = getDomainFromJid(jid);
    return {
        XMPPServer: serverAddr,
        PINGServer: pingAddr,
        deviceName: getBrowserName() + '_PWA',
    };
};

export const getMeetingTypeEnum = ({ isWebinar }: { isWebinar: boolean }) => {
    /**
     * https://zoomvideo.atlassian.net/wiki/spaces/SDKPlatform/pages/3425799256/ZOOM-805723+M-SDK+Client+All+As+an+unlogged+user+the+webinar+should+not+be+in+onMeetingDeviceListChanged+callback#3.5-Interface%2FAPI-Requirements
     */
    if (isWebinar) {
        return 2;
    } else {
        return 1;
    }
};
