import serviceContainer from '../../container';
import PolicyController from './PolicyTimerController';
import { PolicyName } from '@zoom/zpns-contact-presence';

let controller: PolicyController = null;

export const getPolicyController = () => {
    if (!controller) {
        controller = new PolicyController({
            unsetPolicy: (policyName: PolicyName) => {
                const presenceManager = serviceContainer.getPresenceManager();
                presenceManager.updatePresenceWhenPolicyTimeout(policyName);
            },
        });
    }
    return controller;
};
