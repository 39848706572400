import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { RoutePath } from "../../../routes";
import { IconCalendarSidebar, IconCalendarSidebarFill } from "@zoom/icons-react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setShowCalendarSidebar } from "../../Calendar/redux";
import { useAppSelector } from "../../../store";

const HeaderIconCalendarSidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const showCalendarSidebar = useAppSelector(state => state.calendar.showCalendarSidebar);
    const [calendarSidebarLocked, setCalendarSidebarLocked] = useState(false);


    const handleCalendarSidebarClick = () => {
        if (calendarSidebarLocked) {
            return;
        }
        dispatch(setShowCalendarSidebar(!showCalendarSidebar));
    }

    useEffect(() => {
        const isHomeRoute = !!matchPath(location.pathname, { path: RoutePath.Home, exact: true });
        setCalendarSidebarLocked(isHomeRoute);
    }, [location])

    return <span onClick={handleCalendarSidebarClick} className={classNames('home-header__calendar-sidebar-btn', { 'calendar-sidebar__disable': calendarSidebarLocked })}>
        {(showCalendarSidebar || calendarSidebarLocked) ? <IconCalendarSidebarFill /> : <IconCalendarSidebar />}
    </span>
}

export default HeaderIconCalendarSidebar;