import { isFunction } from 'lodash-es';
import { IBuddyPresnece, ResourceType, ZoomIMPresence, ZoomIMPresenceStatus } from '../../types/outgoing-types';
import eventBus from '../../../../events/eventBus';
import serviceContainer from '../../../../services/container';
import { isDisableInternalPresence } from '../../../../store/common/common-utils';
import { ContactPresence, PresenceShow, PresenceStatus, PresenceType } from '@zoom/zpns-contact-presence';
import { subscribePresenceFor5mins } from '../../../../services/zpns/presence-subscribe';

interface ICtorProps {
    notifyBuddyPresneceChange(param: Array<IBuddyPresnece>): void;
}

const presenceTypeComponets = {
    [PresenceType.available]: {
        show: PresenceShow.online,
        status: PresenceStatus.NA,
    },

    [PresenceType.away]: {
        show: PresenceShow.away,
        status: PresenceStatus.NA,
    },
    [PresenceType.busy]: {
        show: PresenceShow.away,
        status: PresenceStatus.Busy,
    },
    [PresenceType.calendar]: {
        show: PresenceShow.dnd,
        status: PresenceStatus.Meeting,
    },
    [PresenceType.dnd]: {
        show: PresenceShow.xa,
        status: PresenceStatus.NA,
    },

    [PresenceType.ooo]: {
        show: PresenceShow.away,
        status: PresenceStatus.OOO,
    },
    [PresenceType.pbx]: {
        show: PresenceShow.dnd,
        status: PresenceStatus.Pbx,
    },
    [PresenceType.presenting]: {
        show: PresenceShow.dnd,
        status: PresenceStatus.Presenting,
    },
    [PresenceType.zoommeeting]: {
        show: PresenceShow.dnd,
        status: PresenceStatus.Meeting,
    },

    // these 2 presence are calculated, not determined by device's presence, how to map!!!
    [PresenceType.mobile]: {
        show: PresenceShow.away,
        status: PresenceStatus.NA,
    },

    [PresenceType.offline]: {
        show: PresenceShow.offline,
        status: PresenceStatus.NA,
    },
};

// it hard to handle this, because one presenceType doen't map to a specific resource
const mapResource = (presence: ContactPresence) => {
    const { presenceType } = presence;

    if (presenceType === PresenceType.mobile) {
        return ResourceType.PHONE;
    }

    if (presenceType === PresenceType.offline) {
        return ResourceType.NOT_SET;
    }

    return ResourceType.DESKTOP;
};

const mapPresence = (show: PresenceShow) => {
    const maps = {
        [PresenceShow.offline]: ZoomIMPresence.Offline,
        [PresenceShow.away]: ZoomIMPresence.Away,
        [PresenceShow.dnd]: ZoomIMPresence.DND,
        [PresenceShow.online]: ZoomIMPresence.Online,
        [PresenceShow.xa]: ZoomIMPresence.XA,
        [PresenceShow.unavailable]: ZoomIMPresence.Offline, // this will not happen here
    };

    return maps[show];
};

const mapPresenceStatus = (status: PresenceStatus) => {
    const maps = {
        [PresenceStatus.NA]: ZoomIMPresenceStatus.NA,
        [PresenceStatus.ZoomMeeting]: ZoomIMPresenceStatus.ZoomMeeting,
        [PresenceStatus.Meeting]: ZoomIMPresenceStatus.Meeting,
        [PresenceStatus.Pbx]: ZoomIMPresenceStatus.PBX,
        [PresenceStatus.Presenting]: ZoomIMPresenceStatus.Presenting,
        [PresenceStatus.Busy]: ZoomIMPresenceStatus.Busy,
        [PresenceStatus.OOO]: ZoomIMPresenceStatus.OOO,
    };
    return maps[status];
};

const transfromPresenceData = (presenceList: Array<ContactPresence>): Array<IBuddyPresnece> => {
    const transformer = (item: ContactPresence) => {
        const { jid, presenceType } = item;

        const components = presenceTypeComponets[presenceType];

        const data = {
            jid,
            res_type: mapResource(item),
            presence: mapPresence(components.show),
            presenceStatus: mapPresenceStatus(components.status),
        };

        /**
         * xmpp gives
         * for busy {show: 'away', status: 'BUSY'}
         */
        if (PresenceStatus.Busy === components.status) {
            data.presence = ZoomIMPresence.Busy;
        }

        /**
         * xmpp gives
         * for OOO {show: 'away', status: 'OOO'}
         */
        if (PresenceStatus.OOO === components.status) {
            data.presence = ZoomIMPresence.OOO;
        }

        return data;
    };

    return presenceList.map(transformer).filter((_) => !!_);
};

const isCurrentUser = ({ jid }: { jid: string }) => {
    const store = serviceContainer.getReduxStore();
    const userJid = store?.getState()?.common.userInfo?.jid || -1;
    return userJid === jid;
};

export default class PresenceSubscriber {
    private subJids: Map<string, boolean>;
    private timerRef = -1;
    private hasSubscribedPresenceEvent = false;

    notifyBuddyPresneceChange;

    constructor({ notifyBuddyPresneceChange }: ICtorProps) {
        this.notifyBuddyPresneceChange = notifyBuddyPresneceChange;
        this.subJids = new Map();
        this.timerRef = null;
    }

    private setTimer() {
        this.timerRef = window.setTimeout(() => {
            this.clearSubscribe();
        }, 10 * 60 * 1000);
    }

    private clearTimer() {
        window.clearTimeout(this.timerRef);
        this.timerRef = -1;
    }

    private subscribePresenceEvent() {
        if (this.hasSubscribedPresenceEvent) {
            return;
        }
        this.hasSubscribedPresenceEvent = true;
        eventBus.zpns.presenceChange.subscribe(this.onNotifyPresneceChange);
    }

    private unsubscribePresenceEvent() {
        eventBus.zpns.presenceChange.unsubscribe(this.onNotifyPresneceChange);
        this.hasSubscribedPresenceEvent = false;
    }

    subscribe(jids: Array<string>) {
        if (isDisableInternalPresence()) {
            return;
        }

        this.subscribePresenceEvent();
        const preenceManager = serviceContainer.getPresenceManager();
        if (!preenceManager) {
            return;
        }

        jids.forEach((jid) => {
            this.subJids.set(jid, true);
        });

        const presences = subscribePresenceFor5mins(jids);

        if (Array.isArray(presences)) {
            this.onNotifyPresneceChange(presences);
        }

        this.setTimer();
    }

    clearSubscribe() {
        this.subJids.clear();
        this.clearTimer();
        this.unsubscribePresenceEvent();
    }

    // make it arrow function to prevent manually binding to this
    onNotifyPresneceChange = (list: Array<ContactPresence>) => {
        if (isDisableInternalPresence()) {
            return;
        }

        const presences = list.filter((item) => {
            return this.subJids.has(item.jid) && !isCurrentUser(item);
        });

        if (presences.length <= 0) {
            return;
        }

        const finalPresences = transfromPresenceData(presences);
        if (isFunction(this.notifyBuddyPresneceChange)) {
            this.notifyBuddyPresneceChange(finalPresences);
        }
    };
}
