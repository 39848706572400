import { ZccEngagement } from '../types';

export default class Engagements {
    private engagements: Map<string, ZccEngagement>;
    private currentEngagementId: string;

    constructor() {
        this.engagements = new Map();
    }

    setCurrentEngagementId(id: string = '') {
        this.currentEngagementId = id;
    }

    getCurrentEngagementId() {
        if (this.currentEngagementId && this.has(this.currentEngagementId)) {
            return this.currentEngagementId;
        } else {
            this.setCurrentEngagementId();
            return this.currentEngagementId;
        }
    }

    getCurrentEngagement() {
        return this.get(this.currentEngagementId);
    }

    add(id: string, engagement: ZccEngagement) {
        if (this.engagements.has(id)) {
            this.update(id, engagement);
        } else {
            this.engagements.set(id, engagement);
        }
    }

    update(id: string, engagement: Partial<ZccEngagement>) {
        if (this.engagements.has(id)) {
            const value = this.engagements.get(id);
            const update = Object.assign(value, engagement);
            this.engagements.set(id, update);
        } else {
            this.add(id, engagement as ZccEngagement);
        }
    }

    delete(id: string) {
        const eng = this.engagements.get(id);

        if (this.engagements.has(id)) {
            this.engagements.delete(id);
            if (this.currentEngagementId === id) {
                this.setCurrentEngagementId();
            }
        }

        return eng;
    }

    get(id: string) {
        return this.engagements.get(id);
    }

    getAll() {
        return Array.from(this.engagements.values());
    }

    clear() {
        return this.engagements.clear();
    }

    has(id: string) {
        return this.engagements.has(id);
    }
}
