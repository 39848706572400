import { ContactPresence, PolicyName } from '@zoom/zpns-contact-presence';

import { setPresence } from '../../features/Contacts/redux/contact-store';
import { PWAMeetingEvent } from '../../features/Meeting/meeting-agent-msg-type';
import { isDisableInternalPresence } from '../../store/common/common-utils';
import serviceContainer from '../container';
import { getPolicyController } from './PolicyTimer';

export const presenceManagerProps = {
    onPresenceUpdate: (presenceList: Array<ContactPresence>) => {
        const reduxStore = serviceContainer.getReduxStore();
        const eventBus = serviceContainer.getEventBus();

        reduxStore.dispatch(setPresence({ data: presenceList }));

        eventBus.zpns.presenceChange.dispatchEvent(presenceList);
    },

    onReportPresenceToZpns: (presence: any) => {
        const xmppAgent = serviceContainer.getXmppAgent();
        xmppAgent.changePresence(presence.presence as any, presence.manual == 1 ? true : false);
    },

    onBeforeHandleSubscribePresence: (subs: any) => {
        if (isDisableInternalPresence()) {
            return false;
        }
        return subs;
    },

    onPolicyChange(policyProps: any) {
        const eventBus = serviceContainer.getEventBus();
        eventBus.xmpp.policyChange.dispatchEvent(policyProps);
        const { policy, unset } = policyProps;

        const policyTimerController = getPolicyController();

        if (policy.name === PolicyName.snooze) {
            if (unset) {
                policyTimerController.unsetSnoozeTimer();
            } else {
                policyTimerController.setSnoozeTimer(policy.value);
            }
        }
    },

    isInMeeting: () => {
        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { meetingInfo },
        } = reduxStore.getState();
        return (
            meetingInfo &&
            meetingInfo.meetingNo &&
            (PWAMeetingEvent.JOINING === meetingInfo.meetingStatus ||
                PWAMeetingEvent.SUCCESS === meetingInfo.meetingStatus)
        );
    },

    isInMeetingPresenting: () => {
        const reduxStore = serviceContainer.getReduxStore();
        const {
            meeting: { isPresenting },
        } = reduxStore.getState();
        return isPresenting;
    },

    isInPhoneCall: () => {
        const reduxStore = serviceContainer.getReduxStore();
        const { phone } = reduxStore.getState();
        return Boolean(phone?.currentSessionId);
    },

    isInCalendar: () => {
        return false;
    },

    requestAllPoliciesFromXmpp() {
        const xmppAgent = serviceContainer.getXmppAgent();
        xmppAgent.webimInstance.getAllPresencePocliies();
    },

    reportPolicyToXmpp(policy: any) {
        const xmppAgent = serviceContainer.getXmppAgent();
        xmppAgent.webimInstance.setPresencePolicy(policy);
    },
};
