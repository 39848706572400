import microApp from '@micro-zoe/micro-app';
import {
    EChatIncomingMessages,
    EChatOutgoingMessages,
    IChatInComingMessage,
    IChatOutgoingMessage,
    MeetingStatusToChat,
} from '../chat-types';
import { history, matchChatRoute, pushRouteAndMinimizeMeeting } from '../../../routes';
import { RoutePath } from '../../../routes/routePath';
import store from '../../../store';
import { isUserSigninSelector } from '../../../store/common/common-selector';
import { throttle } from 'lodash-es';
import { setUnreadCount } from '../redux';
import {
    signoutWhenSeesionExpiredThunk,
    signoutWhenXMPPConflictThunk,
    signoutWhenXMPPRevokeTokenThunk,
} from '../../../store/common/sign-out-thunk';
import { getAsyncPhoneModule } from '../../../asyncModulesStore';
import { inviteContact } from '../../Contacts/hooks/useInviteContact';
import { isDuringMeetingSelector } from '../../Meeting/redux';
import { meetingAgent } from '../../../app-init';
import { setModal } from '../../../store/modal/modal-store';
import { MeetingItem, ZnpsMeetingItemData } from '../../Meetings/Meetings/types';
import { setCurrentNavKey, setShowSettingsDialog, SETTING_NAV_KEY } from '../../Settings/redux/settings-store';
import { updateChatPopover } from '../redux/chat-thunk';
import { isPMCEnabledSelector } from '../../../store/common/userWebSettingsSelector';
import easyStore from '../../../utils/storage';
import { isTeslaMode } from '../../../utils';
import { isChatEnabled } from '../../../utils/featureOptions';
import { userSessionLogger, webclientLogger } from '../../../logger/pwa-loggers';
import { CHAT_APP_NAME } from '../../../configs';
import { CHAT_SEARCH_ROOT_ID } from '../index';

const setUnreadCountThrottled = throttle((payload) => {
    console.log('[chat] ', 'update unread count throttled');
    store.dispatch(setUnreadCount(Object.assign({ total: 0, shown: 0 }, payload)));
}, 2000);

export default class ChatAgent {
    private static _instance: ChatAgent;

    static getInstance(props?: any) {
        if (!ChatAgent._instance) {
            ChatAgent._instance = new ChatAgent(props);
        }

        return ChatAgent._instance;
    }

    private isReady = false;
    private messageQueue: Array<any>;
    hasChatXMPPSessionEnded: boolean;

    constructor(_props: any) {
        this.isReady = false;
        this.messageQueue = [];
        this.hasChatXMPPSessionEnded = false;
    }

    init = () => {
        microApp.addDataListener(CHAT_APP_NAME, this.onMessage);
    };

    uninit = () => {
        microApp.removeDataListener(CHAT_APP_NAME, this.onMessage);
        microApp.unmountApp(CHAT_APP_NAME);
    };

    postMessage = (data: IChatOutgoingMessage) => {
        if (!isChatEnabled()) {
            console.error('error', 'chat is disabled');
            return;
        }

        this.messageQueue.push(data);

        if (!this.isReady) {
            return;
        }

        this.flushMessageQueue();
    };

    private flushMessageQueue = () => {
        while (this.messageQueue.length) {
            const data = this.messageQueue.shift();
            console.log('[chat ====>] ', data);
            microApp.setData(CHAT_APP_NAME, data);
        }
    };

    /**
     * jump to chat user or channel.
     * @param id : jid or channel id.
     **/
    chatWithUserOrChannel = ({ id }: { id: string }) => {
        if (history.location.pathname !== RoutePath.Chat) {
            pushRouteAndMinimizeMeeting(RoutePath.Chat);
        }

        const sType = /conference/.test(id) ? 'groupchat' : 'chat';

        this.postMessage({
            type: EChatOutgoingMessages.ROUTE_TO_CHAT_ITEM,
            data: {
                jid: id,
                sType,
            },
        });
    };

    syncMeetingStatusToChat = (status: MeetingStatusToChat) => {
        this.postMessage({
            type: EChatOutgoingMessages.MEETING_STATUS,
            data: {
                status,
            },
        });
    };

    /**
     * when someone else scheduleed a meeting with a dedicated channel, and add you as attendee,
     * we could get this meeting from /meeting/list. then we push these channel meeting info to chat
     */
    syncChannelMeetings = () => {
        const {
            meetings: { meetings },
        } = store.getState();
        const resultMap: Record<string, MeetingItem> = {};

        meetings
            .filter((m) => Boolean(m.channelId))
            .forEach((m) => {
                // for recurring meetings, all the occurrences share the same channel.
                // to dedup
                resultMap[m.channelId] = m;
            });

        this.postMessage({
            type: EChatOutgoingMessages.CHANNEL_MEETING_RES,
            data: resultMap,
        });
    };

    /**
     * when someone else scheduleed a meeting with a dedicated channel, and add you as attendee,
     * then edit that meeting to remove you from attendee list, zpns will push that notification to us,
     * we shoudld first find that meeting with mn, then delete the meeting (mn), and remove it from meetings in redux
     */
    deleteChannelMeetings = ({ mn }: ZnpsMeetingItemData) => {
        const {
            meetings: { meetings },
        } = store.getState();

        const founds = meetings.filter((m) => m.channelId && String(m.meetingNumber) === String(mn));
        if (founds.length === 1) {
            // for recurring meetings, if this deleted one is the last one of these occurrences, we push to chat
            this.postMessage({
                type: EChatOutgoingMessages.CHANNEL_MEETING_RES,
                data: { [founds[0].channelId]: null },
            });
        }
    };

    /**
     * when chat finds that  a  channel is related to a meeting, it will ask pwa if we have the meeting details,
     * then it displays meeting details, then it will ask us to join/start that meeting
     */
    private queryMeetingForChannel = ({
        channelList = [],
    }: {
        channelList: Array<{ channelId: string; meetingNumber: number }>;
    }) => {
        const {
            meetings: { meetings },
        } = store.getState();
        const resultMap: Record<string, MeetingItem> = {};
        channelList.forEach((channel) => {
            const found = meetings.find((meeting) => {
                return String(meeting.meetingNumber) === String(channel.meetingNumber);
            });
            if (found) {
                resultMap[channel.channelId] = found;
            }
        });

        this.postMessage({
            type: EChatOutgoingMessages.CHANNEL_MEETING_RES,
            data: resultMap,
        });
    };

    /**
     * when we open settings and switch to chat tab, we ask chat sub-app to render it's content
     */
    toRenderSetting({ container }: { container: HTMLDivElement | null }) {
        this.postMessage({
            type: EChatOutgoingMessages.RENDER_SETTING,
            data: { container },
        });
    }

    toNotifyRouteChange(route: { isChat: boolean }) {
        this.postMessage({
            type: EChatOutgoingMessages.ROUTE_CHANGE,
            data: route,
        });
    }

    toNotifyPostMeetingTips(info: { topic: string; channelId: string } | null) {
        this.postMessage({
            type: EChatOutgoingMessages.CHANNEL_MEETING_TIPS,
            data: info ? { topic: info.topic, jid: info.channelId } : null,
        });
    }

    /**
     * to Notify chat signout when zwa signout
     */
    toNotifyDestroy(reason: string) {
        this.postMessage({
            type: EChatOutgoingMessages.EXTERNAL_SIGNOUT,
            data: reason,
        });
    }

    onMessage = (data: IChatInComingMessage): void => {
        const { type, payload } = data;
        const rootState = store.getState();
        const isUserSignin = isUserSigninSelector(rootState);
        const isDuringMeeting = isDuringMeetingSelector(rootState);

        if (this.hasChatXMPPSessionEnded && !isUserSignin) {
            return;
        }

        console.log('[chat <=] ', data);

        switch (type) {
            case EChatIncomingMessages.START_MEETING: {
                inviteContact(payload);
                break;
            }

            case EChatIncomingMessages.START_PHONE_CALL: {
                if (!rootState.common.isPhoneModuleLoaded) {
                    return;
                }

                getAsyncPhoneModule()
                    .then(({ sendCall }) => {
                        if (history.location.pathname !== RoutePath.Phone) {
                            pushRouteAndMinimizeMeeting(RoutePath.Phone);
                        }
                        if (Array.isArray(payload)) {
                            payload[0] && store.dispatch(sendCall({ number: payload[0] }));
                        } else {
                            const { number, name } = payload;
                            store.dispatch(sendCall({ number, name }));
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            }

            case EChatIncomingMessages.ROUTE_TO_CHAT: {
                if (history.location.pathname === RoutePath.Chat) {
                    return null;
                }
                pushRouteAndMinimizeMeeting(RoutePath.Chat);
                break;
            }

            case EChatIncomingMessages.WEB_SESSION_EXPIRED: {
                userSessionLogger.log('', ['Session_Expire', 'From_Chat']);
                store.dispatch(signoutWhenSeesionExpiredThunk());
                break;
            }

            case EChatIncomingMessages.XMPP_SESSION_REVOKE_TOKEN: {
                store.dispatch(signoutWhenXMPPRevokeTokenThunk());
                this.hasChatXMPPSessionEnded = true;
                break;
            }

            case EChatIncomingMessages.XMPP_SESSION_CONFLICT: {
                store.dispatch(signoutWhenXMPPConflictThunk());
                this.hasChatXMPPSessionEnded = true;
                break;
            }

            case EChatIncomingMessages.CHAT_APP_READY: {
                this.isReady = true;
                this.flushMessageQueue();
                break;
            }

            case EChatIncomingMessages.UNREAD_COUNT: {
                setUnreadCountThrottled(payload);
                break;
            }

            case EChatIncomingMessages.JOIN_MEETING: {
                const { meetingNumber, meetingParams } = data.payload;
                if (!isDuringMeeting) {
                    meetingAgent.joinMeeting(meetingNumber, meetingParams);
                    webclientLogger.log(
                        {
                            from: 'TeamChat',
                            duringMeeting: false,
                        },
                        ['Join'],
                    );
                } else {
                    if ('pwd' in meetingParams) {
                        meetingParams.password = meetingParams.pwd;
                        delete meetingParams.pwd;
                    }
                    store.dispatch(
                        setModal({
                            name: 'ifJoinMeeting',
                            data: { meetingNo: meetingNumber, extraParams: meetingParams },
                        }),
                    );
                    webclientLogger.log(
                        {
                            from: 'TeamChat',
                            duringMeeting: true,
                            desc: 'to popup dialog for confirmation',
                        },
                        ['Join'],
                    );
                }
                break;
            }

            case EChatIncomingMessages.CHANNEL_MEETING_REQ: {
                this.queryMeetingForChannel(payload);
                break;
            }

            case EChatIncomingMessages.OPEN_SETTING: {
                this.onMessageOpenSetting();
                break;
            }

            case EChatIncomingMessages.PMC_TIPS_CLOSE: {
                store.dispatch(updateChatPopover({ popover: 'post-meeting', info: null }));
                break;
            }

            case EChatIncomingMessages.RELOAD_CHAT: {
                this.handleReloadChat();
                break;
            }

            default:
                break;
        }
    };

    onMessageOpenSetting() {
        store.dispatch(setShowSettingsDialog(true));
        store.dispatch(setCurrentNavKey(SETTING_NAV_KEY.CHAT));
    }

    handlePmcMeetingEnd(meetingInfo: { topic: string; channelId: string }) {
        console.log('[chat agent] handleMeetingEnd', isPMCEnabledSelector(store.getState()));
        if (
            isTeslaMode() ||
            !isChatEnabled() ||
            !isPMCEnabledSelector(store.getState()) ||
            easyStore.localStore.easyGet('showChatPostMeetingPopover') !== undefined
        ) {
            return;
        }

        store.dispatch(
            updateChatPopover({
                popover: 'post-meeting',
                info: meetingInfo,
            }),
        );

        if (matchChatRoute()) {
            // chat is shown
            this.toNotifyPostMeetingTips(meetingInfo);
        }
    }

    async handleReloadChat() {
        const chatSearchRoot: HTMLElement | null = document.getElementById(CHAT_SEARCH_ROOT_ID);
        if (chatSearchRoot) {
            chatSearchRoot.innerHTML = '';
        }
        await microApp.reload(CHAT_APP_NAME, true);
        if (chatSearchRoot) {
            this.postMessage({
                type: EChatOutgoingMessages.SEARCH_MOUNT_DOM,
                data: {
                    chatSearchContainer: chatSearchRoot,
                },
            });
        }
    }
}
