import { isArray } from 'lodash-es';
import serviceContainer from '../../../../services/container';
import { ZpnsMsgCategory } from '../../../../services/zpns/zpns-defs';
import { getUpdatedUcsGroupMembers } from '../../redux/ucs-thunk';

const handleUcsGroupChange = ({ groupId }: { groupId: string }) => {
    console.log(groupId);
    const reduxStore = serviceContainer.getReduxStore();

    reduxStore.dispatch(getUpdatedUcsGroupMembers({ groupId }));
};

const handleZpnsMessage = (message: any) => {
    if (message.category !== ZpnsMsgCategory.Ucs) {
        return;
    }

    const groups = message.event?.groups;

    if (!isArray(groups)) {
        return;
    }

    for (const group of groups) {
        handleUcsGroupChange({ groupId: group });
    }
};

let listened = false;
export const startListenZpnsMessage = () => {
    const eventBus = serviceContainer.getEventBus();
    if (listened) {
        return;
    }
    listened = true;
    eventBus.zpns.message.subscribe(handleZpnsMessage);
};
