import React, { useState } from 'react';
import Dialog from './Dialog';
import { Modal } from 'Modal';
import {
    Be_Valid_Email,
    CONTACTS_EMAIL,
    Company_T,
    Create_Personal_Contact,
    Create_P_Contact_Ok,
    Create_T,
    Fax_T,
    First_Last_Name,
    Home_T,
    Mobile_T,
    Office_T,
    Phone_Num,
    Phone_Text,
} from '../../../resource';
import Input from './Input';
import styles from './index.module.scss';
import { Select } from '@zoom/react-ui';
import { useAppDispatch } from '../../../store/store-hooks';
import { hideModal } from '../../../store/modal/modal-helper';
import { SelectChangeEvent } from '@mui/material';

import { createPersonalContact } from './services/apis';
import { PwaErrors } from '../../../services/error/pwa-error';
import { ICON_TYPE, toast } from 'Toast';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import Button from '../../../components/Button/Button';
import { Cancel_Text } from '../../Phone/LanguageResource';

const numberTypeOptions = [
    { value: 'office', label: Office_T },
    { value: 'home', label: Home_T },
    { value: 'mobile', label: Mobile_T },
    { value: 'fax', label: Fax_T },
    { value: 'phone', label: Phone_Text },
];

interface IProps {
    data: {
        show: boolean;
        type: 'edit' | 'create';
        info: {
            number: string;
        };
    };
}

const CreatePersonalContactDialogFromPhoneModule = ({ data: { info } }: IProps) => {
    const dispatch = useAppDispatch();

    const initialState = {
        name: {
            value: '',
            error: '',
            required: true,
        },
        company: {
            value: '',
            error: '',
            required: false,
        },
        email: {
            value: '',
            error: '',
            required: false,
        },
        number: {
            value: info?.number || '',
            error: '',
            required: false,
        },
        numberType: {
            value: numberTypeOptions[1].value,
            error: '',
            required: false,
        },
    };

    const [formState, updateFormState] = useState(initialState);

    const updateKeyValue = (key: keyof typeof initialState, value: any) => {
        const curValue = formState[key];

        updateFormState({
            ...formState,
            [key]: Object.assign({}, curValue, value),
        });
    };

    const updateName = (newValue: string) => {
        const nextValue = newValue.slice(0, 300);
        updateKeyValue('name', { value: nextValue });
    };

    const updateCompany = (newValue: string) => {
        const nextValue = newValue.slice(0, 500);
        updateKeyValue('company', { value: nextValue });
    };

    const updateEmail = (newValue: string) => {
        const next = {
            value: newValue,
            error: '',
        };

        if (newValue.indexOf('e') < 0) {
            next.error = Be_Valid_Email;
        }

        if (!next.value) {
            next.error = '';
        }

        updateKeyValue('email', next);
    };

    const updateNumber = (newValue: string) => {
        updateKeyValue('number', { value: newValue });
    };

    const updateNumberType = (e: SelectChangeEvent<unknown>) => {
        const value = e.target.value;
        updateKeyValue('numberType', { value });
    };

    const closeMe = () => {
        dispatch(hideModal('personalContactFromPhone'));
    };

    let isReadyToCreate = false;
    for (const item of Object.values(formState)) {
        const { error, required, value } = item;
        if (error) {
            isReadyToCreate = false;
            break;
        }
        if (!value) {
            if (required) {
                isReadyToCreate = false;
                break;
            }
        }
        isReadyToCreate = true;
    }

    const onCreate = () => {
        if (!isReadyToCreate) {
            return;
        }

        const name = formState.name.value;
        const company = formState.company.value;
        const email = formState.email.value;
        const numberType = formState.numberType.value;
        const number = formState.number.value;
        const createProps = {
            name,
            company,
            email,
            officePhoneNum: numberType === 'office' ? number : '',
            homePhoneNum: numberType === 'home' ? number : '',
            phoneNum: numberType === 'phone' ? number : '',
            fax: numberType === 'fax' ? number : '',
            mobile: numberType === 'mobile' ? number : '',
        };

        createPersonalContact(createProps)
            .then(() => {
                toast({
                    type: ICON_TYPE.SUCCESS,
                    desc: Create_P_Contact_Ok(createProps.name),
                });
            })
            .catch((error) => {
                if (error.code === PwaErrors.WebSessionExpired.code) {
                    // relogin
                    dispatch(signoutWhenSeesionExpiredThunk());
                }
                toast({
                    type: ICON_TYPE.FAILURE,
                    desc: error.message,
                });
            });

        closeMe();
    };

    const onCancel = () => {
        closeMe();
    };

    return (
        <Modal
            needShowAboveBase={true}
            isOpen={true}
            centered={true}
            needBackdrop={true}
            isDraggable={false}
            onRequestClose={closeMe}
            key={'personal-contact-from-phone'}
        >
            <Dialog className={styles.dialog}>
                <div className={styles.header}>{Create_Personal_Contact}</div>
                <Input
                    className={styles.inputItem}
                    placeholder={First_Last_Name}
                    ariaLabel={First_Last_Name}
                    inline={false}
                    errorMsg={formState.name.error}
                    value={formState.name.value}
                    onChange={updateName}
                />

                <Input
                    className={styles.inputItem}
                    placeholder={Company_T}
                    ariaLabel={Company_T}
                    inline={false}
                    value={formState.company.value}
                    onChange={updateCompany}
                    errorMsg={formState.company.error}
                />

                <Input
                    className={styles.inputItem}
                    placeholder={CONTACTS_EMAIL}
                    ariaLabel={CONTACTS_EMAIL}
                    inline={false}
                    value={formState.email.value}
                    onChange={updateEmail}
                    errorMsg={formState.email.error}
                />

                <Input
                    className={styles.inputItem}
                    placeholder={Phone_Num}
                    ariaLabel={Phone_Num}
                    inline={false}
                    value={formState.number.value}
                    onChange={updateNumber}
                    errorMsg={formState.number.error}
                />

                <Select
                    className={styles.select}
                    aria-label="type"
                    fullWidth
                    size="large"
                    options={numberTypeOptions}
                    value={formState.numberType.value}
                    onChange={updateNumberType}
                />

                <div className={styles.footer}>
                    <Button type={Button.Dark} onClick={onCancel}>
                        {Cancel_Text}
                    </Button>
                    <Button onClick={onCreate} disabled={!isReadyToCreate}>
                        {Create_T}
                    </Button>
                </div>
            </Dialog>
        </Modal>
    );
};

export default CreatePersonalContactDialogFromPhoneModule;
