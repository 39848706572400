import React from 'react';
import Button from '../../../components/Button/Button';
import {
    Meeting_Transfer_In_Progress_Text,
    Meeting_Transfer_Switch_Text,
    On_Device_Text,
} from '../LanguageResource';
import { IconCellphoneFill, IconDesktopFill } from '@zoom/icons-react';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { IDevice } from '@zoom/pwa-webim';
import { isDuringMeetingSelector } from '../../Meeting/redux';
import { isTeslaMode } from '../../../utils';

import './InMeetingItem.scss';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';
import { transferMeetingThunk } from '../Meetings/redux/transfer-meetings-thunk';

interface IProps {
    inMeetingDeviceList: Array<IDevice>;
}

export default function InMeetingItem(props: IProps): JSX.Element {
    const { userInfo, isDuringMeeting, isStartDisabled } = useAppSelector((state) => {
        return {
            userInfo: state.common.userInfo,
            isDuringMeeting: isDuringMeetingSelector(state),
            isStartDisabled: state.meeting.isStartDisabled,
        };
    });

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);

    const { inMeetingDeviceList } = props;

    const isTesla = isTeslaMode();
    const dispatch = useAppDispatch();

    if (inMeetingDeviceList.length === 0) return null;

    const inMeetingEle = inMeetingDeviceList.map((inMeetingDevice) => {
        const { meetingInfo, name, resource, deviceId } = inMeetingDevice;
        if (meetingInfo && meetingInfo.length > 0) {
            const { topic, number } = meetingInfo[0];
            return (
                <div className="trans__meeting-container" key={number + deviceId}>
                    <div className="trans__meeting-status">
                        {resource.indexOf('mobile') !== -1 ? (
                            <IconCellphoneFill className="trans__meeting-status-icon" />
                        ) : (
                            <IconDesktopFill className="trans__meeting-status-icon" />
                        )}
                        {Meeting_Transfer_In_Progress_Text}
                    </div>
                    <div className="trans__meeting-topic">
                        <span className="trans__meeting-topic-text">{topic}</span>
                        <Button
                            disabled={isDuringMeeting || isStartDisabled || isZCCInMeeting}
                            size={isTesla ? 40 : 24}
                            onClick={async () => {
                                dispatch(transferMeetingThunk({ userInfo, number, deviceId, resource, name }))
                            }}
                        >
                            {Meeting_Transfer_Switch_Text}
                        </Button>
                    </div>
                    <div className="trans__meeting-device">{On_Device_Text(name)}</div>
                </div>
            );
        }
        return null;
    });

    return (
        <>
            {inMeetingEle}
        </>
    );
}
