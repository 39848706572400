import { DndSnoozePolicy, PolicyName } from '@zoom/zpns-contact-presence';
import DndSnoozePolicyTimer from './DndSnoozePolicyTimer';

interface IProps {
    unsetPolicy(name: PolicyName): void;
}

export default class PolicyController {
    snoozeTimer: DndSnoozePolicyTimer = null;

    private unsetPolicy: (name: PolicyName) => void;

    constructor(props: IProps) {
        this.unsetPolicy = props.unsetPolicy;
    }

    setSnoozeTimer(policy: DndSnoozePolicy) {
        if (!this.snoozeTimer) {
            this.snoozeTimer = new DndSnoozePolicyTimer({
                whenPolicyDurationEnd: this.unsetPolicy,
            });
        }

        this.snoozeTimer.setPolicy(policy);
    }

    unsetSnoozeTimer() {
        if (!this.snoozeTimer) {
            this.snoozeTimer = new DndSnoozePolicyTimer({
                whenPolicyDurationEnd: this.unsetPolicy,
            });
        }

        this.snoozeTimer.unsetPolicy();
    }
}
