import { WebimLogLevel, WebimModuleName } from '@zoom/pwa-webim';
import Long from 'long';
import type { IUserInfo } from '../../store/common/common-store';
import { getBrowserName } from '../../store/common/common-utils';
import { getDomainFromJid, getUniqueId } from '../../utils';

export const getXmppConnectOptions = () => {
    // 656631(pwa) | 2048(chat)
    // option: 658679, // includes personal groups
    const defaultOption = 656631;
    const No_Not_Sync_Presence_To_Zpns_Mask = 0x2000000;
    return Long.fromNumber(defaultOption).or(Long.fromNumber(No_Not_Sync_Presence_To_Zpns_Mask)).toNumber();
};

const EnableDebugLog = process.env.NODE_ENV === 'development';
let logModule: Array<WebimModuleName> = [];
let logLevel: Array<WebimLogLevel> = [];
if (EnableDebugLog) {
    logLevel = [WebimLogLevel.DEBUG, WebimLogLevel.WARN, WebimLogLevel.ERROR, WebimLogLevel.INFO];
    logModule = [
        WebimModuleName.connection,
        WebimModuleName.presence,
        WebimModuleName.roster,
        WebimModuleName.message,
        WebimModuleName.vcard,
        WebimModuleName.xms,
    ];
}

const getXMSDomain = () => {
    return window?.PwaConfig?.serverDomain?.microServiceDomain || '';
};

export const defaultXmppConfig = {
    enableFetchCoworkerGroup: false,
    enableFetchZoomRoomGroup: false,
    log: { logLevel, logModule },
    pingInterval: 30000, //ms
    pingTimeout: 2000,

    PWATokenUrl: `${window.location.origin}/wc/pwa/token`,
    XMSDomain: getXMSDomain(),

    resouceId: 'ZoomChat_pc_pwa', // xmpp resounce,  ZoomChat_pc_pwa, ZoomChat_pwa_hash

    web: {
        option: getXmppConnectOptions(),
    },

    deviceId: getUniqueId('pwa'),
};

export const getXmppConfigFromUserInfo = (
    userInfo: IUserInfo,
): { XMPPServer: string; PINGServer: string; deviceName: string } => {
    const { xmppDomain, jid } = userInfo;
    const serverAddr = `wss://${xmppDomain}/xmpp-websocket`;
    const pingAddr = getDomainFromJid(jid);
    return {
        XMPPServer: serverAddr,
        PINGServer: pingAddr,
        deviceName: getBrowserName() + '_PWA',
    };
};
