import { iText } from '../../../utils/i18n';

export const Cancel_Text = iText('Cancel', 'pwa.cancel');
export const Not_Connected_Text = iText('Not connected. Attempting to reconnect...', 'pwa.phone.not_connected');

export const Same_As_System_Text = iText('Same as System', 'pwa.phone.same_as_system');

export const Stop_Text = iText('Stop', 'pwa.stop');
export const Output_Level_Text = iText('Output Level', 'pwa.output_level');

export const Speaker_List_Empty_Text = iText('No speaker device found', 'pwa.phone.speaker_list_empty');
export const Speaker_Text = iText('Speaker', 'pwa.speaker');
export const Speaker_Start_Test_Text = iText('Test Speaker', 'pwa.phone.speaker_start_test');
export const Speaker_Volume_Text = iText('Volume', 'pwa.volume');
export const Microphone_Trying_Access_Text = iText('Trying to access your microphone', 'pwa.phone.micro_try_to_access');
export const Microphone_Access_Error_Text = iText(
    'Something goes wrong when accessing your microphone',
    'pwa.phone.micro_access_error',
);
export const Microphone_List_Empty_Text = iText('No microphone devices found', 'pwa.phone.micro_list_empty');
export const MicroPhone_Text = iText('Microphone', 'pwa.microphone');
export const Micorphone_Start_Test_Text = iText('Test Mic', 'pwa.phone.micro_start_test');

export const PUSH_NOTIFICATIONS_FOR_ALL_MESSAGES = iText('All Messages', 'pwa.phone.push_notifications.all_messages');
export const PUSH_NOTIFICATIONS_FOR_NO_MESSAGES = iText('Nothing', 'pwa.phone.push_notifications.nothing');
export const PUSH_NOTIFICATIONS = iText('Push Notifications', 'pwa.phone.push_notification');

// for toast
export const Can_Not_Change_Micro_Now_Text = iText(
    'Can not change microphone at this moment',
    'pwa.phone.can_not_change_mocro_now',
);
export const Toast_Auto_Record_Text = iText(
    'Admin set calls to automatically record',
    'pwa.phone.admin_disable_auto_record',
);
export const Toast_AdHoc_Record_Disabled_Text = iText(
    'Admin disabled call recording',
    'pwa.phone.admin_disable_call_record',
);

export const Call_Ended_Text = iText('Call Ended', 'pwa.phone.call_ended');
export const Someone_Pick_Up_Call = (who: string) => iText('{0} picked up the call', 'pwa.phone.pick_up_call', [who]);
export const E911_Call_Disabled_Text = iText(
    'Emergency calling is disabled. Contact your admin for more information',
    'pwa.phone.e911_call_disabled',
);

export const Record_Failed_Text = (msg: string) => iText('Record failed: {0}', 'pwa.phone.record_failed', [msg]);
export const Park_At_Text = (address: string) => iText('Park at: {0}', 'pwa.phone.park_at', [address]);
export const Park_Call_Failed_Text = iText('Park call Failed. Please try again later', 'pwa.phone.park_call_failed');
export const Call_Failed_Text = iText('Call Failed', 'pwa.phone.call_failed');

// for in call pad
export const Mute_Button_Text = iText('Mute', 'pwa.phone.mute_button');
export const Keypad_Button_Text = iText('Keypad', 'pwa.phone.keypad_button');
export const Audio_Button_Text = iText('Audio', 'pwa.phone.audio_button');
export const Add_Call_Button_Text = iText('Add call', 'pwa.phone.add_call_button');
export const Hold_Button_Text = iText('Hold', 'pwa.phone.hold_button');
export const UnHold_Button_Text = iText('UnHold', 'pwa.phone.unhold_button');
export const Transfer_Button_Text = iText('Transfer', 'pwa.phone.transfer_button');
export const More_Button_Text = iText('More', 'pwa.phone.more_button');
export const Call_Button_Text = iText('Call', 'pwa.phone.call_button');
export const Text_Message_Button_Text = iText('Send Text Message', 'pwa.phone.text_message_button');
export const Send_SMS = iText('Send SMS', 'pwa.phone.click_to_dial_send_message');
export const Play_Voicemail_Button_Text = iText('Play Voicemail', 'pwa.phone.play_voicemail_button');
export const Pause_Button_Text = iText('Pause', 'pwa.phone.pause_button');
export const Close_Button_Text = iText('Close', 'pwa.phone.close_button');
export const Record_Button_Text = iText('Record', 'pwa.phone.record_button');
export const Record_Auto_Button_Text = iText('REC', 'pwa.phone.rec');
export const Record_Preparing_Button_Text = iText('Preparing', 'pwa.phone.preparing');
export const Record_Stop_Button_Text = iText('Stop Recording', 'pwa.phone.stop_recording');

export const Caller_Id_Text = iText('Caller ID', 'pwa.phone.caller_id_2');
export const Your_Ext_Txt = iText('Your Ext', 'pwa.phone.your_ext');

export const Emergency_Call_Text = iText('Emergency Call', 'pwa.phone.emergency_call');
export const Phone_Number_Input_PlaceHolder_Text = iText(
    'Enter a name or number...',
    'pwa.phone.number_input_placeholder',
);
export const Phone_Number_Pad_Type_Add_Call = iText('Add call', 'pwa.phone.number_pad_type_add_call');
export const Phone_Number_Pad_Type_Transfer_To = iText('Transfer to', 'pwa.phone.number_pad_type_transfer_to');

export const Zoom_Phone_Call_Text = iText('Zoom Phone call', 'pwa.phone.zoom_phone_call');
export const Someone_Is_Calling_Text = (who: string) =>
    iText('{0} is calling you', 'pwa.phone.someone_is_calling', [who]);
export const To_Text = iText('to', 'pwa.phone.to');
export const TO_UPPER_COLON = iText('To:', 'pwa.phone.to_upper_colon');
export const Ext_Text = iText('Ext', 'pwa.phone.ext');
export const Someone_Text = iText('someone', 'pwa.phone.someone');

export const Send_To_Voicemail_Text = iText('Send To Voicemail', 'pwa.send_to_voicemail');
export const End_Accept_Text = iText('End & Accept', 'pwa.phone.end_accept');
export const END_MEETING_AND_ACCEPT_TEXT = iText('End Meeting & Accept', 'pwa.end_meeting_accept');
export const HOLD_MEETING_AUDIO_AND_ANWSER = iText('Hold Meeting audio & Answer', 'pwa.hold_meeting_audio_and_anwser');

export const Calling_Text = iText('Calling', 'pwa.phone.calling');
export const Connecting_Text = iText('Connecting', 'pwa.phone.connecting');
export const Callers_Text = iText('Callers', 'pwa.callers');
export const Click_To_Swap_Text = iText('Click to swap', 'pwa.phone.click_to_swap');

export const Park_Text = iText('Park', 'pwa.phone.park');
export const Park_Desc_Text = iText('Place call on hold for another user pick up', 'pwa.park_desc');

export const Warm_Transfer_Text = iText('Warm Transfer', 'pwa.phone.warm_transfer');
export const Warm_Transfer_Desc_Text = iText(
    'Speak to the new user before transferring',
    'pwa.phone.warm_transfer_desc2',
);

export const Transfer_To_Starred_Text = iText('Transfer to starred', 'pwa.phone.transfer_to_starred');

export const Blind_Transfer_Text = iText('Direct Transfer', 'pwa.phone.blind_transfer2');
export const Blind_Transfer_Desc_Text = iText(
    'Transfer without speaking to the new user',
    'pwa.phone.blind_transfer_desc2',
);

export const Transfer_To_VM_Text = iText('Transfer to Voicemail', 'pwa.phone.transfer_to_vm');
export const Transfer_To_VM_Desc_Text = iText(
    "Transfer directly to the new user's voicemail",
    'pwa.phone.transfer_to_vm_desc',
);

export const Complete_Transfer_Text = iText('Complete Transfer', 'pwa.phone.complete_transfer');
export const Cancel_Transfer_Text = iText('Cancel Transfer', 'pwa.phone.cancel_transfer');

export const NO_CONVERSATIONS = iText('No Conversations', 'pwa.phone.no_conversation');
export const MESSAGE_FAILED_NOTIFICATION = iText('Message could not be sent', 'pwa.phone.message.failed.notification');
export const MESSAGE_DELETE = iText('Delete', 'pwa.phone.message.delete');
export const MESSAGE_RETRY = iText('Retry', 'pwa.phone.message.retry');
export const CHAT_FOOTER_PLACEHOLDER = iText('Text message...', 'pwa.phone.chat.footer');
export const TEXT_TO_SB = (who: string) => iText('Text {0}...', 'pwa.phone.chat.footer.text_to_sb', [who]);
export const TEXT_TYPEAHEAD = iText('Text', 'pwa.phone.typeahead.text');

export const INVALID_RECIPIENT = iText('Invalid Recipient', 'pwa.phone.invalid.recipient');
export const CHECK_NUMBER = iText(
    'Your message has invalid recipients, please check the number.',
    'pwa.phone.check.number',
);
export const TOO_MANY_RECIPIENTS = iText('Too many recipients', 'pwa.phone.too.many.recipients');
export const MAX_RECIPIENTS = iText(
    'You are only allowed to send SMS messages to a maximum of 10 recipients.',
    'pwa.phone.max.recipients',
);
export const ALREADY_RECIPIENT = iText('Already in recipients', 'pwa.phone.already.recipient');
export const INPUTTED_EXISTED_RECIPIENT = iText(
    'You have already inputted this recipient.',
    'pwa.phone.inputted.existing.recipient',
);
export const HISTORY_TEXT = iText('History', 'pwa_history_text');
export const VOICEMAIL_TEXT = iText('Voicemail', 'pwa_voicemail_text');
export const LINES_TEXT = iText('Lines', 'pwa.lines_text');

export const OPEN_KEYPAD = iText('Open Keypad', 'pwa.phone.open_keypad');
export const NEW_SMS = iText('New SMS', 'pwa.phone.new.sms');
export const DRAFT = iText('[Draft] ', 'pwa.phone.draft');
export const DELETE_CONVERSATION = iText('Delete Conversation', 'pwa.phone.delete.conversation');
export const DELETE_CONVERSATION_LOWERCASE = iText('Delete conversation', 'pwa.phone.delete.conversation.lowercase');
export const CONFIRM_DELETE_DESC = iText(
    'If you delete this conversation, you will permanently remove all messages in this conversation. This action cannot be undone.',
    'pwa.sms.delete.conversation.confirm.desc',
);
export const CONFIRM_BLOCK_DESC = iText(
    'You will not receive phone calls or text messages from this number.',
    'pwa.phone.confirm.block.number.desc',
);
export const BLOCK_TITLE_TEXT = (name: string) => iText('Block {0}', 'pwa.phone.block.title', [name]);
export const BLOCK_TEXT = iText('Block', 'pwa.phone.block');
export const BLOCKED_NUMBER_TEXT = (phoneNumber: string) =>
    iText('Blocked {0}', 'pwa.phone.blocked.number', [phoneNumber]);
export const BLOCKED_NUMBER_Failure_TEXT = (phoneNumber: string) =>
    iText('Block {0} failed', 'pwa.phone.blocked.number.failed', [phoneNumber]);
export const BLOCK_AS_SPAM = iText('Spam Messages', 'pwa.phone.block.number.spam');
export const BLOCK_AS_OTHER = iText('Other Reasons', 'pwa.phone.block.number.other');
export const BLOCK_NUMBER = iText('Block Number', 'pwa.phone.block.number.block');
export const Hang_Up_Text = iText('Hang up', 'pwa.phone.hang_up');
export const N_CALLS_ON_HOLD = (num: number) => iText('{0} CALLS ON HOLD', 'pwa.n_calls_on_hold', [num]);
export const N_CALLS_ON_HOLD_2 = (num: number) => iText('{0} Calls on Hold', 'pwa.n_calls_on_hold_2', [num]);
export const CLICK_TO_SEE_ALL = iText('Click to see all', 'pwa.click_to_see_all');
export const LOADING_E911_ADDR = iText('Loading Emergency Address...', 'pwa.loading_e911_addr');
export const CALLER_ADDR = (what: string) => iText("Caller's {0} Address", 'pwa.caller_addr', [what]);
export const INVALID_NUMBER_CHECK = iText(
    'Invalid number. Please check the number and try again.',
    'pwa.invalid_number_check',
);
export const BACK_TO_CALL = iText('Back to Call', 'pwa.back_to_call');
export const HIDE_KEYPAD = iText('Hide Keypad', 'pwa.hide_keypad');
export const IMAGE_ATTACHMENT = iText('Image', 'pwa.image_attachment');
export const FILE_ATTACHMENT = iText('File', 'pwa.file_attachment');

export const TRANSFERRING = iText('Transferring...', 'pwa.transferring');
export const TRANSFER_SUCC = iText('Transfer Success', 'pwa.transfer_succ');
export const TRANSFER_ERR = iText('Transfer Error', 'pwa.transfer_err');
export const LOADING = iText('Loading...', 'pwa.phone.sms.loading');
export const FAILED_TO_LOAD_TRANSCRIPT = iText('Failed to load transcript', 'pwa.phone.failed.to.load.transcript');
export const FAILED_TO_LOAD_CHATS = iText('Failed to load chats', 'pwa.phone.failed.to.chats');
export const NUMBER_DOES_NOT_EXIST = iText('number does not exist', 'pwa.warnings.number_does_not_exist');
export const CALL_QUEUE_FROM_TEXT = iText('From:', 'pwa.call_queue.from_text');

export const DELETE_MESSAGE = iText('Delete Message', 'pwa.phone.delete.message');
export const MESSAGE_COPY = iText('Copy Text', 'pwa.phone.message.copy');
export const FAILED_MESSAGE_DELETE = iText(
    'This message will be removed for you. Other receivers will still be able to see it. Are you sure you want to delete the message?',
    'pwa.phone.failed.message.delete',
);
export const UNABLE_TO_DELETE_MSG = iText('Unable to delete message', 'pwa.phone.unable.to.delete.message');

export const ABOUT_SMS = iText('About this conversation', 'pwa.about_this_conversation');
export const SMS_MEMBERS = iText('Members', 'pwa.info_members');
export const MORE_INFO_IMAGES_TEXT = iText('Images', 'pwa.more_info_images_text');
export const MORE_INFO_IMAGES_EMPTY = iText(
    'Images shared in this conversation will appear here',
    'pwa.more_info_images_empty',
);
export const MORE_INFO_FILES_TEXT = iText('Files', 'pwa.more_info_files_text');
export const MORE_INFO_FILES_EMPTY = iText(
    'Files shared in this conversation will appear here',
    'pwa.more_info_files_empty',
);
export const MESSAGE_DOES_NOT_EXIST = iText('Message does not exist', 'pwa.phone.message.does.not.exist');
export const PHONE_TEXT = iText('Phone', 'pwa.phone_text');
export const EXTENSION_TEXT = iText('Extension', 'pwa.phone_extension');
export const DIRECT_NUMBER_TEXT = iText('Direct Number', 'pwa.phone_direct_number');

export const CHANGE_SKIN_TONE_TEXT = iText('Change Skin Tone', 'pwa.phone.sms_change_skin_tone');
export const ALL_MESSAGES_TEXT = iText('All messages', 'pwa.phone_all_messages');
export const NO_RESCENT_TEXT = iText('No Recent', 'pwa.phone_no_recent');
export const NO_DIRECT_NUMBER = iText(
    "You don't have a direct number. You cannot start a new conversation.",
    'pwa.phone.no_direct_number',
);
export const ADMIN_NO_DIRECT_NUMBER = iText(
    'Your Direct Number has been removed by your admin. You cannot send messages',
    'pwa.phone_admin_no_direct_number',
);
export const RELEASE = iText('Release', 'pwa.phone.release');
export const REPLY = iText('Reply', 'pwa.phone.reply');

export const CallerId_Block_Text = iText(
    'Caller ID blocking is not available due to your assigned policies. Please contact your admin for additional help.',
    'pwa.callerid_block',
);

export const RESTRICT_IP_TEXT = iText(
    'Unable to access recordings from the current IP address',
    'pwa.restrict_ip_text',
);

export const CONFERENECE_CALL = iText('Conference Call', 'pwa.phone.conference_call');
export const YOU_TEXT = iText('you', 'pwa.phone.you');
export const MERGE_CALL = iText('Merge Call', 'pwa.phone.merge_call');
export const JOINED_CONFERENCE_CALL = (who: string) =>
    iText('{0} joined the call', 'pwa.phone.conference_joined', [who]);
export const LEFT_CONFERENCE_CALL = (who: string) => iText('{0} has left the call', 'pwa.phone.conference_left', [who]);
export const PLAY_BTN_DISABLE_TEXT = iText('Play', 'pwa.play_button_disabled');

export const END_CALL = (who: string) => iText('End {0} call', 'pwa.phone.conference_end_call', [who]);

export const INDEX_OF_F = (num1: number, num2: number) => iText('{0} of {1}', 'pwa.items_index', [num1, num2]);

export const Opt_Out_From_All_Call_Queues = iText('Opt-out from all Call Queues', 'pwa.opt_out_from_call_queue');
export const Opt_Out_From_All_Call_Queues_Desc = iText(
    'Would you like to opt out from all the Call Queues after signing out?',
    'pwa.opt_out_from_call_queue_desc',
);
export const Opt_Out_Text = iText('Opt out', 'pwa.opt_out');
export const Keep_Me_Opt_In_Text = iText('Keep me Opt in', 'pwa.keep_me_opt_in');
export const Recording_Unavailable_Text = iText(
    'This recording is currently unavailable.',
    'pwa.recording.unavailable',
);
export const KEYBOARD_INSTRUCTIONS_NAVBAR = iText(
    'press shift+F10 to open the context menu. Press Alt or Option + Arrow Down to move tab to the right. Press Alt or Option + Arrow Up to move tab to the left.',
    'pwa.keyboard_instructions_navbar',
);
export const Starred_Text = iText('Starred', 'pwa.starred');
export const Search_Starred_Contact_Text = iText('Search starred contacts', 'pwa.search_starred_contacts');
export const For_Text = (callQueue: string) => iText('For {0}', 'pwa.for', [callQueue]);
export const Accepted_By_For_Text = (user1: string, user2: string) =>
    iText('Accepted by {0} for {1}', 'pwa.accepted_by_for', [user1, user2]);
export const Accepted_By_Text = (user: string) => iText('Accepted by {0}', 'pwa.accepted_by', [user]);
export const Restricted_Text = iText('Restricted', 'pwa.restricted');
export const Missed_from_Text = (userName: string) => iText('Missed from {0}', 'pwa.missed_from', [userName]);
export const Missed_for_Text = (userName: string) => iText('Missed for {0}', 'pwa.missed_for', [userName]);
export const Others_Text = iText('others', 'pwa.others');
export const Outgoing_By_For_Text = (user1: string, user2: string) =>
    iText('Outgoing by {0} for {1}', 'pwa.outgoing_by_for', [user1, user2]);
export const Outgoing_By_Text = (user: string) => iText('Outgoing by {0}', 'pwa.outgoing_by', [user]);
