import React, { CSSProperties, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../routes/routePath';
import { chatAgent } from './ChatAgent';
import { SOMETHING_WRONG } from '../../resource';
import { ErrorBoundary } from 'react-error-boundary';
import { useZoomUserSessionStatus } from './hooks';
import styles from './index.module.scss';
import { CHAT_APP_NAME, getTeamChatEntryUrl } from '../../configs';
import classNames from 'classnames';

const FallbackComponent = ({ style }: { style: CSSProperties }) => {
    return (
        <div style={style} className={styles['chat-app']}>
            <div className={styles.fallback}>{SOMETHING_WRONG}</div>
        </div>
    );
};

const Chat = () => {
    const match = useRouteMatch(RoutePath.Chat);
    const isMatch = Boolean(match);
    const style = {
        display: isMatch ? 'block' : 'none',
    };
    const newChatSwitchEnabled = !!window.PwaConfig.newChatSwitchEnabled;

    useEffect(() => {
        chatAgent.init();
        return () => {
            chatAgent.uninit();
        };
    }, []);

    useZoomUserSessionStatus();

    const classes = classNames(styles['chat-app'], {
        [styles['chat-app-not-matched']]: !isMatch,
    });

    return (
        <ErrorBoundary fallback={<FallbackComponent style={style} />}>
            <micro-app
                class={classes}
                name={CHAT_APP_NAME}
                baseroute={`/wc/${CHAT_APP_NAME}`}
                url={getTeamChatEntryUrl()}
                inline
                disable-scopecss
                // data-main-body
                router-mode="pure"
                iframe={newChatSwitchEnabled}
            />
        </ErrorBoundary>
    );
};

export default React.memo(Chat);
