import React from 'react';
import { IconVideoCameraOnFill, IconPlusSquircleFill, IconBack, IconVideoCameraOffFill } from '@zoom/icons-react';
import { shallowEqual } from 'react-redux';
import classnames from 'classnames';
import './Home.scss';
import { meetingAgent } from '../../app-init';
import { DOM_KEY_CODE } from '../../utils/constants';
import { JOIN, NEW_MEETING, Schedule, BACK_TO_MEETING_1 } from '../../resource';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { isTeslaMode } from '../../utils';
import { isDuringMeetingSelector, setIsMeetingMinimized } from '../Meeting/redux';
import { DRIVE_WARNING_TEXT } from '../../vendors/tesla/resource';
import StartMeetingLabel from './StartMeetingLabel';
import { isZCCInVideoCall } from '../../store/appModules/contact-center';
import { RoutePath, history } from '../../routes';
import { setShowJoinMeeting } from '../../store/common/common-store';
import CalendarNumber from './CalendarNumberSVG';
import { webclientLogger } from '../../logger/pwa-loggers';
import { PWAMeetingEvent } from '../Meeting/meeting-agent-msg-type';
import { pmiMeetingSettingSelector } from '../Meetings/Meetings/redux/meetings-selector';
import { useScheduleDialogLoading } from '../Calendar/calendar-hooks';
import CalendarSidebarForHomePlaceholder from '../Calendar/CalendarSidebarForHomePlaceholder';
import { hideShceduleMeetingAction, isCalendarEnabled } from '../../utils/featureOptions';
import Upcoming from '../Meetings/Upcoming/Upcoming';

export default function Home({ cachedCalendarSidebarID }: { cachedCalendarSidebarID?: string }) {
    const dispatch = useAppDispatch();
    const { showWebclient, isStartDisabled, isDuringMeeting, startWithVideoOn, startWithPmi, hideScheduleMeeting } =
        useAppSelector((state) => {
            return {
                isDuringMeeting: isDuringMeetingSelector(state),
                showWebclient: state.meeting.showWebclient,
                isStartDisabled: state.meeting.isStartDisabled,
                startWithVideoOn: state.common.userSettings.video.startWithVideoOn,
                startWithPmi: state.common.userSettings.video.startWithPmi,
                hideScheduleMeeting: hideShceduleMeetingAction(state),
            };
        }, shallowEqual);

    const [clickScheduleDialogHandler, isScheduleDialogLoading] = useScheduleDialogLoading();

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);
    const pmiSetting = useAppSelector(pmiMeetingSettingSelector);
    const isJoinOnly = window.PwaConfig.isNoMeetingsLicenseTypeUser;
    const isTesla = isTeslaMode();

    const onClickIcon = (name: string) => {
        if ('start' === name) {
            if (!showWebclient) {
                webclientLogger.log(
                    {
                        from: 'Home UI',
                        desc: 'manually',
                        pmi: startWithPmi,
                    },
                    ['Start'],
                );
                if (startWithPmi) {
                    if (!pmiSetting) {
                        console.debug('pmisetting is not exist');
                        return;
                    }
                    const { meetingNumber } = pmiSetting;
                    meetingAgent.startMeetingWithMeetingNumber(meetingNumber);
                    return;
                }
                meetingAgent.startMeeting(undefined, startWithVideoOn);
            }
        }

        if ('join' === name) {
            // for now weblient iframe is put above pwa
            // in the future, webclient may be a small div or window, and join meeting button will be seen
            // if we click join, we just focus the current webclient window or div
            // for now we do nothing.
            if (showWebclient) {
                // meetingAgent.joinMeeting();
                return;
            } else {
                if (window.PwaConfig?.enableMeetingRoute) {
                    history.push(RoutePath.Join);
                } else {
                    dispatch(setShowJoinMeeting(true));
                }
            }
        }

        if ('return' === name) {
            console.log('return to meeting');
            dispatch(setIsMeetingMinimized(false));
            meetingAgent.sendMsgToWebClient(PWAMeetingEvent.BACK_TO_MEETING);
        }

        if ('schedule' === name) {
            if (typeof clickScheduleDialogHandler === 'function') {
                clickScheduleDialogHandler();
            }
        }
    };

    const onKeydownIcon = (e: React.KeyboardEvent, name: string) => {
        if (e.keyCode === DOM_KEY_CODE.ENTER) {
            onClickIcon(name);
        }
    };

    const icons = [
        {
            show: showWebclient && isDuringMeeting,
            tag: 'return',
            label: BACK_TO_MEETING_1,
            ariaLabel: BACK_TO_MEETING_1,
            disabled: false,
            Icon: IconBack,
        },
        {
            show: !isJoinOnly && (!showWebclient || !isDuringMeeting),
            tag: 'start',
            label: <StartMeetingLabel label={NEW_MEETING} />,
            ariaLabel: NEW_MEETING,
            disabled: (showWebclient && !isDuringMeeting) || isStartDisabled || isZCCInMeeting,
            Icon: startWithVideoOn ? IconVideoCameraOnFill : IconVideoCameraOffFill,
        },
        {
            show: true,
            tag: 'join',
            label: JOIN,
            ariaLabel: JOIN,
            disabled: showWebclient || isStartDisabled || isZCCInMeeting,
            Icon: IconPlusSquircleFill,
        },
        {
            show: !(isTesla || isJoinOnly || hideScheduleMeeting),
            tag: 'schedule',
            label: Schedule,
            ariaLabel: Schedule,
            disabled: Boolean(isScheduleDialogLoading),
            Icon: CalendarNumber,
        },
    ].filter((_) => _.show);

    const iconRows = [];
    while (icons.length > 0) {
        const first = icons.shift();
        const second = icons.shift();
        iconRows.push([first, second].filter((_) => _));
    }

    const iconRowsElements = iconRows.map((rows, index) => {
        return (
            <div key={index} className="main__actions-row">
                {rows.map((item) => {
                    const { tag, label, ariaLabel, disabled, Icon } = item;
                    const cls = classnames(`main__action-${tag}`, {
                        disabled: disabled,
                    });

                    return (
                        <div className={cls} key={tag}>
                            <button
                                className="main__action-btn"
                                aria-label={ariaLabel}
                                onClick={() => onClickIcon(tag)}
                                onKeyDown={(e) => onKeydownIcon(e, tag)}
                                disabled={disabled}
                            >
                                <Icon aria-hidden={true} />
                            </button>

                            {typeof label !== 'object' ? <span>{label}</span> : label}
                        </div>
                    );
                })}
            </div>
        );
    });

    const OpButtonsElem = (
        <div className="main__actions" key="op-buttons">
            {iconRowsElements}
        </div>
    );
    const UpcomingsElem = <Upcoming key="upcomings" />;

    const calendarEnabled = isCalendarEnabled();

    const Elems = [OpButtonsElem];

    if (isTesla) {
        Elems.unshift(UpcomingsElem);
    } else {
        Elems.push(
            calendarEnabled ? <CalendarSidebarForHomePlaceholder cacheID={cachedCalendarSidebarID} /> : UpcomingsElem,
        );
    }

    return (
        <div className="main" role="tabpanel" id="home-tabpanel-main" aria-labelledby="home-tab-main">
            {Elems.map((e) => e)}
            {isTesla && <div className="drive-warning">{DRIVE_WARNING_TEXT}</div>}
        </div>
    );
}
