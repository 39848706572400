import React, { useEffect, useRef } from 'react';
import { EChatOutgoingMessages } from './chat-types';
import { chatAgent } from './ChatAgent/';
import { CHAT_SEARCH_ROOT_ID } from '.';

function ChatSearch() {
    const chatSearchRootRef = useRef();

    useEffect(() => {
        if (!chatSearchRootRef.current) {
            return;
        }
        chatAgent.postMessage({
            type: EChatOutgoingMessages.SEARCH_MOUNT_DOM,
            data: {
                chatSearchContainer: chatSearchRootRef.current,
            },
        });
    }, [chatSearchRootRef.current]);

    return <div id={CHAT_SEARCH_ROOT_ID} ref={chatSearchRootRef}></div>;
}

export default React.memo(ChatSearch);
