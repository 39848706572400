import { createSelector } from '@reduxjs/toolkit';
import { GroupFrom, GROUP_ID, JID, LABLE_GROUP } from '../types';
import { RootState } from '../../../store';
import { correctName, isExternalGroup } from '../utils/stringUtils';
import { COMPANY_CONTACT_TEXT, ZOOM_ROOMS_TEXT } from '../../../resource';
import { featureOptionManager } from '../../../app-init';
import {
    enableFarEndCameraControlSelector,
    enableAutoFarEndCameraControlSelector,
} from '../../../store/common/userWebSettingsSelector';

export const searchResultSelector = (state: RootState) => state.contacts.searchResult;
export const presenceByJidSelector = (state: RootState, jid: JID) => state.contacts.presences[jid];

export enum IRenderType {
    LABEL,
    GROUP,
    CONTACT,
    SENTINEL,
}
export interface IGroupRender {
    renderType: IRenderType.GROUP;
    groupId: string;
    groupFrom: GroupFrom;
}

export interface IContactRender {
    renderType: IRenderType.CONTACT;
    jid: JID;
    groupId: string;
    groupFrom: GroupFrom;
    contactInGroupIndex: number;
}

export interface ILabelRender {
    renderType: IRenderType.LABEL;
    labelId: LABLE_GROUP;
}

export interface ISentinelRender {
    renderType: IRenderType.SENTINEL;
    groupId: string;
}

export type TItemRender = IGroupRender | IContactRender | ILabelRender | ISentinelRender;

export const selectGroups = (state: RootState) => state.contacts.groups;
export const selectContacts = (state: RootState) => state.contacts.contacts;

export const selectGroupById = createSelector(
    selectGroups,
    (_state: RootState, gid: GROUP_ID) => gid,
    (groups, gid) => {
        return groups[gid];
    },
);

export const selectContactByJid = createSelector(
    selectContacts,
    (_state: RootState, jid: JID) => jid,
    (contacts, jid) => contacts[jid],
);

export const selectAllContacts = (state: RootState) => {
    return Object.values(state.contacts.contacts);
};

export const isGroupOrContactSelectedSelector = (state: RootState, jid: JID, groupId: GROUP_ID) => {
    const selectedItem = state.contacts.selectedItem;
    return selectedItem && 'jid' in selectedItem && selectedItem.jid === jid && selectedItem.groupId === groupId;
};

// we manually specify the order of labels
export const CONTACT_LABEL_LIST = [LABLE_GROUP.MY, LABLE_GROUP.SHARED, LABLE_GROUP.ALL];

export const selectLableToGroupTable = (state: RootState) => state.contacts.labelToGroupTable;
export const selectGroupToContactTable = (state: RootState) => state.contacts.groupToContactTable;
export const selectUIGroupInfo = (state: RootState) => state.contacts.ui.groups;

export const selectContactRenderList = createSelector(
    selectLableToGroupTable,
    selectGroupToContactTable,
    selectUIGroupInfo,
    (labelToGroupTable, groupToContactTable, groupsUiInfo) => {
        const result: Array<TItemRender> = [];
        console.time('selector render, uuuuuuuuuuuuuuuuuuuuuu');
        // we manually specify the order of labels
        // const labelList = [LABLE_GROUP.MY, LABLE_GROUP.SHARED, LABLE_GROUP.ALL];
        CONTACT_LABEL_LIST.forEach((labelId) => {
            const labelGroups = labelToGroupTable[labelId];

            if (!Array.isArray(labelGroups)) return;

            if (labelGroups.length > 0) {
                result.push({
                    renderType: IRenderType.LABEL,
                    labelId: labelId,
                });
            }

            labelGroups.forEach((group) => {
                const { id, groupFrom } = group;
                const { folded } = groupsUiInfo[id];

                // add group itself
                result.push({
                    renderType: IRenderType.GROUP,
                    groupId: id,
                    groupFrom: groupFrom,
                });

                if (folded) return;

                const groupContacts = groupToContactTable[id];

                if (!Array.isArray(groupContacts)) return;

                // add group members
                groupContacts.forEach((jid, index) => {
                    result.push({
                        renderType: IRenderType.CONTACT,
                        jid: jid,
                        groupId: id,
                        groupFrom: groupFrom,
                        contactInGroupIndex: index + 1,
                    });
                });

                // add sentinel at the end
                result.push({
                    renderType: IRenderType.SENTINEL,
                    groupId: id,
                });
            });
        }); // end of label list forEach
        console.timeEnd('selector render, uuuuuuuuuuuuuuuuuuuuuu');
        return result;
    },
);

export const selectContactSearchList = createSelector(searchResultSelector, (searchResult) => searchResult);

export const composeContactSearchListDataSelector = createSelector(
    [searchResultSelector, selectContacts],
    (searchContacts, contacts) => {
        return searchContacts.map((item) => {
            const { jid } = item;
            const contact = contacts[jid] || {};
            return Object.assign({}, item, contact);
        });
    },
);

export const formatContactGroupByIdSelector = (state: RootState, groupId: string) => {
    const pendingContactsCount = state.contacts.pendingContacts.length;
    const groups = state.contacts.groups;
    const { id, name, total, totalCountFromApi } = groups[groupId];

    const exactTotal = totalCountFromApi || total;

    const count = isExternalGroup(id)
        ? parseInt(String(exactTotal)) + pendingContactsCount
        : parseInt(String(exactTotal));

    const limitedTotal = Math.min(1000, count);
    const groupName = correctName(name);
    const _total = groupName === COMPANY_CONTACT_TEXT || groupName === ZOOM_ROOMS_TEXT ? exactTotal : limitedTotal;

    return {
        total: _total,
        groupName,
    };
};

export const getLabelbyGroupIdSelector = (state: RootState, groupId: string) => {
    const labelToGroupTable = state.contacts.labelToGroupTable;
    const currentLabels = Object.keys(labelToGroupTable);

    const labels = CONTACT_LABEL_LIST.filter((item) => currentLabels.includes(item));

    const reult = labels
        .map((item) => {
            const data = labelToGroupTable[item].find(({ id }) => id === groupId);
            return data ? item : '';
        })
        .filter((_) => !!_);

    const labelId = reult[0] || '';
    const labelGroups = labelToGroupTable[labelId] || [];

    return { labelId, labelGroups };
};

export const showFarEndCameraControlGroupSelector = (state: RootState) => {
    return (
        featureOptionManager.isFarEndCameraControlGroupOn() &&
        enableFarEndCameraControlSelector(state) &&
        enableAutoFarEndCameraControlSelector(state)
    );
};
