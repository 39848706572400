import React from 'react';
import ConfirmDialog from './ConfirmDialog';
import { hideModal } from '../../../store/modal/modal-helper';
import { Delete_Contact_Success, Delete_This_Contact, Want_To_Delete_Contact } from '../../../resource';
import { ICON_TYPE, toast } from 'Toast';
import { deletePersonalContactThunk } from '../redux/personal-contact-thunks';
import { useAppDispatch } from '../../Phone/types';

interface IProps {
    data: {
        show: boolean;
        userId: string;
        userName: string;
        jid: string;
        groupId: string;
    };
}

const DelatePersonalContact = ({ data: { userId, userName, jid, groupId } }: IProps) => {
    const closeMe = () => {
        hideModal('deletePersonalContact');
    };
    const dispatch = useAppDispatch();

    const deleteContact = () => {
        closeMe();
        dispatch(deletePersonalContactThunk({ userId, jid, groupId }))
            .then(() => {
                toast({
                    type: ICON_TYPE.INFO,
                    desc: Delete_Contact_Success(userName),
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <ConfirmDialog
            isOpen={true}
            title={Delete_This_Contact(userName)}
            body={Want_To_Delete_Contact(userName)}
            closeMe={closeMe}
            cancelBtn
            dangerConfirmBtn={{ handler: deleteContact }}
        />
    );
};

export default DelatePersonalContact;
