import { useEffect } from 'react';
import serviceContainer from '../../services/container';
import { ZpnsMsgCategory, ZpnsMsgType } from '../../services/zpns/zpns-defs';
import { showOTPNotificationThunk } from '../../store/common/common-thunk';

export default () => {
    useEffect(() => {
        const eventBus = serviceContainer.getEventBus();
        const reduxStore = serviceContainer.getReduxStore();
        const subId = eventBus.zpns.message.subscribe((msg) => {
            if (
                ZpnsMsgType.push === msg.type &&
                ZpnsMsgCategory.Web === msg.category &&
                msg.name === 'otp_notification'
            ) {
                reduxStore.dispatch(showOTPNotificationThunk(msg.event));
            }
        });
        return () => {
            eventBus.zpns.message.unsubscribe(subId);
        };
    }, []);
};
