import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'Modal';
import { connect } from 'react-redux';
import './index.scss';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { DropdownButton, Dropdown } from '../../../components/Dropdown/Dropdown';
import { IconChevronDownSmall } from '@zoom/icons-react';
import classNames from 'classnames';
import { Button } from '@zoom/zoom-react-ui';
import {
    CANCEL,
    FEEDBACK_PREFILLED_TEXT,
    FEEDBACK_TECHNICAL_SUPPORT_TEXT,
    FEEDBACK_TEXT,
    FEEDBACK_TEXT_BOX_AX,
    GIVE_FEEDBACK_TEXT,
    PRODUCT_TEXT,
    SATISFACTION_SCORE_WARNING,
    SCORE_TEXT,
    THANK_YOU_RESPONSE_TOAST,
    VERY_DISSATISFIED_TEXT,
    VERY_SATISFIED_TEXT,
} from '../../../resource';
import { submit } from '../../Meeting/feedback/resource';
import { submitFeedBack } from '../../../services/http/apis';
import { ICON_TYPE, toast } from 'Toast';
import { i18nWithLink } from '../../../components/I18nStrings/i18nStringWithLink';

const GiveFeedback = () => {
    const dispatch = useAppDispatch();
    const closeRef = useRef(null);

    const [productArea, setProductArea] = useState('General');
    const [score, setScore] = useState('');
    const [message, setMessage] = useState('');
    const [messageLength, setMessageLength] = useState(0);
    const [showSatisfactionAlert, setShowSatisfactionAlert] = useState(false);

    const products = ['General', 'Team Chat', 'Phone', 'Whiteboard', 'Meetings', 'Contact Center'];

    const scores = ['1', '2', '3', '4', '5'];

    const renderLink = (content: string) => {
        return (
            <a href="https://support.zoom.com" target="_blank">
                {content}
            </a>
        );
    };

    useEffect(() => {
        closeRef.current && closeRef.current.focus();
    }, []);

    const closeMe = () => {
        dispatch(setModal({ name: 'giveFeedback', data: null }));
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
        setMessageLength(e.target.value.length);
    };

    const handleSubmitFeedback = () => {
        if (score !== '') {
            submitFeedBack(productArea, score, message).then(() => {
                toast({
                    type: ICON_TYPE.SUCCESS,
                    desc: THANK_YOU_RESPONSE_TOAST,
                });
            });
            closeMe();
        }
        if (score === '') {
            setShowSatisfactionAlert(true);
        }
    };

    const draggableProps = {
        handle: '.dragHandle',
    };

    return (
        <Modal
            isOpen={true}
            isDraggable={true}
            aria={{ labelledby: 'zm-about-version' }}
            centered={true}
            onRequestClose={closeMe}
            draggableProps={draggableProps}
        >
            <>
                <div className="dragHandle"></div>
                <div className="modal-give-feedback">
                    <div className="modal-give-feedback__header">{GIVE_FEEDBACK_TEXT}</div>
                    <div className="modal-give-feedback__body">
                        <div className="feedback-type-wrapper">
                            <div className="feedback-title-container">
                                <span>{PRODUCT_TEXT}</span>
                                <span className="asterix">*</span>
                            </div>

                            <DropdownButton
                                className="feedback-dropdown-button"
                                title={
                                    <div aria-label={`Product Area, ${productArea}`} aria-required={true}>
                                        {productArea}
                                        <IconChevronDownSmall className="chevron-down" aria-hidden={true} />
                                    </div>
                                }
                                autoFocus={true}
                            >
                                {products.map((product: string, index: number) => (
                                    <Dropdown.Item
                                        key={index}
                                        eventKey={index}
                                        onClick={() => setProductArea(product)}
                                        active={product === productArea}
                                    >
                                        {product}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        <div className="feedback-type-wrapper">
                            <div className="feedback-title-container">
                                <span id="satisfaction">{SCORE_TEXT} </span>
                                <span className="asterix">*</span>
                            </div>

                            <div className="score-options" role="radiogroup" aria-labelledby="satisfaction">
                                {scores.map((number: string) => (
                                    <button
                                        key={number}
                                        onClick={() => setScore(number)}
                                        className={classNames('score-button', { active: score === number })}
                                        role="radio"
                                        aria-checked={number === score}
                                        aria-label={`${
                                            number === '1'
                                                ? `${number} ${VERY_DISSATISFIED_TEXT}`
                                                : number === '5'
                                                ? `${number} ${VERY_SATISFIED_TEXT}`
                                                : ''
                                        }`}
                                    >
                                        {number}
                                    </button>
                                ))}
                            </div>
                            <div className="lowest-highest-ratings">
                                <span className="satisfaction-subtitle"> {VERY_DISSATISFIED_TEXT}</span>
                                <span className="satisfaction-subtitle-right">{VERY_SATISFIED_TEXT}</span>
                            </div>
                            {showSatisfactionAlert && (
                                <div className="satisfaction-empty-warning">
                                    <span> {SATISFACTION_SCORE_WARNING}</span>
                                </div>
                            )}
                        </div>
                        <div
                            className="feedback-type-wrapper"
                            style={showSatisfactionAlert ? { marginTop: '0px' } : {}}
                        >
                            <span className="feedback-text">{FEEDBACK_TEXT}</span>
                            <textarea
                                className="feedback-text-input"
                                placeholder={FEEDBACK_PREFILLED_TEXT}
                                maxLength={20000}
                                value={message}
                                onChange={(e) => handleOnChange(e)}
                                aria-label={FEEDBACK_TEXT_BOX_AX}
                            ></textarea>
                            <span className="message-length">{messageLength}/20000</span>
                        </div>
                        <div className="give-feedback-footer-buttons">
                            <Button ariaLabel="Cancel" onClick={() => closeMe()}>
                                {CANCEL}
                            </Button>
                            <Button ariaLabel="Submit" type="primary" onClick={handleSubmitFeedback}>
                                {submit}
                            </Button>
                        </div>
                    </div>
                    <div className="modal-give-feedback__footer">
                        <div className="technical-suppport-text">
                            <span>{i18nWithLink(FEEDBACK_TECHNICAL_SUPPORT_TEXT, [renderLink])}</span>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    );
};

export default connect()(GiveFeedback);
