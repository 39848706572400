import { ContactCenterAgent } from '.';
import serviceContainer from '../../../services/container';
import store from '../../../store';
import { closeZoomApp } from '../../ZApps/redux/zoom-apps-thunk';
import engagements from '../Engagement';
import { queueToAppList } from '../QueueToApps';
import { selectContactCenterRunningAppList } from '../redux/zcc-apps-selector';

const DESTROY_WAIT_SECONDS = 1000 * 60 * 3;

let destroyTimer = 0;

export const scheduleDestroyAppInstancesProcess = (props: { contactCenterAgent: ContactCenterAgent }) => {
    clearTimeout(destroyTimer);

    destroyTimer = window.setTimeout(() => doDestroyProcess(props), DESTROY_WAIT_SECONDS);
};

const doDestroyProcess = (props: { contactCenterAgent: ContactCenterAgent }) => {
    const reduxStore = serviceContainer.getReduxStore();
    const { contactCenterAgent } = props;

    // collect all app instances from redux
    const runningApps = selectContactCenterRunningAppList(reduxStore.getState());

    // collect all possiblely used app ids
    const existingEngagementQueueIds = [...new Set(engagements.getAll().map((eng) => eng.queueId))];
    const existingAppIdSet = new Set(
        existingEngagementQueueIds.reduce((acc, cur) => {
            const appList = queueToAppList.getAppListByQueue(cur) || [];
            return acc.concat(appList);
        }, []),
    );

    const toDestoryApps = runningApps.filter((runningApp) => !existingAppIdSet.has(runningApp.applicationId));

    toDestoryApps.forEach((app) => {
        // we first remove app in redux
        // then related eg. ZoomAppSuccess component's effect will destroy corresponding app instance
        store.dispatch(closeZoomApp(app));
        contactCenterAgent.notifyAppClose({ appId: app.applicationId });
    });
};
