import React, { useEffect, useState } from 'react';
import { calendarAgent } from './CalendarAgent';
import { createRoot } from 'react-dom/client';
import { IconLoadingSpinner } from '@zoom/icons-react';
import './calendar-sidebar.scss';
import { Loading_Calendar_Text } from '../../resource';

const pwaCalendarSidebarRootInsideDOMID = 'pwaCalendarSidebarRootInside';
const pwaCalendarSidebarContainerDOMID = 'pwaCalendarSidebarContainerDOMID';

class CalendarSidebarElement extends HTMLElement {
    private _calendarSidebarRoot_Inside_ShadowDOM: HTMLDivElement;
    constructor() {
        super();
        this.render();
    }
    render() {
        const shadowDOM = this.attachShadow({ mode: 'open' });
        this._calendarSidebarRoot_Inside_ShadowDOM = this.createCalendarSidebarRootInsideShadowDOM();
        this._calendarSidebarRoot_Inside_ShadowDOM.style.height = "100%";
        shadowDOM.appendChild(this._calendarSidebarRoot_Inside_ShadowDOM);
        this.renderCalendarSidebar();
    }
    createCalendarSidebarRootInsideShadowDOM() {
        const _calendarSidebarRoot_Inside_ShadowDOM = document.createElement('div');
        _calendarSidebarRoot_Inside_ShadowDOM.id = pwaCalendarSidebarRootInsideDOMID;
        _calendarSidebarRoot_Inside_ShadowDOM.classList.add('pwa-calendar-sidebar-root__inside');
        return _calendarSidebarRoot_Inside_ShadowDOM;
    }
    renderCalendarSidebar() {
        const calendarSidebar = <div className='calendar-sidebar__container' style={{height: '100%'}} id={pwaCalendarSidebarContainerDOMID}></div>;
        createRoot(this._calendarSidebarRoot_Inside_ShadowDOM).render(calendarSidebar)
    }
}

customElements.define('calendar-sidebar-app', CalendarSidebarElement);


const CalendarSidebar = () => {
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        const calendarSidebar = document.getElementsByTagName('calendar-sidebar-app')[0];
        if (calendarSidebar.shadowRoot) {
            calendarAgent.calendarAppInitedPromise.then(() => {
                setShowLoading(false);

                const sidebarContainer = calendarSidebar.shadowRoot.getElementById(pwaCalendarSidebarContainerDOMID);
                calendarAgent.sendCalendarSidebarDOM(sidebarContainer);
                const calendarApp = document.body.getElementsByTagName('calendar-app')[0];
                const head = document.createElement('head');
                const styles = calendarApp.shadowRoot?.querySelectorAll('style, link');
                if (styles && styles.length > 0) {
                    const clonedStyles: any  = [];
                    styles.forEach(style => {
                        clonedStyles.push(style.cloneNode(true));
                    })
                    head.append(...clonedStyles);
                    calendarSidebar.shadowRoot?.prepend(head);
                }
                const svgLib = calendarApp.shadowRoot.querySelector('micro-app-body > svg');
                if (svgLib) {
                    sidebarContainer.append(svgLib.cloneNode(true));
                }
            })
        }

    }, [])

    
    return <>
        {showLoading && <div className='loading-calendar-sidebar'>
            <IconLoadingSpinner />
            {Loading_Calendar_Text}
        </div>}
        {/* @ts-ignore */}
        <calendar-sidebar-app />
    </>
}

export default React.memo(CalendarSidebar);