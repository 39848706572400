export const ERR_Unknown = -1;
export const ERR_General = -2;
export const ERR_401 = 401;

export interface Error {
    code: number; // it's only meanfuly in pwa
    message: string; // it's the default message. if you have more specific message from server. user yours
}

export const PwaErrors = {
    Unknown: {
        code: 1,
        message: 'Unknow Error',
    },
    WebSessionExpired: {
        code: 2,
        message: 'Your session has expired and you need login',
    },
    TokenExpired: {
        code: 3,
        message: 'Your credential is invalid.',
    },
    RequestNotSend: {
        code: 4,
        message: 'The PWA failed to send a request to the server.',
    },
};
