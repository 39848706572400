import Axios from 'axios';
import { getCsrfToken } from '../../../../services/http/apis';
import { ERR_401, ERR_NEED_LOGIN } from '../../../Meetings/apis/errcode';
import { PwaErrors } from '../../../../services/error/pwa-error';

const axiosClient = Axios.create({
    // baseURL: window.PwaConfig.serverDomain.nwsDomain,
});

const getNakToken = () => {
    const path = '/nws/common/2.0/nak';
    return Axios.get<string>(path)
        .then((res) => res.data)
        .catch((error) => {
            if (error.response) {
                // request send and server responds not 2xx

                if (error.response.status !== ERR_401 || error.response?.data?.code === ERR_NEED_LOGIN) {
                    throw {
                        code: PwaErrors.WebSessionExpired.code,
                        message: PwaErrors.WebSessionExpired.message,
                    };
                }

                if (error.response?.data) {
                    throw {
                        code: error.response?.data?.code,
                        message: error.response?.data?.msg,
                    };
                }
            } else if (error.request) {
                // request send and server does NOT respond
            } else {
                // request not send or not create successfully
            }

            throw {
                code: PwaErrors.Unknown.code,
                message: PwaErrors.Unknown.message,
            };
        });
};

// callers must take care of errors
const getHeaderTokens = async () => {
    const nak = await getNakToken();
    const csrf = await getCsrfToken();

    return {
        zak: nak,
        ...csrf.headers,
    };
};

export interface IPersonalContactInfo {
    name: string;
    company: string;
    email: string;
    officePhoneNum: string;
    homePhoneNum: string;
    fax: string;
    phoneNum: string;
    mobile: string;
}

interface IResponse<R = null> {
    status: boolean;
    errorCode: number;
    errorMessage: string;
    result: R;
}

export interface IPersonContactInResponse {
    id: string; // userId
    accountId: string;
    userId: string; // it's current user's userId.
    groupId: string;
    groupName: 'Personal Contacts';

    name: string;
    company: string;
    email: string;
    officePhoneNumber: string;
    homePhoneNumber: string;
    mobile: string;
    fax: string;
    phoneNumber: string;

    createTime: number;
    modifyTime: number;
}

export type IResponseCreate = IResponse<{
    personalContact: IPersonContactInResponse;
}>;

export type IPropsCreate = IPersonalContactInfo;

export const createPersonalContact = async (contact: IPropsCreate) => {
    const path = '/nws/imcommon/1.0/user/personalContact/create';

    const headers = await getHeaderTokens();

    const formdata = new FormData();
    for (const [key, value] of Object.entries(contact)) {
        formdata.append(key, value);
    }

    return axiosClient
        .post<IResponseCreate>(path, formdata, {
            headers,
        })
        .then((res) => {
            if (res.data?.errorCode === 0) {
                return res.data.result;
            }

            throw res.data?.errorMessage;
        })
        .catch((error) => {
            throw error;
        });
};

interface IPropsEdit extends IPersonalContactInfo {
    userId: string;
}

export const editPersonalContact = async (props: IPropsEdit) => {
    const path = '/nws/imcommon/1.0/user/personalContact/edit';
    const headers = await getHeaderTokens();

    const formdata = new FormData();

    const contact = props;
    Object.assign(contact, {
        personalContactId: props.userId,
    });
    delete contact.userId;

    for (const [key, value] of Object.entries(contact)) {
        formdata.append(key, value);
    }

    return axiosClient.post(path, formdata, {
        headers,
    });
};

export interface IPropsDelete {
    userId: string;
}

export const deletePersonalContact = async (props: IPropsDelete) => {
    const path = '/nws/imcommon/1.0/user/personalContact/singleDelete';
    const headers = await getHeaderTokens();
    const formdata = new FormData();

    const contact = {
        personalContactId: props.userId,
    };

    for (const [key, value] of Object.entries(contact)) {
        formdata.append(key, value);
    }

    return axiosClient.post(path, formdata, {
        headers,
    });
};
