import { useEffect } from 'react';
import serviceContainer from '../../services/container';
import { ZpnsMsgCategory, ZpnsMsgType } from '../../services/zpns/zpns-defs';
import { zpnsEventHandlerMeetingsThunk } from './Meetings/redux/meetings-thunk';

export const useMeetingsZpnsMessage = () => {
    useEffect(() => {
        const eventBus = serviceContainer.getEventBus();
        const reduxStore = serviceContainer.getReduxStore();

        const subId = eventBus.zpns.message.subscribe((msg) => {
            if (
                ZpnsMsgType.push === msg.type &&
                (ZpnsMsgCategory.Calendar === msg.category ||
                    ZpnsMsgCategory.Meeting === msg.category ||
                    ZpnsMsgCategory.UserSetting === msg.category ||
                    ZpnsMsgCategory.Zr === msg.category)
            ) {
                reduxStore.dispatch(zpnsEventHandlerMeetingsThunk(msg));
            }
        });

        return () => {
            eventBus.zpns.message.unsubscribe(subId);
        };
    }, []);
};
