import React from 'react';
import { IconChatSmallFill, IconVideoOnFill } from '@zoom/icons-react';
import Button from '../../../components/Button/Button';
import { MEET_BUTTON, INVITE_TO_MEETING, Chat_Text } from '../../../resource';
import { IContact } from '../types';
import { getContactDisplayName } from '../redux';
import { chatAgent } from '../../Chat';
import { inviteContact } from '../hooks/useInviteContact';
import { PhoneCallButton } from '../PhoneCallToContact';
import { ContactProperty } from '../hooks/useContactProperty';

type IProps = ContactProperty & {
    contact: IContact;
};

export default function ContactControl({
    contact,
    canWeChat,
    canWeMeet,
    isDuringMeeting,
    canWeDisableMeet,
    isAddon,
    isIMBlocked,
}: IProps) {
    const { jid } = contact;
    const displayName = getContactDisplayName(contact);

    // jump to chat
    const onClickChat = () => {
        chatAgent.chatWithUserOrChannel({ id: jid });
    };

    // invite contact meeting
    const onClickInviteContact = () => {
        if (!jid) return;
        inviteContact([{ jid, name: displayName }]);
    };

    return (
        <div className="contact-detail__controls">
            {canWeChat ? (
                <Button
                    size={Button.Size32}
                    type={Button.Dark}
                    onClick={onClickChat}
                    LeftIcon={<IconChatSmallFill aria-hidden={true} />}
                >
                    {Chat_Text}
                </Button>
            ) : null}

            {canWeMeet ? (
                <Button
                    size={Button.Size32}
                    type={Button.Dark}
                    onClick={onClickInviteContact}
                    disabled={canWeDisableMeet}
                    LeftIcon={<IconVideoOnFill aria-hidden={true} />}
                >
                    {isDuringMeeting ? INVITE_TO_MEETING : MEET_BUTTON}
                </Button>
            ) : null}

            <PhoneCallButton contact={contact} isAddon={isAddon} isIMBlocked={isIMBlocked} />
        </div>
    );
}
