import { AppThunk } from '../../../store';
import { getUcsGroupMembers, getUcsGroups, queryUcsGroupMemberCount } from '../services/ucs';
import {
    createContactFromUcsContact,
    createGroupFromUcsGroup,
    isGroupAddon,
    isGroupSharedOffice,
    isGroupZoomRoom,
} from './contact-utils';
import {
    setGroups,
    setGroupsLoadingState,
    setThisGroupContacts,
    deletGroups,
    deleteGroupContacts,
    updateGroupTotalCount,
} from './contact-store';
import { IGroup, GroupFrom } from '../types';
import { onGetUcsServerGroups, listenXmppEvents } from './ucs-and-xmpp';
import { IUCSGroupCompact } from '../services/ucs-types';
import { requestLimiter } from '../utils/requestUtils';
import { FetchStatus } from '../../../utils/constants';
import { IGroupRender } from './contact-selector';
import { getPendingContactsThunk, tryResetSelectedContactWhenDelete } from './contact-thunk';
import { isNumber } from 'lodash-es';
import { isPersonalContactGroup } from '../utils/stringUtils';

const UCS_MEMBER_COUNT_LIMIT_PER_REQ = 100;
const GROUP_INITIAL_REQUESTS = 6;

export const getUcsGroupsThunk = (): AppThunk => (dispatch, getState) => {
    const {
        common: { userInfo },
    } = getState();

    // get pending contact from indexdb
    dispatch(getPendingContactsThunk());

    const { userId } = userInfo;
    return getUcsGroups(userId).then((res) => {
        // console.log('ucs yyyyyyy', 'getUcsGroups response', res);
        const {
            normal_groups = [],
            sp_groups = [],
            zr_groups = [],
            directory_groups = [],
            assignable_groups = [],
        } = res;
        const allGroups = [].concat(assignable_groups, sp_groups, normal_groups, zr_groups, directory_groups);

        const xmppGroups: Array<IUCSGroupCompact> = [];

        // filter ucsGroups and xmppGroups
        const ucsGroups: Array<IFetchGroup> = allGroups
            .sort((left, right) => {
                const { name: leftName } = left;
                const { name: righName } = right;
                return leftName.localeCompare(righName);
            })
            .map((group) => {
                group.id = group.groupId;
                return group;
            })
            .filter((group) => {
                if (isGroupZoomRoom(group) || isGroupAddon(group) || isGroupSharedOffice(group)) {
                    xmppGroups.push(group);
                    return false;
                }
                return true;
            });

        const groups = ucsGroups.map((group) => {
            return {
                name: group.name,
                id: group.id,
                type: group.type,
                groupFrom: GroupFrom.ucs,
                total: -1,
            };
        });
        // render cotnact list, just has simple group
        dispatch(setGroups({ groups } as any));

        // get detail group infro
        // when scroll list, trigger lazy load, getUcsGroupInfoLazyThunk will be called
        const fetchUcsGroup = (group: IFetchGroup) => dispatch(loadMoreUcsGroupMembersThunk(group));
        requestLimiter(ucsGroups.slice(0, GROUP_INITIAL_REQUESTS), fetchUcsGroup);

        // get xmpp group info from xmpp server
        onGetUcsServerGroups(xmppGroups);
    });
};

interface IFetchGroup {
    id: string;
    type: number | string;
    name: string;
    groupId?: string;
}

/**
 * this is used to load more members of certain ucs group
 * every group has a version in server side. version is like git commit or snapshot of current group data and members.
 * when any data changed, group version changes too.
 *
 * for native client, when you first install it, it will try to fully sync all your contacts from ucs server and store to it's local sqllist database, as well as newest version of group, let's say {version: 100}.
 * by fully sync, you request data with localVersion: -1;
 *
 * when sometime, certain group changed, eg, you add one new contact. server store the changes and gererate new version ({ version: 101 }) for the group and it pushes ZPNS messages to all clients.
 * when native clients are notified, they request incremental changes of the group by attaching it's locally stored group version ({ version: 100})to the request.
 * and server will diff data between {version: 100} and {version: 101}, and respond with ONLY the changes: the new added members
 *
 * here, we are trying to load group member page by page (100 / page), so we have to tell server that we want to paginate whole(full) members, not incremental memebers.
 * and we always request with {localVersion: -1}
 */
export const loadMoreUcsGroupMembersThunk =
    (groupData: IFetchGroup): AppThunk<any> =>
    (dispatch, getState) => {
        const {
            common: { userInfo },
            contacts: { groups, groupsLoadingState },
        } = getState();
        const { id: groupId } = groupData;
        const localGroup = groups[groupId];
        const gLoadingState = groupsLoadingState[groupId];

        // if we already have all the content;
        if (localGroup?.nextCursor === -1) {
            setGroupsLoadingState({
                id: groupId,
                status: FetchStatus.succeeded,
            });
            return Promise.resolve(null);
        }

        // we always request members for ONLY one group
        const reqProps = {
            limit: UCS_MEMBER_COUNT_LIMIT_PER_REQ,
            cursor: 0,
            groups: [
                {
                    groupId: groupId,
                    localVersion: -1, // localVersion should always be -1
                },
            ],
        };

        if (localGroup) {
            reqProps.cursor = localGroup.nextCursor;
            reqProps.groups[0].localVersion = -1; // we don't store data permanent in database, so we always fetch from empty
        }

        // if a request from cursor is already made (idle or loading or successed), do not reqeust it again.
        if (
            gLoadingState &&
            gLoadingState?.cursor === reqProps.cursor &&
            gLoadingState?.status !== FetchStatus.failed
        ) {
            return Promise.resolve(null);
        }

        dispatch(
            setGroupsLoadingState({
                id: groupId,
                cursor: reqProps.cursor,
                limit: reqProps.limit,
                status: FetchStatus.loading,
            }),
        );

        if (!reqProps.cursor) {
            /**
             * per derek.yang,
             * this api doesn't give correct count for personal contact due to stale cache
             */
            if (!isPersonalContactGroup(groupId)) {
                queryUcsGroupMemberCount({ groupId }, userInfo).then((res) => {
                    const { count } = res;
                    dispatch(updateGroupTotalCount({ id: groupId, totalCountFromApi: count }));
                });
            }
        }

        return getUcsGroupMembers(reqProps, userInfo)
            .then((res) => {
                const { groups, nextCursor } = res;
                if (groups.length === 0) {
                    return null;
                }
                const resGroup = createGroupFromUcsGroup(groups[0]);
                if (!resGroup) {
                    // validate group will be deleted in redux state
                    dispatch(deletGroups([groupId]));
                    setGroupsLoadingState({
                        id: groupId,
                        status: FetchStatus.succeeded,
                    });
                    return null; // maybe group is invalid
                }

                resGroup.nextCursor = nextCursor;

                const ucsContacts = groups[0].items || [];

                // remove myself
                const myselfIndex = ucsContacts.findIndex(({ jid }) => jid === userInfo.jid);
                if (myselfIndex > -1) {
                    console.log('teeeeest ucs', 'comtaims me', groupId);
                    ucsContacts.splice(myselfIndex, 1);
                    resGroup.total--;
                }

                dispatch(
                    setThisGroupContacts({
                        groupId: groupId,
                        contacts: ucsContacts.map(createContactFromUcsContact),
                    }),
                );

                // should update contacts first, then update groups
                // if you update groups first, then react renders, the the newly added contacts' data are not added to store
                // the we have null contact data
                dispatch(setGroups({ groups: [resGroup as IGroup] }));
                dispatch(
                    setGroupsLoadingState({
                        id: groupId,
                        status: FetchStatus.succeeded,
                    }),
                );
                return null;
            })
            .catch((_e) => {
                dispatch(
                    setGroupsLoadingState({
                        id: groupId,
                        status: FetchStatus.failed,
                    }),
                );
            });
    };

/**
 * this is used to respond ZPNS notifications about group member changes
 */
export const getUpdatedUcsGroupMembers =
    ({ groupId }: { groupId: string }): AppThunk<any> =>
    (dispatch, getState) => {
        const {
            common: { userInfo },
            contacts: { groups },
        } = getState();

        const localGroup = groups[groupId];

        // we always request members for ONLY one group
        const reqProps = {
            limit: UCS_MEMBER_COUNT_LIMIT_PER_REQ,
            cursor: 0,
            groups: [
                {
                    groupId: groupId,
                    localVersion: -1,
                },
            ],
        };

        if (localGroup) {
            // reqProps.cursor = forceFetch ? 0 : Math.max(localGroup.nextCursor, 0);
            reqProps.groups[0].localVersion = localGroup.version as unknown as number; // we don't store data permanent in database, so we always fetch from empty
        }

        return getUcsGroupMembers(reqProps, userInfo)
            .then((res) => {
                const { groups } = res;
                if (groups.length === 0) {
                    return null;
                }

                const isContctInGroup = (gid: string, jid: string) => {
                    const {
                        contacts: { groupToContactTable },
                    } = getState();

                    const groupMembers = groupToContactTable[gid] || [];
                    return groupMembers.includes(jid);
                };

                const resGroup = createGroupFromUcsGroup(groups[0]);
                if (!resGroup) {
                    // validate group will be deleted in redux state
                    dispatch(deletGroups([groupId]));
                    return null; // maybe group is invalid
                }

                const { startVersion } = resGroup;
                const { items = [], itemsAdded = [], itemsModified = [], itemsRemoved = [] } = groups[0];

                if (String(startVersion) === '-1') {
                    // full sync
                    const ucsContacts = items;

                    // remove myself
                    const myselfIndex = ucsContacts.findIndex(({ jid }) => jid === userInfo.jid);
                    if (myselfIndex > -1) {
                        ucsContacts.splice(myselfIndex, 1);
                        resGroup.total--;
                    }

                    dispatch(
                        setThisGroupContacts({
                            groupId: groupId,
                            contacts: ucsContacts.map(createContactFromUcsContact),
                        }),
                    );
                } else {
                    // incremental sync
                    if (itemsAdded.length !== 0) {
                        const itemsReallyAdded = itemsAdded.filter((con) => !isContctInGroup(groupId, con.jid));

                        dispatch(
                            setThisGroupContacts({
                                groupId: groupId,
                                contacts: itemsAdded.map(createContactFromUcsContact),
                            }),
                        );

                        if (isNumber(localGroup.total)) {
                            resGroup.total = localGroup.total + itemsReallyAdded.length;
                        }

                        if (isNumber(localGroup.totalCountFromApi)) {
                            resGroup.totalCountFromApi = localGroup.totalCountFromApi + itemsReallyAdded.length;
                        }
                    }

                    if (itemsModified.length !== 0) {
                        dispatch(
                            setThisGroupContacts({
                                groupId: groupId,
                                contacts: itemsModified.map(createContactFromUcsContact),
                            }),
                        );
                    }

                    if (itemsRemoved.length !== 0) {
                        const itemsReallyRemoved = itemsRemoved.filter((con) => isContctInGroup(groupId, con.jid));
                        dispatch(
                            deleteGroupContacts({
                                groupId,
                                contacts: itemsRemoved.map((i) => i.jid),
                            }),
                        );

                        if (isNumber(localGroup.total)) {
                            resGroup.total = Math.max(localGroup.total - itemsReallyRemoved.length, 0);
                        }

                        if (isNumber(localGroup.totalCountFromApi)) {
                            resGroup.totalCountFromApi = Math.max(
                                localGroup.totalCountFromApi - itemsReallyRemoved.length,
                                0,
                            );
                        }

                        dispatch(tryResetSelectedContactWhenDelete(itemsReallyRemoved.map((c) => c.jid)));
                    }
                }

                // should update contacts first, then update groups
                // if you update groups first, then react renders, the the newly added contacts' data are not added to store
                // the we have null contact data
                dispatch(setGroups({ groups: [resGroup as IGroup] }));
                return null;
            })
            .catch((e) => {
                console.log(e);
            });
    };

// contact list: group label lazy load
export const getUcsGroupInfoLazyThunk =
    (groupLabelList: Array<IGroupRender>): AppThunk<any> =>
    (dispatch, getState) => {
        if (groupLabelList.length <= 0) return;
        const {
            contacts: { groups },
        } = getState();

        const resultGroup = groupLabelList
            .filter(({ groupId }) => {
                const { total } = groups[groupId];
                // totla === -1 : need requst group info
                // totla === >=0 : has requested
                return total < 0;
            })
            .map(({ groupId }) => {
                const { id, type, name } = groups[groupId];
                return {
                    id,
                    type,
                    name,
                    groupId,
                };
            });

        if (resultGroup.length > 0) {
            // get group members info
            requestLimiter(resultGroup, (group: IFetchGroup) => dispatch(loadMoreUcsGroupMembersThunk(group)));
        }
    };

export const testUcs = (): AppThunk => (dispatch) => {
    listenXmppEvents();
    return dispatch(getUcsGroupsThunk());
};
