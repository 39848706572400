import React from 'react';
import ChatSearch from '../../Chat/ChatSearch';
import { isChatEnabled } from '../../../utils/featureOptions';
import { useAppSelector } from '../../Phone/types';
import classNames from 'classnames';

function HeaderActions() {
    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    return (
        <div className={classNames('home-header__actions', { themed: theme !== 'classic' })}>
            {isChatEnabled() ? <ChatSearch /> : null}
        </div>
    );
}

export default HeaderActions;
