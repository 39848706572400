import dayjs from 'dayjs';
import httpRequest from '../../../../services/http/http';
import HttpClient from '../../apis/HttpClient';
import { FetchStatus } from '../../../../utils/constants';
import { getCsrfToken } from '../../../../services/http/apis';
import qs from 'querystring';
import {
    isRemoveCalendarAgainstMeetings,
    transformZoomMeeting,
    produceMeetingItemId,
    transformCalendarEvent,
    isPMIMeetingSetting,
    isPeriodicMeeting,
    sortMeetings,
    getNwsCalendarListUrl,
    isUserNeedReAuthCalendar,
    isUserNotIntegrateCalendar,
    isPeriodicMeetingWithNoFixedTime,
} from '../utils';
import {
    setMeetings,
    setCalendars,
    setLoadingStstus,
    deleteMeetings,
    updateMeetings,
    setSelectedMeetingItemId,
    setIsConnectedToCalendar,
    setIsNeedReAuthCalendar,
} from './meetings-store';
import { AppDispatch, AppGetState } from '../../../../store';
import {
    CalendarEvent,
    CalendarParseMeetingType,
    EOccurrenceStatus,
    WebCalendarEvent,
    WebMeetingItem,
    ZnpsMeetingItemData,
} from '../types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { EveryoneId } from './meetings-selector';
import { getDeleteMeetingUrl } from '../../../../utils/meeting-url';
import { chatAgent } from '../../../Chat';
import { getClientCaps } from '../../../../utils/clientCapability';
import { isChatEnabled } from '../../../../utils/featureOptions';
import { signoutWhenSeesionExpiredThunk } from '../../../../store/common/sign-out-thunk';
import { ZpnsMsgCategory } from '../../../../services/zpns/zpns-defs';
import { IUserInfo } from '../../../../store/common/common-store';

const nwsHttpClient = new HttpClient();
const axiosInstance = nwsHttpClient.getAxiosInstance({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    params: {
        from: 'pwa',
    },
});

export const maybeUpdateSelectedMeetingItemIdThunk = () => (dispatch: AppDispatch, getState: AppGetState) => {
    const {
        meetings: { meetings, calendars, selectedMeetingItemId, selectedMeetingHostId },
    } = getState();

    const pmiSetting = meetings.find(isPMIMeetingSetting);
    const filteredMeetings = meetings
        .filter((_) => !isPMIMeetingSetting(_))
        .filter((_) => !isPeriodicMeeting(_))
        .filter((_) => selectedMeetingHostId === EveryoneId || _.hostId === selectedMeetingHostId);

    // meetings maybe [];
    const total = [...filteredMeetings, ...calendars].sort(sortMeetings);

    let best = total.find((_) => _.id === selectedMeetingItemId);

    if (!best || (pmiSetting && best.id === pmiSetting.id)) {
        const nearest = total.find(({ startTime, duration }) => {
            return !dayjs().isAfter(dayjs.unix(startTime).add(duration, 'minutes'));
        });
        if (nearest) {
            best = nearest;
        } else if (pmiSetting) {
            best = pmiSetting;
        } else if (total[0]) {
            best = total[0];
        } else {
            best = meetings[0];
        }
    }

    if (best) {
        dispatch(setSelectedMeetingItemId(best.id));
    }
};

export const deleteMeetingFromServer = (options: any) => {
    const { userId = '', mn, occurrence = 0, sendMail = false, mailBody = '' } = options;
    const params = {
        user_id: userId,
        id: mn,
        occurrence,
        sendMail,
        mailBody,
    };

    return getCsrfToken().then((config) => {
        return httpRequest.post(getDeleteMeetingUrl(), qs.stringify(params), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...config.headers,
            },
        });
    });
};

export interface IDeleteMeetingsProps {
    mn: string | number;
    originMn: string | number;
    occurrenceTime: string | number | null;
}

// delete meeting from redux store
export const deleteMeetingsLocally =
    ({ mn, originMn, occurrenceTime }: IDeleteMeetingsProps) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        const getMn = (origin: string | number, display: string | number) => {
            return String(origin) === '0' ? String(display) : String(origin);
        };

        const thisMn = getMn(originMn, mn);

        const meetings = getState().meetings.meetings || [];
        const isNormalOrMainRecurring = occurrenceTime === null || occurrenceTime === '0';

        const deletes = meetings
            .filter((m) => {
                // delete normal meeting, not recurring meeitng
                // delete all recurring meeings with [meetingNumber], maybe many of them

                if (isNormalOrMainRecurring) {
                    // compare by meeting number
                    return thisMn === getMn(m.originalMtgNumber, m.meetingNumber);
                }

                // delete one specific recurring meeting with [meetingNumber and occurrence]
                if (occurrenceTime && occurrenceTime !== '0') {
                    return produceMeetingItemId(String(mn), String(originMn), occurrenceTime) === m.id;
                }

                return false;
            })
            .map((m) => m.id);

        dispatch(deleteMeetings(deletes));
    };

export const getMeetingListThunk = () => (dispatch: AppDispatch, getState: AppGetState) => {
    const {
        common: { userInfo },
    } = getState();

    if (!userInfo?.userId) {
        return Promise.reject('empty user info');
    }

    const config: AxiosRequestConfig = {
        params: {
            startTime: dayjs().startOf('date').unix(),
            uid: userInfo.userId,
            // cv: '5.5.13142.0301'
        },
        headers: {
            'ZM-CAP': getClientCaps(),
        },
    };

    return httpRequest
        .post('/wc/pwa-meeting/list', null, config)
        .then((response) => {
            const { data } = response;
            if (!data) {
                return Promise.reject('');
            }
            const { errorCode, result } = data;
            if (errorCode !== 0) {
                if (errorCode === 201) {
                    dispatch(signoutWhenSeesionExpiredThunk());
                }
                return Promise.reject(data);
            }
            return result.meetings || [];
        })
        .then((meetings: Array<WebMeetingItem>) => {
            const {
                meetings: { calendars },
            } = getState();

            const recuringMap: Record<string, boolean> = {};
            const meetingList = meetings
                .filter((meeting: any) => {
                    return window.PwaConfig?.enableUserPMISetting || !isPMIMeetingSetting(meeting);
                })
                .filter((meeting) => {
                    /**
                     * we have a op flag to control if we could expend recurring meetings.
                     * eg.
                     * 1) if we don't expend, only one item returns
                     * 2)if we expend, multiple items return and each of them should be considered independent.
                     *    [ {meetingNumber: 123}, {meetingNumber: 123}, {meetingNumber: 123} ]
                     *    they share same meeting number, but with different "occurence".
                     *    but at the time i implement this logic, server don't add 'occurence' attr,
                     *    so i want to fallback to case 1) and only take first one and remove the others.
                     * you can think of the if statement below as deduplication.
                     */
                    if (isPeriodicMeeting(meeting) && !isPeriodicMeetingWithNoFixedTime(meeting)) {
                        // recurring meeting can not be PMI meeting. so meeting number is unique
                        // but recurring meeting no fixed time meeting could be PMI meeting
                        if (!meeting.occurrence) {
                            if (!recuringMap[meeting.meetingNumber]) {
                                recuringMap[meeting.meetingNumber] = true;
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                    return true;
                })
                .map(transformZoomMeeting)
                .filter((_) => !!_);

            const calendarList = calendars.filter(
                (calendar) => !isRemoveCalendarAgainstMeetings(meetingList, calendar),
            ); // de-dup calendars
            dispatch(setMeetings(meetingList));
            dispatch(setCalendars(calendarList));
            dispatch(maybeUpdateSelectedMeetingItemIdThunk());
        })
        .catch((e) => {
            throw e;
        });
};

export interface IGetMeetingDetailFromServerProps {
    userId: string;
    mn: string;
    originMn: string;
    occurrenceTime: number | string | null;
    meetingMasterEventId?: string;
}

export interface IGetMeetingDetailFromServerRetrun {
    meetings: Array<WebMeetingItem>;
}

export interface ITransferMeetingThunk {
    userInfo: IUserInfo;
    number: string;
    deviceId: string;
    resource: string;
    name: string;
}

export const getMeetingDetailFromServer = (
    props: IGetMeetingDetailFromServerProps,
): Promise<IGetMeetingDetailFromServerRetrun> => {
    const { userId, mn, originMn, occurrenceTime, meetingMasterEventId } = props;

    if (!userId) {
        return Promise.reject('empty user info');
    }

    const meetingNumber = originMn && String(originMn) !== '0' ? originMn : mn;

    const recuringParams = {};
    if (meetingMasterEventId) {
        Object.assign(recuringParams, {
            meetingMasterEventId,
        });
    }

    const isSingleRecurringMeeting = occurrenceTime && String(occurrenceTime) !== '0';
    if (isSingleRecurringMeeting) {
        // to fetch specific recurring meeting, not all of them
        Object.assign(recuringParams, {
            responseToSingleOccurrence: true,
            occurrence: occurrenceTime,
        });
    }

    const config: AxiosRequestConfig = {
        params: {
            ...recuringParams,
            mn: meetingNumber,
            uid: userId,
        },
        headers: {
            'ZM-CAP': getClientCaps(),
        },
    };

    return httpRequest
        .post<{ result: IGetMeetingDetailFromServerRetrun; errorCode: number; errorMessage: string; status: boolean }>(
            '/wc/pwa-meeting/detail',
            null,
            config,
        )
        .then((response) => {
            // this request always succeeds
            const { data } = response;

            if (!data) {
                return Promise.reject('');
            }

            if (data.status === true) {
                return data.result;
            } else {
                if (201 === data.errorCode) {
                    return Promise.reject(data);
                }

                if (data?.errorMessage) {
                    return Promise.reject(data?.errorMessage);
                }

                return Promise.reject('');
            }
        });
};

// this is called from UI, when you what to fetch a single meeting's detail
export const fetchMeetingDetailThunk = (props: IGetMeetingDetailFromServerProps) => (dispatch: AppDispatch) => {
    return getMeetingDetailFromServer(props)
        .then(({ meetings }) => {
            const meeting = meetings[0];
            const transformedMeeting = transformZoomMeeting(meeting);
            dispatch(updateMeetings([transformedMeeting]));
            return transformedMeeting;
        })
        .catch((error) => {
            if (error?.errorCode === 201) {
                dispatch(signoutWhenSeesionExpiredThunk());
            }
            throw error;
        });
};

export const zpnsAddMeetingThunk = (event: ZnpsMeetingItemData) => (dispatch: AppDispatch, getState: AppGetState) => {
    const { mn, originMn, occurrenceTime } = event;
    const {
        common: { userInfo },
    } = getState();

    if (!userInfo?.userId) {
        return Promise.reject('user info is empty');
    }

    const params = {
        mn,
        originMn,
        occurrenceTime,
        userId: userInfo.userId,
    };

    return getMeetingDetailFromServer(params)
        .then(({ meetings }) => {
            let toAdds: Array<WebMeetingItem> = [];
            meetings
                .filter((m) => {
                    // when you schedule a meeting with zoom addon from you calendar service,
                    // zoom created a zoom meeting before you save you calendar event,
                    // so we will receive a zpns 'add' event, this meeting is {topic: "Google Calendar Meeting (not synced)"}; we need to ignore it.
                    // after you save your calendar, zpns push another 'edit' event;
                    if (isPeriodicMeetingWithNoFixedTime(m)) {
                        // this kind meeting's start time is 0; ignore it
                        // i don't find this kind meeting from zoom's calendar addon/plugin
                        return true;
                    }
                    return dayjs.unix(m.startTime).isAfter(dayjs().startOf('day'));
                })
                .forEach((meeting) => {
                    const isPeriodic = isPeriodicMeeting(meeting);
                    const isPeriodicNoFixedTime = isPeriodicMeetingWithNoFixedTime(meeting);
                    if (isPeriodic && !isPeriodicNoFixedTime) {
                        const allRecurring = normalizeRecurringMeetings(meeting);
                        toAdds = toAdds.concat(allRecurring);
                    } else {
                        toAdds.push(meeting);
                    }
                });

            const meetingItems = toAdds.map(transformZoomMeeting);
            dispatch(updateMeetings(meetingItems));
        })
        .then(() => {
            if (isChatEnabled()) {
                chatAgent.syncChannelMeetings();
            }
        })
        .catch((error) => {
            if (error?.errorCode === 201) {
                dispatch(signoutWhenSeesionExpiredThunk());
            }
            console.error('meeting item update error', error);
        });
};

const normalizeRecurringMeetings = (meeting: WebMeetingItem): Array<WebMeetingItem> => {
    const { occurrenceMeetings } = meeting;

    if (!Array.isArray(occurrenceMeetings)) {
        return [meeting];
    }

    delete meeting.occurrenceMeetings;

    const initalDiffs = {
        startTime: true,
        duration: true,
        occurrence: true,
    };

    const updatedDiffs = {
        ...initalDiffs,
        scheduleOptions: true,
        scheduleOptions2: true,
        scheduleOptions3: true,
        scheduleOptions4: true,
        scheduleOptions5: true,
        scheduleOptions6: true,
        scheduleOptions7: true,
    };

    const result = occurrenceMeetings
        .filter((m) => {
            return 'occurrenceStatus' in m && m.occurrenceStatus !== EOccurrenceStatus.DELETED;
        })
        .map((m) => {
            if ('occurrenceStatus' in m && m.occurrenceStatus === EOccurrenceStatus.INIT) {
                const updates = (Object.keys(initalDiffs) as (keyof typeof initalDiffs)[]).reduce((acc, cur) => {
                    acc[cur] = m[cur];
                    return acc;
                }, {} as Record<keyof typeof initalDiffs, any>);

                return Object.assign({}, meeting, updates);
            }

            // has edited before
            const updates = (Object.keys(updatedDiffs) as (keyof typeof updatedDiffs)[]).reduce((acc, cur) => {
                acc[cur] = m[cur];
                return acc;
            }, {} as Record<keyof typeof updatedDiffs, any>);

            return Object.assign({}, meeting, updates);
        });

    return result;
};

export const zpnsEditMeetingThunk = (event: ZnpsMeetingItemData) => (dispatch: AppDispatch, getState: AppGetState) => {
    const { mn, originMn, occurrenceTime } = event;
    const {
        common: { userInfo },
        meetings: { meetings },
    } = getState();

    if (!userInfo?.userId) {
        return Promise.reject('user info is empty');
    }

    const isSinglePeriodic = occurrenceTime && String(occurrenceTime) !== '0';

    const getMn = (meetingNumber: string | number, originMeetingNumber: string | number) => {
        return String(originMeetingNumber) === '0' ? String(meetingNumber) : String(originMeetingNumber);
    };

    const params = {
        mn,
        originMn,
        occurrenceTime,
        userId: userInfo.userId,
    };

    if (isSinglePeriodic) {
        // try to find meetingMasterEventId when you edit only one of occurences
        const id = produceMeetingItemId(mn, originMn, occurrenceTime);
        const thisMeeting = (meetings || []).find((item) => item.id === id);
        if (thisMeeting?.meetingMasterEventId) {
            Object.assign(params, { meetingMasterEventId: thisMeeting.meetingMasterEventId });
        }
    }

    return getMeetingDetailFromServer(params)
        .then(({ meetings }) => {
            meetings.forEach((meeting) => {
                // - single recuring -> single recuring
                // - no fix time recurring -> no fix time recurring
                // - normal -> normal (1 to 1)
                // - normal -> recuring (1 to n)
                // - recuring -> normal (n to 1)
                // - recurring -> recurring (n to n)
                // - no fix time recurring -> recurring (1 to n)
                // - recurring -> no fix time recurring (n to 1)

                // occurrenceTime === "0"
                // you edit a normal meeting, or
                // you edit all occurrences of a recurring meeting

                const isPeriodic = isPeriodicMeeting(meeting);

                // only one recuring meeting changed
                if (isSinglePeriodic) {
                    // zpns provides 'occurrenceTime'
                    // we just fetch this one item from server,so cann't delete all locally. just update it
                    dispatch(updateMeetings([transformZoomMeeting(meeting)]));

                    return;
                }

                let toEdits: Array<WebMeetingItem> = [];

                const meetingsLocal = getState().meetings.meetings.filter((m) => {
                    return getMn(m.meetingNumber, m.originalMtgNumber) === getMn(mn, originMn);
                });

                if (isPeriodic && Array.isArray(meeting.occurrenceMeetings)) {
                    // all the recurring items changed
                    const allRecurring = normalizeRecurringMeetings(meeting);
                    toEdits = toEdits.concat(allRecurring);
                } else {
                    toEdits.push(meeting);
                }

                const result = toEdits.map(transformZoomMeeting);

                // delete what we have
                dispatch(deleteMeetings(meetingsLocal.map((m) => m.id)));
                // update what server gives
                dispatch(updateMeetings(result));
                dispatch(maybeUpdateSelectedMeetingItemIdThunk());
            });
        })
        .then(() => {
            if (isChatEnabled()) {
                chatAgent.syncChannelMeetings();
            }
        })
        .catch((error) => {
            if (error?.errorCode === 201) {
                dispatch(signoutWhenSeesionExpiredThunk());
            }
            console.error('meeting item update error', error);
        });
};

export const zpnsDeleteMeetingThunk = (event: ZnpsMeetingItemData) => (dispatch: AppDispatch) => {
    const { mn, originMn, occurrenceTime } = event;
    if (isChatEnabled()) {
        chatAgent.deleteChannelMeetings(event);
    }
    dispatch(deleteMeetingsLocally({ mn, originMn, occurrenceTime }));
    dispatch(maybeUpdateSelectedMeetingItemIdThunk());

    console.log('meeting item delete');
    return Promise.resolve(true);
};

export const zpnsNotifyMeetingsChangedThunk = (event: ZnpsMeetingItemData) => (dispatch: AppDispatch) => {
    const { type } = event;

    if ('delete' === type || 'delete_antendee' === type || 'delete_occurrence' === type) {
        return dispatch(zpnsDeleteMeetingThunk(event));
    }

    if ('add' === type || 'add_antendee' === type) {
        return dispatch(zpnsAddMeetingThunk(event));
    }

    if ('edit' === type || 'edit_antendee' === type) {
        return dispatch(zpnsEditMeetingThunk(event));
    }
    return Promise.resolve(true);
};

const calendarEventFields = [
    'topic',
    'conferenceInfo',
    'organizerEmail',
    'allDay',
    'startTime',
    'endTime',
    'duration',
    'timeZone',
].join(',');

// when calendar event changed, we pull all the events, not the specific one.
export const getCalendarListThunk =
    (force = false) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        // Gov env doesn't support Calendar service now (04/24 2022)
        // and it's been deployed to Gov. now (11/15 2024)

        // we need to filter some items
        // ZMPTMeetingMgr.cpp -> IsCalendarEventNeedFiltered
        const {
            common: { userInfo },
            meetings: { isConnectedToCalendar, isNeedReAuthCalendar },
        } = getState();

        if (!userInfo?.userId) {
            return Promise.reject('empty user identity');
        }

        const { userId, accountId = '', haid = '', clusterId = '' } = userInfo;

        if (!isConnectedToCalendar || isNeedReAuthCalendar) {
            if (!force) {
                return Promise.resolve();
            }
        }

        const config = {
            params: {
                clientId: userId,
                startTime: dayjs().startOf('date').toISOString(),
                endTime: dayjs().endOf('date').toISOString(),
                fields: calendarEventFields,
                parseMeetingTypes: [CalendarParseMeetingType.Zoom, CalendarParseMeetingType.OnZoom].join(','),
            },
            headers: {
                'x-zm-aid': accountId, // use (-)dash rather than (_)underscore
                'x-zm-client-cluster-id': clusterId,
                'x-zm-haid': haid,
            },
            withCredentials: true,
            needToken: true,
        };

        const url = getNwsCalendarListUrl();
        return axiosInstance
            .get(url, config)
            .then((response: AxiosResponse) => {
                const { data, code, message } = response.data;
                dispatch(setIsConnectedToCalendar(code === 0 ? true : !isUserNotIntegrateCalendar(code)));
                dispatch(setIsNeedReAuthCalendar(code === 0 ? false : isUserNeedReAuthCalendar(code)));

                if (code !== 0) {
                    // if you delete calendar
                    if (isUserNotIntegrateCalendar(code)) {
                        dispatch(setCalendars([]));
                    }
                    throw Error(message);
                }

                return data;
            })
            .then((data: Array<WebCalendarEvent>) => {
                const {
                    meetings: { meetings },
                } = getState();

                const calendarList = data
                    .map(transformCalendarEvent)
                    .filter((_) => !!_)
                    .filter((calendar: CalendarEvent) => !isRemoveCalendarAgainstMeetings(meetings, calendar));

                // de-dup calendar according to meetings from zoom server
                dispatch(setCalendars(calendarList));
                dispatch(maybeUpdateSelectedMeetingItemIdThunk());
            })
            .catch((error: any) => {
                console.error('calendar list', error.message);
            });
    };

export const refreshListThunk = () => (dispatch: AppDispatch) => {
    dispatch(setLoadingStstus(FetchStatus.loading));
    dispatch(getCalendarListThunk());
    return dispatch(getMeetingListThunk())
        .then(() => {
            dispatch(setLoadingStstus(FetchStatus.succeeded));
        })
        .catch(() => {
            dispatch(setLoadingStstus(FetchStatus.failed));
        });
};

export const zpnsEventHandlerMeetingsThunk = (data: any) => (dispatch: AppDispatch) => {
    // web meeting list changed add/remove/edit
    if (data.category === ZpnsMsgCategory.Meeting) {
        dispatch(zpnsNotifyMeetingsChangedThunk(data.event));
    }

    // calendar events changed, NWS receives notification from your calendar service
    if (data.category === ZpnsMsgCategory.Calendar) {
        dispatch(getCalendarListThunk());
    }

    if (data.category === ZpnsMsgCategory.Zr) {
        const { event } = data;

        // ProfileAmendment's content is encoded by protobuf
        // 1. when zoom room setting is changed
        if (/ProfileAmendment/.test(event)) {
            dispatch(getMeetingListThunk());
            dispatch(getCalendarListThunk());
        }
    }

    if (data.category === ZpnsMsgCategory.UserSetting) {
        const { event } = data;
        // 1. when you add / delete calendar service
        if (event.category === '3RD_INTEGRATION_USERINFO') {
            dispatch(getCalendarListThunk(true));
        }
    }
};
