import { useIsUserBlockedByIM } from '../../Chat';
import { RootState, useAppSelector } from '../../Phone/types';
import { ifCanShowChatBtn, isGroupAddon } from '../redux/contact-utils';
import { isPersonalContactGroup } from '../utils/stringUtils';
import { isDuringMeetingSelector } from '../../Meeting/redux';
import { WebimPresenceType } from '@zoom/pwa-webim';
import { getPresenceTxt } from '../../../services/xmpp/utils';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';
import { isDisableInternalPresence } from '../../../store/common/common-utils';

const selectContactPresence = (jid: string) => (state: RootState) => {
    const {
        contacts: { presences },
    } = state;
    const presence = presences[jid];
    return presence?.presenceType || WebimPresenceType.offline;
};

interface IProps {
    jid: string;
    groupdId: string;
}

export const useContactProperty = ({ jid, groupdId }: IProps) => {
    // get from redux state
    const showWebclient = useAppSelector((state) => state.meeting.showWebclient);
    const isDuringMeeting = useAppSelector(isDuringMeetingSelector);
    const isStartDisabled = useAppSelector((state) => state.meeting.isStartDisabled);
    const presenceType = useAppSelector(selectContactPresence(jid));
    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);

    const presenceText = getPresenceTxt(presenceType);

    // calculate
    const isIMBlocked = useIsUserBlockedByIM({ jid });
    const isAddon = isGroupAddon(groupdId);
    const isPersonalContact = isPersonalContactGroup(groupdId);
    const isJoinOnly = window.PwaConfig.isNoMeetingsLicenseTypeUser;
    const isDisableInternalPresenceOn = isDisableInternalPresence();

    const canWeDisableChat = false;
    const canWeChat = ifCanShowChatBtn(groupdId) && !isPersonalContact && !isJoinOnly && !isAddon;

    const canWeDisableMeet = (showWebclient && !isDuringMeeting) || isStartDisabled || isZCCInMeeting;
    const canWeMeet = !isIMBlocked && !isPersonalContact && !isAddon && !isJoinOnly;

    const canWeShowPresence = !isDisableInternalPresenceOn && !isIMBlocked && !isPersonalContact && !isAddon;

    return {
        canWeShowPresence,
        presenceType,
        presenceText,

        canWeDisableChat,
        canWeChat,

        canWeDisableMeet,
        canWeMeet,

        isDuringMeeting: isDuringMeeting || isZCCInMeeting,
        isIMBlocked,

        isAddon,
    };
};

export type ContactProperty = ReturnType<typeof useContactProperty>;
