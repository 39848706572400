import { useEffect } from 'react';
import { useAppSelector } from '../../../store/store-hooks';
import { isXmppConnectedSelector } from '../../../store/xmpp/xmpp-store';
import { PresenceBadge } from '../../../components/Presence';
import { isDisableInternalPresence } from '../../../store/common/common-utils';
import { PresenceType } from '@zoom/zpns-contact-presence';
import { subscribePresenceFor3Hours } from '../../../services/zpns/presence-subscribe';

interface IUsePresence {
    jid: string;
    needQuery?: boolean;
    isAddon?: boolean;
    byPassOpInternalPresenceCheck?: boolean;
}

interface IReturnUsePresence {
    presenceType: PresenceType;
    PresenceBadge: () => JSX.Element;
}

export default function usePresence({
    jid,
    needQuery = true,
    isAddon = false,
    byPassOpInternalPresenceCheck,
}: IUsePresence): IReturnUsePresence {
    const presences = useAppSelector((state) => state.contacts.presences);
    const isXmppConnected = useAppSelector(isXmppConnectedSelector);
    const isDisableInternalPresenceOn = isDisableInternalPresence();

    const disableShowPresenceBadge = byPassOpInternalPresenceCheck ? false : isDisableInternalPresenceOn;

    useEffect(() => {
        if (jid && isXmppConnected && !isAddon) {
            return;
        }

        if (needQuery && jid) {
            subscribePresenceFor3Hours([jid]);
        }
    }, []);

    if (!jid || !isXmppConnected) {
        return {
            presenceType: PresenceType.offline,
            PresenceBadge: () =>
                PresenceBadge({ presenceType: PresenceType.offline, disableShow: disableShowPresenceBadge }),
        };
    }

    if (isAddon) {
        return {
            presenceType: PresenceType.available,
            PresenceBadge: () =>
                PresenceBadge({ presenceType: PresenceType.available, disableShow: disableShowPresenceBadge }),
        };
    }

    const presence = presences[jid];
    if (!presence) {
        return {
            presenceType: PresenceType.offline,
            PresenceBadge: () =>
                PresenceBadge({ presenceType: PresenceType.offline, disableShow: disableShowPresenceBadge }),
        };
    }

    return {
        presenceType: presence.presenceType as any,
        PresenceBadge: () =>
            PresenceBadge({ presenceType: presence.presenceType, disableShow: disableShowPresenceBadge }),
    };
}
