import { AppDispatch, AppGetState } from '../../../Phone/types';
import {
    setEnableFeedbackTextField,
    setFeedbackCustMessage,
    setPostAttendeeUrl,
    setWebclientEnableFeedback,
} from '../../redux/meeting-store';
import { setModal } from '../../../../store/modal/modal-store';
import { getGPUInfo, getOSVersionInfo, goToPostAttendeeUrl } from '../utils';

interface IFeedbackToWebAction {
    quality: string;
    email: string;
    addComments?: string;
    recaptcha: string;
}

const os = getOSVersionInfo();

export const sendFeedbackToWebAction =
    ({ quality, email, addComments, recaptcha }: IFeedbackToWebAction) =>
    (_dispatch: AppDispatch, getState: AppGetState) => {
        const {
            modal: {
                modals: { feedback },
            },
            meeting: { postAttendeeUrl },
        } = getState();
        const url = `/wc/feedback?meetingNumber=${feedback.meetingNumber}`;
        const gpuInfo = getGPUInfo();

        const getContent = () => {
            const searchParams = {
                quality,
                email,
                addComments,
                recaptcha,
                content: '',
            };
            const content = [
                ['Meeting Number', feedback?.meetingNumber],
                ['Device Info', navigator.userAgent],
                ['os', `${os.os}|${os.osVersion}`],
                ['deviceType', feedback?.deviceType],
                ['browser', `${os.browser.name}|${os.browser.version}`],
                ['MeetingID', feedback?.meetingId || 'not provided'],
                ['gpuInfo', gpuInfo],
                ['confId', feedback?.confId || 'not provided'],
                ['trackingId', feedback?.trackingId || 'not provided'],
                ['myNodeID', feedback?.nodeId || 'not provided'],
                ['Meeting Details', feedback?.meetingDetails || 'not provided'],
                ['cores', navigator.hardwareConcurrency],
            ].reduce((acc, cur) => {
                return `${acc}\r\n[${cur.join(':')}]`;
            }, addComments);

            console.log('content', content);
            searchParams.content = content;

            return new URLSearchParams(searchParams);
        };

        fetch(url, {
            method: 'POST',
            body: getContent(),
            headers: {
                'ZOOM-USER-AGENT': 'zoom.webclient',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            // keepAlive will guarantee the request succeed even the page unloaded, axios is planning to support it
            keepalive: true,
        });
        goToPostAttendeeUrl(postAttendeeUrl);
    };

export const feedbackDialogThunk = (feedbackData: any) => (dispatch: AppDispatch) => {
    if (!feedbackData) {
        return;
    }
    const webclientEnableFeedback = +feedbackData.feedback[0];
    const enableFeedbackTextField = +feedbackData.feedback[1];
    const meetingNumber = feedbackData.meetingNumber;
    const meetingId = feedbackData.meetingId;
    const nodeId = feedbackData.nodeId;
    const postAttendeeUrl = feedbackData.postAttendeeUrl;
    const meetingDetails = feedbackData.meetingDetails;
    const deviceType = feedbackData.deviceType;
    const confId = feedbackData.confId;
    const trackingId = feedbackData.trackingId;

    dispatch(setWebclientEnableFeedback(webclientEnableFeedback));
    dispatch(setEnableFeedbackTextField(!!enableFeedbackTextField));
    dispatch(setPostAttendeeUrl(postAttendeeUrl));

    const feedbackCustMessage = feedbackData.feedbackCustMessage;
    if (feedbackCustMessage) {
        dispatch(setFeedbackCustMessage(feedbackCustMessage));
    }

    dispatch(
        setModal({
            name: 'feedback',
            data: { show: true, meetingNumber, meetingId, nodeId, meetingDetails, deviceType, confId, trackingId },
        }),
    );
};
