import { throttle } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const CalendarSidebarForHomePlaceholder = ({ cacheID, style = {} }: { cacheID: string, style?: React.CSSProperties }) => {
    const ref = useRef(null);
    const location = useLocation();
    const updateSidebarPosition = throttle(() => {
        const cachedCalendarSidebar = document.getElementById(cacheID);
        if (!cachedCalendarSidebar) {
            return null;
        }
        const rect = ref.current.getBoundingClientRect();
        cachedCalendarSidebar.style.top = rect.y + 'px';
        cachedCalendarSidebar.style.left = rect.x + 'px';
        cachedCalendarSidebar.style.width = rect.width + 'px';
        cachedCalendarSidebar.style.height = rect.height + 'px';
        return cachedCalendarSidebar;
    }, 30);

    useEffect(() => {
        updateSidebarPosition();
    }, [location])

    useEffect(() => {
        const cachedCalendarSidebar = updateSidebarPosition();

        // for initial style
        setTimeout(() => {
            updateSidebarPosition();
            cachedCalendarSidebar.style.display = 'block';
        }, 100);

        // for resize
        window.addEventListener('resize', updateSidebarPosition);

        return () => {
            cachedCalendarSidebar.style.display = 'none';
            window.removeEventListener('resize', updateSidebarPosition);
        }
        
    }, [])

    return <div id='CalendarSidebarForHomePlaceholder' ref={ref} style={{ width: 328, height: 460, ...style }} />
}

export default CalendarSidebarForHomePlaceholder;