import React, { forwardRef } from 'react';
import { isPersonalContactGroup } from '../../../../utils/stringUtils';
import styles from '../operation.module.scss';
import classNames from 'classnames';
import { OperationMenuItemProps } from '../types';
import { getUserIdFromJid } from '../../../../../../utils';
import { showModal } from '../../../../../../store/modal/modal-helper';
import { Delete_Text, Edit_Text } from '../../../../../Meetings/LanguageResource';
import { useAppSelector } from '../../../../../Phone/types';
import { getContactDisplayName, selectContactByJid } from '../../../../redux';

export default forwardRef<HTMLButtonElement, OperationMenuItemProps>(function PersonalContactMenuItems(
    { groupId, jid, closeMenu },
    ref,
) {
    const isFromPersonalContactGroup = isPersonalContactGroup(groupId);

    const displayName = useAppSelector((state) => {
        if (!jid) {
            return '';
        }
        const contact = selectContactByJid(state, jid);

        if (!contact) {
            return '';
        }

        return getContactDisplayName(contact);
    });

    if (!isFromPersonalContactGroup) {
        return null;
    }

    return (
        <>
            <button
                ref={ref}
                className={classNames(styles.menuItem)}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    closeMenu();
                    showModal('personalContact', {
                        type: 'edit',
                        info: {
                            groupId,
                            jid,
                        },
                    });
                }}
            >
                {Edit_Text}
            </button>

            <button
                ref={ref}
                className={classNames(styles.menuItem)}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    closeMenu();
                    showModal('deletePersonalContact', {
                        userId: getUserIdFromJid(jid),
                        userName: displayName,
                        jid,
                        groupId,
                    });
                }}
            >
                {Delete_Text}
            </button>
        </>
    );
});
