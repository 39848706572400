export interface AppVariable {
    dataType: string;
    defaultValue: any;
    id: string;
    name: string;
    type: string;
}

export interface EngagementVariable {
    id: string;
    value: any;
    name: string;
}

type VariableId = string;

export default class AppVariables {
    private apps: Map<string, Map<VariableId, AppVariable>>;

    private engagements: Map<string, Map<VariableId, EngagementVariable>>;

    constructor() {
        this.apps = new Map();
        this.engagements = new Map();
    }

    updateAppVariables(props: { applicationId: string; variables: Array<AppVariable> }) {
        const { applicationId, variables = [] } = props;

        if (!this.apps.has(applicationId)) {
            this.apps.set(applicationId, new Map());
        }

        const currentVariablesMap = this.apps.get(applicationId);

        variables.forEach((variable) => {
            if (!currentVariablesMap.has(variable.id)) {
                currentVariablesMap.set(variable.id, Object.assign({}, variable));
            } else {
                currentVariablesMap.set(variable.id, Object.assign(currentVariablesMap.get(variable.id), variable));
            }
        });
    }

    getAppVariablesList(appId: string) {
        const variablesMap = this.apps.get(appId);
        if (variablesMap) {
            return Array.from(variablesMap.values());
        } else {
            return [];
        }
    }

    updateEngagementVariables(props: {
        applicationId: string;
        engagementId: string;
        variables: Array<EngagementVariable>;
    }) {
        const { applicationId, engagementId, variables } = props;

        const appVariables = this.apps.get(applicationId);

        let engagementVariablesMap = this.engagements.get(engagementId);
        if (!engagementVariablesMap) {
            this.engagements.set(engagementId, new Map());
            engagementVariablesMap = this.engagements.get(engagementId);
        }

        const updates: Array<EngagementVariable> = [];

        variables.forEach((variable) => {
            const name = appVariables?.get(variable.id)?.name;
            const currentVariables = engagementVariablesMap.get(variable.id);
            if (!currentVariables || currentVariables.value !== variable.value) {
                const newV = Object.assign(currentVariables || { name }, variable);
                engagementVariablesMap.set(variable.id, newV);
                updates.push(newV);
                return;
            }
        });

        return updates;
    }

    getEngagementVariablesList(engagementId: string) {
        const engagementVariablesMap = this.engagements.get(engagementId);
        if (engagementVariablesMap) {
            return Array.from(engagementVariablesMap.values());
        } else {
            return [];
        }
    }
}
