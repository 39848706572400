import React from 'react';
import classnames from 'classnames';
import { usePhoneModule } from '../../../asyncModulesStore';
import { RoutePath } from '../../../routes/routePath';
import { useAppDispatch } from '../../../store';
import { useAppSelector } from '../../../store/store-hooks';
import { useLocation } from 'react-router-dom';
import { useFormatPhoneNumber } from '../hooks/usePhoneNumber';
import { pushRouteAndMinimizeMeeting } from '../../../routes';
import { IContact } from '../types';
import { getContactDisplayName } from '../redux';
interface IContentProps {
    title: string;
    content: string | Array<string>;
    region?: string;
    isNumber?: boolean;
    isIMBlocked?: boolean;
    contact?: IContact;
}

const Content = ({ str = '', region = '' }) => {
    const number = useFormatPhoneNumber(str, region);

    return <>{number}</>;
};

export const TextItem = ({ title, content, region = '', isNumber = false }: IContentProps) => {
    let singleText = !Array.isArray(content) ? content : '';
    const isOne = Array.isArray(content) && content.length === 1;
    if (isOne) singleText = content[0];
    const isMore = Array.isArray(content) && content.length > 1;
    const getContent = (item: string, region: string) => (isNumber ? <Content str={item} region={region} /> : item);

    const contentView = isMore ? (
        <div className="contact-detail__info-wrapper">
            {(content as Array<string>).map((item: string) => {
                return (
                    <span key={item} className="contact-detail__info-right" title={item}>
                        {getContent(item, region)}
                    </span>
                );
            })}
        </div>
    ) : (
        <span className="contact-detail__info-right" title={singleText}>
            {getContent(singleText, region)}
        </span>
    );

    const infoCls = classnames({
        'contact-detail__info': true,
        'contact-detail__info-start': isMore,
    });

    return (
        <div className={infoCls} tabIndex={0}>
            <span className="contact-detail__info-left">{title}</span>
            {contentView}
        </div>
    );
};

export const MailItem = ({ title, content }: IContentProps) => {
    return (
        <div className="contact-detail__info" tabIndex={0}>
            <span className="contact-detail__info-left">{title}</span>
            <a
                className="contact-detail__info-right contact-detail__info-href"
                title={content as string}
                href={`mailto:${content}`}
            >
                {content}
            </a>
        </div>
    );
};

/**
 * @param region : country code , such as 'CN', 'US'
 */
export const PhoneItem = ({ title, content, region = '', contact }: IContentProps) => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { sendCall, showSMSDialPanelWhenContactCallThunk } = usePhoneModule();

    const sendPhoneCall = (phoneNumber: string) => {
        console.log('sendPhoneCall ==>', phoneNumber);
        if (!sendCall) {
            return;
        }
        if (location.pathname !== RoutePath.Phone) {
            pushRouteAndMinimizeMeeting(RoutePath.Phone);
        }

        // if phone active tab is SMS before jump to phone, need  display  dial panel in sms
        dispatch(showSMSDialPanelWhenContactCallThunk());
        const format = phoneNumber.replace(/\s/g, '');
        dispatch(sendCall({ number: format, name: contact ? getContactDisplayName(contact) : '' }));
    };

    let singleText = !Array.isArray(content) ? content : '';
    const isOne = Array.isArray(content) && content.length === 1;
    if (isOne) singleText = content[0];

    const isMore = Array.isArray(content) && content.length > 1;

    const contentView = isMore ? (
        <div className="contact-detail__info-wrapper">
            {(content as Array<string>).map((item: string) => {
                return (
                    <span
                        key={item}
                        className="contact-detail__info-right contact-detail__info-href"
                        title={item}
                        onClick={() => sendPhoneCall(item)}
                    >
                        <Content str={item} region={region} />
                    </span>
                );
            })}
        </div>
    ) : (
        <span
            className="contact-detail__info-right contact-detail__info-href"
            title={singleText}
            onClick={() => sendPhoneCall(singleText)}
        >
            <Content str={singleText} region={region} />
        </span>
    );

    const infoCls = classnames({
        'contact-detail__info': true,
        'contact-detail__info-start': isMore,
    });

    return (
        <div className={infoCls} tabIndex={0}>
            <span className="contact-detail__info-left">{title}</span>
            {contentView}
        </div>
    );
};

export const PhoneItemView = (props: IContentProps) => {
    const isPhoneModuleLoaded = useAppSelector((_) => _.common.isPhoneModuleLoaded);
    if (isPhoneModuleLoaded && !props.isIMBlocked) {
        return <PhoneItem {...props} />;
    } else {
        return <TextItem {...props} isNumber={true} />;
    }
};
