import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export const selectZoomApps = (state: RootState) => state.zoomApps;

export const selectZoomAppsEntities = createSelector([selectZoomApps], (apps) => {
    return apps.runningApps.entities;
});

export const selectRunningAppById = (runningAppId: string) =>
    createSelector([selectZoomAppsEntities], (entities) => {
        return entities[runningAppId];
    });
