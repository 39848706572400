export * from './op_client_location';
import serviceContainer from '../../services/container';
import { RootState } from '../../store';
import FeatureOptionsManager from './FeatureOptionsManager';

const featureOptionManager = new FeatureOptionsManager();

export default featureOptionManager;

// TODO this is duplicated with '../index.js'
// i think this file a good place for isChatEnabled, isWhiteBoardEnabled, isZCCEnabled
// but isTeslaMode is defined '../index.js', i don't want to import it here. because it should not be there
const isTeslaMode = () => {
    return /TESLA/i.test(navigator.userAgent);
};

export const isChatEnabled = () => {
    return !isTeslaMode() && window.chatInitConfig?.isChatEnabled;
};

export const isDocsEnabled = () => {
    return !isTeslaMode() && featureOptionManager.isDocsDeployed() && window.PwaConfig.enableDocs;
};

export const isWhiteBoardEnabled = () => {
    return !isTeslaMode() && window.PwaConfig?.isShowWhiteboard;
};

export const isZCCEnabled = () => {
    return !isTeslaMode() && window.PwaConfig.isEnablePWAZCCIntegration;
};

export const isCalendarEnabled = () => {
    return !isTeslaMode() && featureOptionManager.isSupportCalendar();
};

export const hideShceduleMeetingAction = (state?: RootState) => {
    const reduxStore = serviceContainer.getReduxStore();
    const {
        common: { userInfo },
    } = state || reduxStore.getState();

    return userInfo?.keyValueOptions?.hide_meeting_schedule_edit_delete_actions === true;
};
