import React, { useState, useRef } from 'react';
import { IconEllipsisHorizontalSmall } from '@zoom/icons-react';
import { OverlayTrigger } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import OperationMemberMenu from './OperationMemberMenu';
import { useClickOutside } from '../../../.././../hooks/useClickOutside';
import { More_Text } from '../../../../../resource';
import { interceptPropagation, isZoomRoomEmail } from '../../../../../utils/index';
import { DOM_KEY_CODE } from '../../../../../utils/constants';
import { CONTACT_MEMBER_OPTIONS_FROM } from '../../../types';
import { RootState, useAppSelector } from '../../../../../store';
import { featureOptionManager } from '../../../../../app-init';
import { isGroupZoomRoom } from '../../../redux/contact-utils';
import { isExternalGroup } from '../../../utils/stringUtils';
import { showFarEndCameraControlGroupSelector } from '../../../redux/contact-selector';

// Add to Auto Answer Group, exclude zoom rooms user and pending contact
// contact search data  will not contain groupId, just get email from web api(/im/contact/queryForWeb?from=pwa)
const showFarEndCameraControlGroupActionSelector = (
    from: CONTACT_MEMBER_OPTIONS_FROM,
    groupId: string,
    email: string,
) => {
    const isFromPendingContact = from === CONTACT_MEMBER_OPTIONS_FROM.PENDIND_CONTACT;
    const flag =
        !isFromPendingContact && ((groupId && !isGroupZoomRoom(groupId)) || (email && !isZoomRoomEmail(email)));

    return (state: RootState) => showFarEndCameraControlGroupSelector(state) && flag;
};

// delete contact, just external group contact or pending contact display
const getShowDeleteContact = (from: CONTACT_MEMBER_OPTIONS_FROM, groupId: string) => {
    const isFromPendingContact = from === CONTACT_MEMBER_OPTIONS_FROM.PENDIND_CONTACT;
    return (
        (isExternalGroup(groupId) &&
            featureOptionManager.isInviteContactOn() &&
            from === CONTACT_MEMBER_OPTIONS_FROM.CONTACT) ||
        isFromPendingContact
    );
};

interface MemberOperationProps {
    jid: string;
    groupId: string;
    name: string;
    email: string;
    showActions: (keep: boolean) => void;

    from: CONTACT_MEMBER_OPTIONS_FROM;
}

const ContactOperationButton = (props: MemberOperationProps) => {
    const [show, setShow] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const ariaId = 'contact-memmber-operation-menu';
    const { showActions, from, groupId, email } = props;

    const showFarEndCameraControlGroup = useAppSelector(
        showFarEndCameraControlGroupActionSelector(from, groupId, email),
    );
    const showDeleteContact = getShowDeleteContact(from, groupId);

    // console.log('ContactOperationButton ==>', showFarEndCameraControlGroup, showDeleteContact);

    const onClick = () => {
        setShow(!show);
        showActions(!show);
    };

    const closePopup = () => {
        setShow(false);
        showActions(false);
    };

    const onToggle = (nextShow: boolean) => {
        if (!nextShow) {
            showActions(false);
        }
    };

    const rednerPopup = (renderProps: OverlayInjectedProps) => {
        return (
            <OperationMemberMenu
                close={closePopup}
                ariaId={ariaId}
                {...renderProps}
                {...props}
                options={{
                    showFarEndCameraControlGroup,
                    showDeleteContact,
                }}
            />
        );
    };

    const popperConfig = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    };

    const menuRef = useClickOutside({
        callback: (flag: boolean) => {
            if (flag) {
                setShow(false);
            }
        },
    });

    // if menu opened, intercept click except ESC and SPACE key.
    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (!show) return;

        if (e.keyCode === DOM_KEY_CODE.ESC) {
            buttonRef.current?.focus();
            setShow(false);

            return;
        }

        if (e.keyCode === DOM_KEY_CODE.SPACE || e.keyCode === DOM_KEY_CODE.TAB) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div ref={menuRef} onKeyDown={onKeyDown} role="presentation">
            <OverlayTrigger
                overlay={rednerPopup}
                placement="bottom-start"
                trigger={['click']}
                show={show}
                rootClose={true}
                popperConfig={popperConfig}
                onToggle={onToggle}
            >
                <button
                    ref={buttonRef}
                    className={'contact__action-btns--btn more-actions'}
                    title={More_Text}
                    aria-label={More_Text}
                    onClick={(e) => interceptPropagation(e, onClick)}
                >
                    <IconEllipsisHorizontalSmall aria-hidden={true} />
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default ContactOperationButton;
