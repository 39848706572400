import { getDomainFromJid } from '../../../utils';
import { AppDispatch, AppGetState } from '../../Phone/types';
import {
    IPersonalContactInfo,
    createPersonalContact,
    deletePersonalContact,
    editPersonalContact,
} from '../PersonalContactDialog/services/apis';
import { deleteGroupContacts, setThisGroupContacts, updateContacts, updateGroupTotalCount } from './contact-store';
import { ICON_TYPE, toast } from 'Toast';
import { Create_P_Contact_Ok } from '../../../resource';
import { PwaErrors } from '../../../services/error/pwa-error';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import { isNumber } from 'lodash-es';
import { createContactFromUcsUpdateResponse } from './contact-utils';
import { IContact } from '../types';
import { tryResetSelectedContactWhenDelete } from './contact-thunk';

const composeJidFromUserIdByAppendingDomain = (targetUserId: string, currentUserJid: string) => {
    const domain = getDomainFromJid(currentUserJid);
    if (!domain) {
        return '';
    }
    return `${targetUserId}@${domain}`;
};

export const createPersonalContactThunk =
    (pContact: IPersonalContactInfo) => (dispatch: AppDispatch, getState: AppGetState) => {
        return createPersonalContact(pContact)
            .then((res) => {
                const {
                    common: { userInfo },
                    contacts: { groups },
                } = getState();

                const { personalContact } = res;
                const { groupId, id } = personalContact;

                /*****
                 *
                 * update contact info
                 *
                 * */
                const userJid = composeJidFromUserIdByAppendingDomain(id, userInfo?.jid);
                if (!userJid) {
                    return null;
                }

                const contact = createContactFromUcsUpdateResponse(personalContact);
                contact.jid = userJid;

                dispatch(updateContacts({ contacts: [contact] }));

                /*****
                 *
                 * update group info
                 *
                 * */

                /**
                 * if wer are creating the first member of a new group, we don't create the group here,
                 * rather, we just update contact, and waiting for zpns message to fetch group and member into there
                 */

                const loaclGroup = groups[groupId];

                if (loaclGroup) {
                    dispatch(setThisGroupContacts({ groupId, contacts: [contact] }));

                    const count = {
                        id: groupId,
                    };

                    if (isNumber(loaclGroup.total)) {
                        Object.assign(count, { total: loaclGroup.total + 1 });
                    }

                    if (isNumber(loaclGroup.totalCountFromApi)) {
                        Object.assign(count, { totalCountFromApi: loaclGroup.totalCountFromApi + 1 });
                    }

                    dispatch(updateGroupTotalCount(count));
                }

                return contact;
            })
            .then((contact) => {
                toast({
                    type: ICON_TYPE.SUCCESS,
                    desc: Create_P_Contact_Ok(contact.displayName),
                });
            })
            .catch((error) => {
                if (error.code === PwaErrors.WebSessionExpired.code) {
                    // relogin
                    dispatch(signoutWhenSeesionExpiredThunk());
                }
                toast({
                    type: ICON_TYPE.FAILURE,
                    desc: error.message,
                });
            });
    };

export const deletePersonalContactThunk =
    (pContact: { userId: string; groupId: string; jid: string }) => (dispatch: AppDispatch, getState: AppGetState) => {
        const { jid, groupId } = pContact;
        return deletePersonalContact(pContact).then(() => {
            const {
                contacts: { groups, groupToContactTable },
            } = getState();

            const groupMembers = groupToContactTable[groupId] || [];
            const loaclGroup = groups[groupId];

            if (!loaclGroup || !groupMembers.includes(jid)) {
                return;
            }

            const count = {
                id: groupId,
            };

            if (isNumber(loaclGroup.total)) {
                Object.assign(count, { total: Math.max(loaclGroup.total - 1, 0) });
            }

            if (isNumber(loaclGroup.totalCountFromApi)) {
                Object.assign(count, { totalCountFromApi: Math.max(loaclGroup.totalCountFromApi - 1, 0) });
            }

            dispatch(updateGroupTotalCount(count));

            dispatch(deleteGroupContacts({ groupId, contacts: [jid] }));

            // if we are show it's detail, we need to hide contact detail UI
            dispatch(tryResetSelectedContactWhenDelete([jid]));
        });
    };

const updateEditedContact = (currentContact: IContact, changes: IPersonalContactInfo): IContact => {
    const newContact = Object.assign({}, currentContact, {
        displayName: changes.name,
        company: changes.company,
        email: changes.email,
    });

    const newPersonal = [
        ['home', 'homePhoneNum'],
        ['office', 'officePhoneNum'],
        ['fax', 'fax'],
        ['mobile', 'mobile'],
        ['phone', 'phone'],
    ].reduce((acc, [left, right]) => {
        (acc as any)[left] = Object.assign(
            {
                label: left,
                number: '',
                needSyncSearch: true,
                verified: true,
            },
            (acc as any)[left],
            {
                number: (changes as any)[right],
            },
        );

        return acc;
    }, Object.assign({}, currentContact.personal));

    newContact.personal = newPersonal;

    return newContact;
};

export const editPersonalContactThunk =
    (
        meta: {
            userId: string;
            jid: string;
        },
        contact: IPersonalContactInfo,
    ) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        const { jid, userId } = meta;
        const {
            contacts: { contacts },
        } = getState();

        const thisContact = contacts[jid];

        if (!thisContact) {
            return null;
        }

        return editPersonalContact({ ...contact, userId }).then(() => {
            const newContact = updateEditedContact(thisContact, contact);
            dispatch(
                updateContacts({
                    contacts: [newContact],
                }),
            );
        });
    };
