import type { IEventBus } from '../../events/eventBus';
import type MeetingAgent from '../../features/Meeting/MeetingAgent';
import type { WebPhone } from '../../features/Phone/WebPhone';
import type ZAppController from '../../features/ZApps/ZAppController/ZAppController';
import type { AppStore } from '../../store';
import type XmppAgent from '../xmpp/XmppAgent';
import type { ZpnsConnection, PresenceManager, ZpnsPresenceFactory } from '@zoom/zpns-contact-presence';
export default class InverseContainer {
    private meetingAgent: MeetingAgent;
    private xmppAgent: XmppAgent;
    private reduxStore: AppStore;
    private webPhone: WebPhone;
    private eventBus: IEventBus;
    private zAppController: ZAppController;
    private zpns: ZpnsConnection;
    private presenceManager: PresenceManager;
    private zpnsPresenceFactory: ZpnsPresenceFactory;

    // meeting agent
    setMeetingAgent(meetingAgent: MeetingAgent) {
        this.meetingAgent = meetingAgent;
    }
    getMeetingAgent() {
        return this.meetingAgent;
    }

    // xmpp agent
    setXmppAgent(xmppAgent: XmppAgent) {
        this.xmppAgent = xmppAgent;
    }
    getXmppAgent() {
        return this.xmppAgent;
    }

    // redux store
    setReduxStore(store: AppStore) {
        this.reduxStore = store;
    }
    getReduxStore() {
        return this.reduxStore;
    }

    // webPhone
    setWebPhone(phone: WebPhone) {
        this.webPhone = phone;
    }
    getWebPhone() {
        return this.webPhone;
    }

    setEventBus(bus: IEventBus) {
        this.eventBus = bus;
    }
    getEventBus() {
        return this.eventBus;
    }

    setZAppController(controller: ZAppController) {
        this.zAppController = controller;
    }
    getZAppController() {
        return this.zAppController;
    }

    setZpns(zpns: ZpnsConnection) {
        this.zpns = zpns;
    }

    getZpns() {
        return this.zpns;
    }

    setPresenceManager(manager: PresenceManager) {
        this.presenceManager = manager;
    }

    getPresenceManager() {
        return this.presenceManager;
    }

    setZpnsPresenceFactory(factory: ZpnsPresenceFactory) {
        this.zpnsPresenceFactory = factory;
    }
    getZpnsPresenceFactory() {
        return this.zpnsPresenceFactory;
    }
}
