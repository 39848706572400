import { IconPhoneSmallFill } from '@zoom/icons-react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { usePhoneModule, type ZoomPhoneModule } from '../../../asyncModulesStore';
import Button from '../../../components/Button/Button';
import { RoutePath } from '../../../routes/routePath';
import { useAppDispatch } from '../../../store/store-hooks';
import { Call_Text } from '../../Meetings/LanguageResource';
import {
    getContactDisplayName,
    getPersonalNumberForCallout,
    getPhoneNumberOfThisContact,
} from '../redux/contact-utils';
import { IContact } from '../types';
import { interceptPropagation } from '../../../utils/index';
import { pushRouteAndMinimizeMeeting } from '../../../routes';

interface IUserPhoneCallProps {
    contact: IContact;
    isAddon: boolean;
    isIMBlocked: boolean;
}

const usePhoneCall = ({ contact, isAddon, isIMBlocked }: IUserPhoneCallProps) => {
    const phoneModule = usePhoneModule();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const phoneNumber = getPhoneNumberOfThisContact(contact);
    const personalNumber = getPersonalNumberForCallout(contact);
    const displayName = getContactDisplayName(contact);

    const number = phoneNumber || personalNumber;

    if (isEmpty(phoneModule) || !number || isAddon || isIMBlocked) {
        return null;
    }

    const { sendCall } = phoneModule as ZoomPhoneModule;

    const sendPhoneCall = () => {
        if (
            !matchPath(location.pathname, {
                path: RoutePath.Phone,
                exact: true,
            })
        ) {
            pushRouteAndMinimizeMeeting(RoutePath.Phone);
        }
        dispatch(sendCall({ number: number, name: displayName }));
    };

    return {
        phoneNumber: number,
        sendPhoneCall,
    };
};

export interface IPhoneCallProps extends IUserPhoneCallProps {
    className?: string;
    title?: string;
}

export const PhoneCallIcon = ({ contact, isAddon, isIMBlocked, title, ...restProps }: IPhoneCallProps) => {
    const phone = usePhoneCall({ contact, isAddon, isIMBlocked });
    if (!phone) {
        return null;
    }

    return (
        <button
            {...restProps}
            onClick={(e) => interceptPropagation(e, phone.sendPhoneCall)}
            title={title}
            aria-label={title}
        >
            <IconPhoneSmallFill aria-hidden={true} />
        </button>
    );
};

export const PhoneCallButton = (props: IPhoneCallProps) => {
    const phone = usePhoneCall(props);
    if (!phone) {
        return null;
    }

    return (
        <Button
            size={Button.Size32}
            type={Button.Dark}
            onClick={phone.sendPhoneCall}
            LeftIcon={<IconPhoneSmallFill aria-hidden={true} />}
        >
            {Call_Text}
        </Button>
    );
};
