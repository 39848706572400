import React, { forwardRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store-hooks';
import { CONTACT_MEMBER_OPTIONS_FROM } from '../../../../types';
import { isGroupZoomRoom, showFarEndCameraControlGroupSelector } from '../../../../redux';
import { isZoomRoomEmail } from '../../../../../../utils';
import { RootState } from '../../../../../../store';
import { cameraControlGroupHelper } from '../../../../utils/CameraControlGroupHelper';
import { ADD_CAMERA_CONTROL_GROUP, REMOVE_CAMERA_CONTROL_GROUP } from '../../../../../../resource';
import { setModal } from '../../../../../../store/modal/modal-store';
import styles from '../operation.module.scss';
import { OperationMenuItemProps } from '../types';
import { isPersonalContactGroup } from '../../../../utils/stringUtils';

// Add to Auto Answer Group, exclude zoom rooms user and pending contact
// contact search data  will not contain groupId, just get email from web api(/im/contact/queryForWeb?from=pwa)
const showFarEndCameraControlGroupActionSelector = (
    from: CONTACT_MEMBER_OPTIONS_FROM,
    groupId: string,
    email: string,
) => {
    const isFromPendingContact = from === CONTACT_MEMBER_OPTIONS_FROM.PENDIND_CONTACT;
    const flag =
        !isFromPendingContact &&
        ((groupId && !isGroupZoomRoom(groupId)) || (email && !isZoomRoomEmail(email))) &&
        !isPersonalContactGroup(groupId);

    return (state: RootState) => showFarEndCameraControlGroupSelector(state) && flag;
};

export default forwardRef<HTMLButtonElement, OperationMenuItemProps>(function FarEndCameraControlMenuItem(
    { groupId, email, jid, from, name, closeMenu },
    ref,
) {
    const dispatch = useAppDispatch();
    const showFarEndCameraControlGroup = useAppSelector(
        showFarEndCameraControlGroupActionSelector(from, groupId, email),
    );

    if (!showFarEndCameraControlGroup) {
        return null;
    }

    if (cameraControlGroupHelper.hasExisted(jid)) {
        return (
            <button
                ref={ref}
                className={styles.menuItem}
                onClick={() => {
                    closeMenu();
                    cameraControlGroupHelper.removeCameraControlGroup(jid);
                }}
            >
                {REMOVE_CAMERA_CONTROL_GROUP}
            </button>
        );
    } else {
        return (
            <button
                ref={ref}
                className={styles.menuItem}
                onClick={() => {
                    closeMenu();

                    dispatch(
                        setModal({
                            name: 'addToCameraControlGroup',
                            data: { name, jid },
                        }),
                    );
                }}
            >
                {ADD_CAMERA_CONTROL_GROUP}
            </button>
        );
    }
});
