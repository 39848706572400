import React, { useMemo } from 'react';
import {
    CONTACTS_BUTTON,
    Calendar_Text,
    Chat_Text,
    ContactCenter_Text,
    Docs_Text,
    HOME_BUTTON,
    MEETINGS_TEXT,
    Phone_Text,
    Whiteboards_Text,
} from '../resource';
import { headerTabType } from '../utils/constants';
import {
    IconCalendar,
    IconCalendarFill,
    IconContactCenter,
    IconContactCenterFill,
    IconHomeSmall,
    IconHomeSmallFill,
    IconPhoneSmall,
    IconPhoneSmallFill,
    IconProfileContact,
    IconProfileContactFill,
    IconTeamChat,
    IconTeamChatFill,
    IconVideoOn,
    IconVideoOnFill,
    IconWhiteboardFillSmall,
    IconWhiteboardSmall,
} from '@zoom/icons-react';
import { ReactComponent as DogFood } from '../assets/images/dog-food-icon.svg';

import { RoutePath } from '../routes';
import { isCalendarEnabled, isChatEnabled, isDocsEnabled, isWhiteBoardEnabled } from '../utils/featureOptions';
import ChatUnReadBadge from '../features/Chat/UnReadBadge/index';
import { useAppDispatch, useAppSelector } from '../store';
import { canWeLoadZoomPhone, isContactCenterEnabledSelector } from '../store/appModules/app-modules-selector';
import { updateHeaderTabsPinned } from '../features/Settings/redux/setttings-thunk';
import { DocsNormal, DocsSelected } from '../features/Docs/icons/DocsIcon';
import { userInfoSelector } from '../store/common/common-selector';
import { isDogFoodEnv } from '../store/common/common-utils';
import PhoneTabBanner from '../features/SignedIn/Header/HeaederTabBanners/PhoneTabBanners';

const useTabList = () => {
    const dispatch = useAppDispatch();
    const userSettingHeaderTabs = useAppSelector((state) => state.settings.userSettingHeaderTabs);

    const showPhoneIcon = useAppSelector(canWeLoadZoomPhone);

    const isContactCenterEnabled = useAppSelector(isContactCenterEnabledSelector);

    const clusterId = useAppSelector((state) => {
        return userInfoSelector(state)?.clusterId;
    });

    const isDogfood = isDogFoodEnv(clusterId);

    const tabs = [
        {
            show: true,
            text: HOME_BUTTON,
            tag: headerTabType.home,
            icon: !isDogfood ? [IconHomeSmall, IconHomeSmallFill] : [DogFood, DogFood],
            banner: null,
            routePath: RoutePath.Home,
            pinned: true,

        },
        {
            show: isCalendarEnabled(),
            text: Calendar_Text,
            tag: headerTabType.calendar,
            icon: [IconCalendar, IconCalendarFill],
            routePath: RoutePath.Calendar,
            pinned: true,
        },
        {
            show: isDocsEnabled(),
            text: Docs_Text,
            tag: headerTabType.docs,
            icon: [DocsNormal, DocsSelected],
            routePath: RoutePath.Docs,
            pinned: true,
        },
        {
            show: isContactCenterEnabled,
            text: ContactCenter_Text,
            tag: headerTabType.contactCenter,
            icon: [IconContactCenter, IconContactCenterFill],
            routePath: RoutePath.ContactCenter,
            pinned: true,
        },
        {
            show: isChatEnabled(),
            text: Chat_Text,
            tag: headerTabType.chat,
            icon: [IconTeamChat, IconTeamChatFill],
            banner: isChatEnabled() && <ChatUnReadBadge />,
            routePath: RoutePath.Chat,
            pinned: true,
        },
        {
            show: showPhoneIcon,
            text: Phone_Text,
            tag: headerTabType.phone,
            icon: [IconPhoneSmall, IconPhoneSmallFill],
            banner: showPhoneIcon ? <PhoneTabBanner /> : null,
            routePath: RoutePath.Phone,
            pinned: true,
        },

        {
            show: true,
            text: MEETINGS_TEXT,
            tag: headerTabType.meetings,
            icon: [IconVideoOn, IconVideoOnFill],
            banner: null,
            routePath: RoutePath.Meetings,
            pinned: true,
        },
        {
            show: true,
            text: CONTACTS_BUTTON,
            tag: headerTabType.contacts,
            icon: [IconProfileContact, IconProfileContactFill],
            banner: null,
            routePath: RoutePath.Contacts,
            pinned: true,
        },
        {
            show: isWhiteBoardEnabled(),
            text: Whiteboards_Text,
            tag: headerTabType.whiteboards,
            icon: [IconWhiteboardSmall, IconWhiteboardFillSmall],
            banner: null,
            routePath: RoutePath.Whiteboards,
            pinned: true,
        },
    ].filter((_) => _.show);

    const sharedTabs = useMemo(() => {
        const _tabs = [...tabs];
        if (userSettingHeaderTabs.length > 0) {
            return _tabs
                .sort((a, b) => {
                    const orderA = userSettingHeaderTabs.findIndex((item: any) => item.tag === a.tag);
                    const orderB = userSettingHeaderTabs.findIndex((item: any) => item.tag === b.tag);

                    return orderA - orderB;
                })
                .map((item) => {
                    // need to locate index in case we activate a new tab
                    const tabIndex = userSettingHeaderTabs.findIndex((tab) => tab.tag === item.tag);
                    // New app has been activated and user has already reorganized tabs
                    if (tabIndex === -1) {
                        return item;
                    }

                    return { ...item, pinned: userSettingHeaderTabs[tabIndex]?.pinned };
                });
        } else {
            return _tabs;
        }
    }, [tabs.length, userSettingHeaderTabs]);

    const setSavedTabs = (_tabs: any) => {
        dispatch(updateHeaderTabsPinned(_tabs.map(({ tag, pinned }: any) => ({ tag, pinned }))));
    };

    const setData = (tabData: any) => {
        if (tabData === 'reset') {
            setSavedTabs(tabs);
        } else {
            setSavedTabs(tabData);
        }
    };

    return { data: sharedTabs, setData: setData };
};
export default useTabList;
