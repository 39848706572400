import React from 'react';

import classNames from "classnames";
import styles from '../operation.module.scss';
import { STAR_THIS_CONTACT_TEXT, UNSTAR_THIS_CONTACT_TEXT } from '../../../../../../resource';
import { RootState } from '../../../../../../store';
import { connect } from 'react-redux';
import { xmppAgent } from '../../../../../../app-init';
import useIsStranger from '../../../../../../hooks/useIsStranger';
import { OperationMenuItemProps } from '../types';
import { STARED_CONTACTS_GROUP_ID } from '../../../../types';
import { isPersonalContactGroup } from '../../../../utils/stringUtils';

interface IStarContacts extends Partial<OperationMenuItemProps> {
    staredContacts: string[];
}

const StarContactsMenuItem = ({
    staredContacts,
    jid,
    groupId,
    closeMenu
}: IStarContacts) => {

    const isStranger = useIsStranger(jid);

    if (isStranger || isPersonalContactGroup(groupId)) {
        return null;
    }
    
    const isCurrentActionPanelItemStared = staredContacts.includes(jid);
    return <>
        <button
            className={classNames(styles.menuItem)}
            onClick={(e) => {
                e.stopPropagation();
                xmppAgent.starOrUnStarContact(jid, !isCurrentActionPanelItemStared);
                closeMenu();
            }}
        >
            {isCurrentActionPanelItemStared ? UNSTAR_THIS_CONTACT_TEXT : STAR_THIS_CONTACT_TEXT}
        </button>
        {/* TODO: need the groupLine back */}
    </>
}

const mapStateToProps = (state: RootState) => {
    const {
        contacts: {
            groupToContactTable,
        }
    } = state;

    return {
        staredContacts: groupToContactTable[STARED_CONTACTS_GROUP_ID]
    }
}

export default connect(mapStateToProps)(StarContactsMenuItem);