/**
 * https://zoomvideo.atlassian.net/wiki/spaces/PWA/pages/2621637390/Zoom+Contact+Center+ZCC+integration
 * message event definition reference
 */
import { ETaskType } from './incoming-types';
export interface ICurrentCall {
    from: string; // phone number
    fromName: string;
    taskSid: string;
    startTime: number;
    isMute: boolean;
    type: ETaskType;
}

export enum ECciMsgNotifyAudioSetting {
    CCI_MSG_NOTIFY_NONE = 0,
    CCI_MSG_NOTIFY_ALWAYS_PLAY = 1,
    CCI_MSG_NOTIFY_MUTE_DURING_MTG_AND_CALL = 2,
    CCI_MSG_NOTIFY_ALWAYS_MUTE = 3,
}

export * from './incoming-types';
export * from './outgoing-types';
