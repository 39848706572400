import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { selectZoomAppsEntities } from '../../ZApps/redux/zoom-apps-selector';

export const selectContactCenterRunningAppList = createSelector([selectZoomAppsEntities], (entities) => {
    return Object.values(entities).filter((item) => {
        return item.runningContext === 'inContactCenter';
    });
});

export const selectContactCenterCurrentRunningAppId = (state: RootState) =>
    state.contactCenter.zoomApps.currentRunningAppId;

export const selectContactCenterCurrentRunningApp = createSelector(
    [selectZoomAppsEntities, selectContactCenterCurrentRunningAppId],
    (entities, appId) => {
        return entities[appId];
    },
);

export const selectContactCenterRunningAppById = (runningAppId: string) =>
    createSelector([selectZoomAppsEntities], (entities) => {
        return entities[runningAppId];
    });
