import pako from 'pako';
import { meetingAgent, xmppAgent } from '../../../../app-init';
import { ITransferMeeting, setIsStartDisabled, setMeetingLcp } from '../../../Meeting/redux';
import { AppDispatch, AppGetState } from '../../../Phone/types';
import { Meeting_Transfer_Notify_Msg } from '../../LanguageResource';
import protobufjs from 'protobufjs/light';
import meetDescription from '../../Upcoming/meeting.proto.js';
import { ITransferMeetingThunk } from './meetings-thunk';
import { toUint8Array } from 'js-base64';
import { CookieSetOptions } from 'universal-cookie';
import { cookies } from '../../../../utils/Cookies/cookie';
import { WC_COOKIE_KEY } from '../../../../utils/constants';
import { setModal } from '../../../../store/modal/modal-store';

export const transferMeetingThunk =
    ({ userInfo, number, deviceId, resource, name }: ITransferMeetingThunk) =>
    async (dispatch: AppDispatch, _getState: AppGetState) => {
        const { displayName: userName, userId } = userInfo;
        try {
            dispatch(setIsStartDisabled(true));
            console.log('[PWA] [Transfer]', 'fetch LCP from Xmpp');
            const lcp = await xmppAgent.requestLcp(
                number,
                deviceId,
                Meeting_Transfer_Notify_Msg(name),
                userName,
                resource,
            );
            console.log('[PWA] [Transfer]', 'fetched LCP from Xmpp success');
            const transferData: ITransferMeeting = {
                lcp,
            };
            const result = pako.inflate(toUint8Array(lcp));
            const root = protobufjs.Root.fromJSON(meetDescription);
            const launchConfDecoder = root.lookupType('MeetingProtos.LaunchConfParameter');
            const launchConf: any = launchConfDecoder.decode(result);
            const now = new Date();
            const duration = 1000 * 60 * 60 * 2; // 2 hours
            const cookieOptions: CookieSetOptions = {
                expires: new Date(now.getTime() + duration),
                path: '/',
                sameSite: 'none',
                secure: true,
            };
            cookies.set(WC_COOKIE_KEY.NAME, launchConf['userName'] || '', cookieOptions);
            cookies.set(WC_COOKIE_KEY.EMAIL, launchConf['userEmail'] || '', cookieOptions);
            const { password = '', hostId = '', options = 0, alternativeHost = [] } = launchConf['meeting'];

            const meetingOption: any = {
                transfer: 1,
            };
            const boToken = launchConf['boToken'];
            const boID = launchConf['boID'];
            const boTitle = launchConf['boTitle'];
            if (boToken && boID) {
                transferData.boID = boID;
                transferData.boTitle = boTitle || '';
                transferData.boToken = boToken;
            }
            transferData.isAudioOn = (options & 0x0002) !== 0;
            transferData.isVideoOn = (options & 0x0004) !== 0;
            transferData.isWaitingRoomVideoOn = !!+launchConf['appRecords'].find(
                (record: any) => record.key === 'waiting.room.video.on',
            )?.value;
            transferData.isWaitingRoomAudioOn = !!+launchConf['appRecords'].find(
                (record: any) => record.key === 'waiting.room.audio.on',
            )?.value;
            transferData.isAudioDisconnect = (options & 0x0080) !== 0;
            dispatch(setMeetingLcp(transferData));

            const isHost = hostId === userId;
            const isAlternativeHost = (alternativeHost as Array<{ id: string }>).find((alt) => alt.id === userId);

            if (isHost || isAlternativeHost) {
                meetingAgent.startMeetingWithMeetingNumber(number, meetingOption);
            } else {
                meetingOption.pwd = password;
                meetingAgent.joinMeeting(number, meetingOption);
            }
        } catch (e) {
            dispatch(
                setModal({
                    name: 'transferMeetingError',
                    data: {
                        show: true,
                    },
                }),
            );
        } finally {
            dispatch(setIsStartDisabled(false));
        }
    };
