import qs from 'querystring';
import { AxiosRequestConfig } from 'axios';
import httpRequest from './http';
import { getSignOutPath } from '../../utils/meeting-url';
import { getClientCaps } from '../../utils/clientCapability';
import { uploadUserSessionLogs, userSessionLogger } from '../../logger/pwa-loggers';
import { PwaErrors } from '../error/pwa-error';
import { memoizeWithExpireTime } from '../utils';

/**
 *
 * @param {Bboolean} param0: whather we want to update xmpp token
 * every time we call this api, web backend will generate a new xmpp token. if we already hava an xmpp connection, it is still ok.
 * but now we have more than one valid xmpp token, it is not so good. so pass profileOnly: true, to only get user's profile only.
 */
export function fetchUserInfo({ profileOnly = false } = {}) {
    const url = '/get_profile_for_pwa';
    let options = {};
    if (profileOnly) {
        options = {
            profileOnly: true,
        };
    }

    return httpRequest.post(url, qs.stringify(options)).then((response) => {
        return response.data;
    });
}

export const fetchUserWebSettings = memoizeWithExpireTime(() => {
    const headers = {
        'ZM-CAP': getClientCaps(),
    };
    const url = '/get_profile_feature_options_for_pwa';
    return httpRequest.post(url, null, { headers }).then((res) => res.data);
}, 5000);

export function signout() {
    const url = getSignOutPath();

    userSessionLogger.log('', ['Call_Signout_Api']).then(() => {
        uploadUserSessionLogs();
    });

    return httpRequest.get(url).then(() => {
        return true;
    });
}

export function fetchSessionStatus() {
    const url = '/user/session/status';
    return httpRequest.get(url).then((res) => res.data);
}

export function sessionExpireRelogin() {
    const url = '/user/session/relogin';
    const config: AxiosRequestConfig = {
        params: {
            backUrl: encodeURIComponent(window.location.href),
            from: 'pwa',
        },
    };
    return httpRequest.get(url, config).then((res) => res.data);
}

export enum LoginRequestType {
    Allow,
    Deny,
}

export const allowOrDenyLoginRequestUrl = '/user/otp/notify/pwa';

export async function allowOrDenyLoginRequest(code: string, from: string, action: LoginRequestType) {
    const csrfConfig = await getCsrfToken();

    const config = {
        params: {
            code,
            from,
            action: action === LoginRequestType.Allow ? 'allow' : 'deny',
        },
        headers: csrfConfig.headers,
    };
    const response = await httpRequest.post(allowOrDenyLoginRequestUrl, config, config);

    return response.data;
}

export const csrfUrl = '/csrf_js';

export const getCsrfToken = async () => {
    let response;
    try {
        response = await httpRequest.post(csrfUrl, null, {
            headers: {
                'fetch-csrf-token': 1,
            },
        });
    } catch (e) {
        throw Object.assign({}, PwaErrors.Unknown);
    }

    const { status, statusText, data } = response;

    if (status !== 200) {
        throw {
            code: PwaErrors.Unknown.code,
            message: data?.msg || data || statusText || PwaErrors.Unknown.message,
        };
    }

    let [csrfTokenName, csrfToken] = data.split(':');
    csrfTokenName = csrfTokenName.trim().toLowerCase();
    csrfToken = csrfToken.trim();

    return {
        headers: {
            [csrfTokenName]: csrfToken,
        },
    };
};

export const submitFeedBack = async (product: string, score: string, text: string) => {
    const csrfConfig = await getCsrfToken();
    const url = '/submit_feedback';

    const config = {
        params: {
            product: product,
            score: score,
            text: text,
        },
        headers: csrfConfig.headers,
    };
    return httpRequest.post(url, config, config).then((res) => console.error(res));
};
