import React from 'react';
import { addSpaceToMeetingId } from '../../../utils/index';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { pmiMeetingSettingSelector, selectedMeetingItemSelector } from './redux/meetings-selector';
import { setSelectedMeetingItemId } from './redux/meetings-store';
import { My_PMI_Text } from '../LanguageResource';

const PMISection = () => {
    const dispatch = useAppDispatch();
    const pmiSetting = useAppSelector(pmiMeetingSettingSelector);
    const selectedMeetingItem = useAppSelector(selectedMeetingItemSelector);

    if (!pmiSetting) return null;

    const { meetingNumber, id } = pmiSetting;
    const txt = My_PMI_Text;
    const pmi = addSpaceToMeetingId(meetingNumber);
    const clsname = classnames({
        meetings__pmi: true,
        'meetings__pmi--selected': selectedMeetingItem && id === selectedMeetingItem.id,
    });
    const onClick = () => {
        dispatch(setSelectedMeetingItemId(id));
    };

    return (
        <>
            <button className={clsname} onClick={onClick}>
                <p className="meetings__pmi-number">{pmi}</p>
                <p className="meetings__pmi-txt">{txt}</p>
            </button>
            <div className="meetings__pmi-divider"></div>
        </>
    );
};

export default PMISection;
