import './ZccNotification.scss';
import React from 'react';
import { Radio, RadioGroup } from '@zoom/react-ui';
import {
    CCI_NOTIFICATION_SETTING,
    CCI_NOTIFICATION_ALWAYS_PLAY,
    CCI_NOTIFICATION_ALWAYS_PLAY_DESC,
    CCI_NOTIFICATION_MUTE_DURING_CALL,
    CCI_NOTIFICATION_MUTE_DURING_CALL_DESC,
    CCI_NOTIFICATION_ALWAYS_MUTE,
    CCI_NOTIFICATION_ALWAYS_MUTE_DESC,
} from '../../../resource';
import { useAppDispatch, useAppSelector } from '../../../store';
import { isContactCenterEnabledSelector } from '../../../store/appModules/app-modules-selector';
import {
    zccNotificationSettingSelector,
    zccNotificationSettingWithPolicySelector,
} from '../../ContactCenter/redux/zcc-selectors';
import { setMessageNotificationSetting } from '../../ContactCenter/redux';
import { ECciMsgNotifyAudioSetting } from '../../ContactCenter/types';
import {
    saveNotificationSettingToLocalStorage,
    sendMessageNotificationSettingToCCI,
} from '../../ContactCenter/redux/zcc-actions';
import { featureOptionManager } from '../../../app-init';

const ZccNotification = () => {
    const dispatch = useAppDispatch();
    const isContactCenterEnabled = useAppSelector(isContactCenterEnabledSelector);
    const notificationSetting = useAppSelector(zccNotificationSettingSelector);
    const notificationSettingWithPolicy = useAppSelector(zccNotificationSettingWithPolicySelector);
    const isEnableZccNotificationSetting = featureOptionManager.isSupportZccNotificationSetting();

    if (!isContactCenterEnabled || !isEnableZccNotificationSetting) {
        return null;
    }

    const handleNotificationSettingChange = (value: number) => {
        dispatch(setMessageNotificationSetting(value as ECciMsgNotifyAudioSetting));
        dispatch(saveNotificationSettingToLocalStorage(value as ECciMsgNotifyAudioSetting));
        dispatch(sendMessageNotificationSettingToCCI());
    };

    return (
        <div className="zcc-notification zm-audio-pane" role="region" aria-label={CCI_NOTIFICATION_SETTING}>
            <div className="zcc-notification-title settings-title">{CCI_NOTIFICATION_SETTING}</div>
            <RadioGroup
                id="zcc-notification"
                value={notificationSetting}
                disabled={notificationSettingWithPolicy}
                onChange={handleNotificationSettingChange}
            >
                <Radio
                    id="always-play"
                    value={ECciMsgNotifyAudioSetting.CCI_MSG_NOTIFY_ALWAYS_PLAY}
                    description={CCI_NOTIFICATION_ALWAYS_PLAY_DESC}
                >
                    {CCI_NOTIFICATION_ALWAYS_PLAY}
                </Radio>
                <Radio
                    id="mute-during-call"
                    value={ECciMsgNotifyAudioSetting.CCI_MSG_NOTIFY_MUTE_DURING_MTG_AND_CALL}
                    description={CCI_NOTIFICATION_MUTE_DURING_CALL_DESC}
                >
                    {CCI_NOTIFICATION_MUTE_DURING_CALL}
                </Radio>
                <Radio
                    id="always-mute"
                    value={ECciMsgNotifyAudioSetting.CCI_MSG_NOTIFY_ALWAYS_MUTE}
                    description={CCI_NOTIFICATION_ALWAYS_MUTE_DESC}
                >
                    {CCI_NOTIFICATION_ALWAYS_MUTE}
                </Radio>
            </RadioGroup>
        </div>
    );
};

export default ZccNotification;
