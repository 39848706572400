import React, { ReactNode } from 'react';
import Dialog from './Dialog';
import Button from '../../../components/Button/Button';
import styles from './confirm-dialog.module.scss';
import classNames from 'classnames';
import { Modal } from 'Modal';
import { isFunction, isUndefined, noop } from 'lodash-es';
import { Cancel_Text, Delete_Text } from '../../Meetings/LanguageResource';
import { confirmText } from '../../../resource';

type BtnProps = { text?: string; handler?: () => void; disabled?: boolean };

const getValueFromProps = (obj: true | BtnProps, key: keyof BtnProps, defaultValue: any) => {
    if (obj === true) {
        return defaultValue;
    } else {
        if (key in obj && !isUndefined(obj[key])) {
            return obj[key];
        } else {
            return defaultValue;
        }
    }
};

interface IProps {
    isOpen: boolean;
    className?: string; // class name of dialog
    title: string;
    body: ReactNode;
    cancelBtn?: true | BtnProps;
    safeConfirmBtn?: true | BtnProps;
    dangerConfirmBtn?: true | BtnProps;
    closeMe(): void;
}

const ConfirmDialog = ({
    isOpen,
    className,
    title,
    body,
    closeMe,
    cancelBtn,
    safeConfirmBtn,
    dangerConfirmBtn,
}: IProps) => {
    if (!isFunction(closeMe)) {
        closeMe = noop;
    }

    return (
        <Modal
            needShowAboveBase={true}
            isOpen={isOpen}
            centered={true}
            needBackdrop={true}
            isDraggable={false}
            onRequestClose={closeMe}
        >
            <Dialog className={classNames(styles.confirmDialog, { [className]: className })}>
                <header className={styles.header}>{title}</header>
                <section className={styles.body}>{body}</section>
                <footer className={styles.footer}>
                    {cancelBtn ? (
                        <Button
                            type={Button.Secondary}
                            onClick={getValueFromProps(cancelBtn, 'handler', closeMe)}
                            disabled={getValueFromProps(cancelBtn, 'disabled', false)}
                        >
                            {getValueFromProps(cancelBtn, 'text', Cancel_Text)}
                        </Button>
                    ) : null}

                    {dangerConfirmBtn ? (
                        <Button
                            type={Button.Destructive}
                            onClick={getValueFromProps(dangerConfirmBtn, 'handler', closeMe)}
                            disabled={getValueFromProps(dangerConfirmBtn, 'disabled', false)}
                        >
                            {getValueFromProps(dangerConfirmBtn, 'text', Delete_Text)}
                        </Button>
                    ) : null}

                    {safeConfirmBtn ? (
                        <Button
                            type={Button.Secondary}
                            onClick={getValueFromProps(safeConfirmBtn, 'handler', closeMe)}
                            disabled={getValueFromProps(safeConfirmBtn, 'disabled', false)}
                        >
                            {getValueFromProps(safeConfirmBtn, 'text', confirmText)}
                        </Button>
                    ) : null}
                </footer>
            </Dialog>
        </Modal>
    );
};

export default ConfirmDialog;
