import { memoize } from 'lodash-es';
import serviceContainer from '../container/index';

export const maybeWaitUserInfoReady = (): Promise<any> => {
    const reduxStore = serviceContainer.getReduxStore();
    const {
        common: { userInfo },
    } = reduxStore.getState();

    if (userInfo && userInfo.userId) {
        return Promise.resolve();
    } else {
        return new Promise<any>((resolve, reject) => {
            const eventBus = serviceContainer.getEventBus();
            eventBus.app.userInfoReady.subscribe((result, { id }) => {
                eventBus.app.userInfoReady.unsubscribe(id);
                const state = reduxStore.getState();
                if (state?.common?.userInfo && state.common.userInfo.userId) {
                    return resolve(result);
                }
                return reject('Not get userId');
            });
        });
    }
};

export const maybeWaitXMPPReady = (): Promise<any> => {
    const xmppAgent = serviceContainer.getXmppAgent();

    if (xmppAgent.isConnected()) {
        return Promise.resolve();
    } else {
        return new Promise<any>((resolve, reject) => {
            const eventBus = serviceContainer.getEventBus();
            eventBus.xmpp.xmppReady.subscribe((result, { id }) => {
                eventBus.xmpp.xmppReady.unsubscribe(id);
                if (xmppAgent.isConnected()) {
                    return resolve(result);
                }
                return reject('XMPP not connected');
            });
        });
    }
};

/**
 * A memoize function with expire time
 * @param fn - The function to memoize return value by args
 * @param expireTime - The cache expire time in milliseconds
 * @returns The memoized function
 */
export function memoizeWithExpireTime<T extends (...args: Array<any>) => any>(fn: T, expireTime: number = 1000) {
    const memoized = memoize(fn, (...args) => JSON.stringify(args));
    const cache = memoized.cache;

    const wrappedFn = (...args: Parameters<T>) => {
        const key = JSON.stringify(args);
        const hasCache = cache.has(key);

        if (!hasCache) {
            setTimeout(() => {
                cache.delete(key);
            }, expireTime);
        }

        return memoized(...args);
    };

    return wrappedFn;
}
