import { isArray } from 'lodash-es';
import { ZccEngagement } from '../ContactCenter/types';
import { ZApp_Notification_Data } from './types/message';

export const getIncrementor = (prefix = 'inc') => {
    let base = 0;
    return () => {
        return `${prefix}_${base++}`;
    };
};

export const createAppResponse = (callId: string, data: object) => {
    return {
        type: 'apiResponse',
        data: {
            jsCallId: callId,
            result: data,
        },
    };
};

export const appResponseSimpleResult = {
    success: 'Success',
    failure: 'Failure',
};

export const createAppResponseError = (callId: string, errorCode: number, errorMessage: string) => {
    return {
        type: 'apiResponse',
        data: {
            jsCallId: callId,
            result: false,
            errorCode,
            errorMessage,
        },
    };
};

export const createAppNotification = (name: string, data: ZApp_Notification_Data) => {
    return {
        type: 'event',
        name,
        data,
    };
};

// https://coda.io/d/_d5wCHggE6Tg/Zoom-Apps-for-ZCC-V3_suqA7#_lu2l_
export const getContextFromEngagment = (engagement: ZccEngagement) => {
    const result = {
        engagementId: engagement.engagementId,
        startTime: engagement.startTime,
        acceptTime: engagement.acceptTime,
        queueId: engagement.queueId,
        queueName: engagement.queueName,
        isTransfer: engagement.isTransfer,
        transferType: engagement.transferType,
        transferFromAgentId: engagement.transferFromAgentId,
        transferFromAgentName: engagement.transferFromAgentName,
    };
    return result;
};

export const getStatusFromEngagement = (engagement: Partial<ZccEngagement>) => {
    const sameKeys: Array<keyof ZccEngagement> = [
        'engagementId',
        'endTime',
        'channel',
        'source',
        'isConference',
        'assignedAgentId',
        'assignedAgentName',
    ];

    const diffKeys: Array<[keyof ZccEngagement, string]> = [['status', 'state']];

    const fStatus: any = {};

    sameKeys.forEach((key) => {
        if (key in engagement) {
            fStatus[key] = engagement[key];
        }
    });

    diffKeys.forEach(([from, to]) => {
        if (from in engagement) {
            fStatus[to] = engagement[from];
        }
    });

    if (isArray(engagement.consumers)) {
        fStatus.consumers = engagement.consumers.map((item) => {
            return {
                consumerId: item.consumerId,
                consumerName: item.consumerDisplayName,
                consumerNumber: item.consumerNumber,
                consumerEmail: item.consumerEmail,
            };
        });
    }

    if (fStatus.state === 'closed') {
        fStatus.state = 'end';
    }

    return fStatus;
};
