import serviceContainer from '../../../services/container';
import {
    ZCCInComingMessageFunctionName,
    ZCCIncomingMessage_AppHide,
    ZCCIncomingMessage_AppResize,
    ZCCIncomingMessage_AppShow,
    ZCCIncomingMessage_AppUpdateEngagementVariables,
    ZCCIncomingMessage_AppUpdateVariables,
    ZCCIncomingMessage_CreateEngagement,
    ZCCIncomingMessage_EndEngagement,
    ZCCIncomingMessage_GetZCCAppList,
    ZCCIncomingMessage_SetPCIPalSecureStatus,
    ZCCIncomingMessage_SetStartMediaRedirectionApiResult,
    ZCCIncomingMessage_SwitchEngagement,
    ZCCIncomingMessage_UpdateEngagement,
    ZCCIncomingMessage_UpdatePCIPalMediaRedirectionStatus,
    ZCCOutgoingMessageNotification,
} from '../types';
import { createZCCNotification, generateRunningAppId } from '../utils';
import ContactCenterAgent from './ContactCenterAgent';
import { contactCenterAgentMessageDecorator } from './messageHandlerDecorator';
import engagements from '../Engagement';
import Signal from '../../../utils/Signal';
import ZAppErrors, { createError } from '../../ZApps/Errors';
import { getContextFromEngagment, getStatusFromEngagement } from '../../ZApps/utils';
import {
    addRunningApp,
    deleteRunningApp,
    RunningApp_Starting,
    updateRunningApp,
} from '../../ZApps/redux/zoom-apps-slice';
import { setCurrentRunningAppId } from '../redux';
import { selectContactCenterCurrentRunningApp, selectContactCenterRunningAppById } from '../redux/zcc-apps-selector';
import appVariables from '../AppVariables';
import { queueToAppList } from '../QueueToApps';
import { scheduleDestroyAppInstancesProcess } from './handle-engagement-end';
const { handleMessage } = contactCenterAgentMessageDecorator;

const store = serviceContainer.getReduxStore();

class ContactCenter_WithZccApp extends ContactCenterAgent {
    hasSubscribedAppEvents: boolean;

    constructor() {
        super();
        this.hasSubscribedAppEvents = false;
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_GetZCCAppList)
    async onGetZoomAppList(message: ZCCIncomingMessage_GetZCCAppList) {
        const { queueId } = message.params;
        const zAppController = serviceContainer.getZAppController();
        try {
            const list = await zAppController.fetchAppList({
                params: { queue: queueId },
            });
            const appList = list.map((item) => {
                return {
                    id: item.id,
                    applicationId: item.id,
                    iconURL: item.icon,
                    displayName: item.display_name,
                };
            });

            queueToAppList.setAppListByQueue(queueId, appList);

            const response = {
                jsCallId: message.jsCallId,
                result: {
                    appList,
                },
                returnCode: 0,
            };
            return this.postMessage(response);
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppShow)
    async onAppShow(message: ZCCIncomingMessage_AppShow) {
        this.handleAppShow(message.params);
        this.ackMessage(message);
    }

    // create a new app or show the same existing app
    async handleAppShow(params: ZCCIncomingMessage_AppShow['params'], refreshApp = false) {
        if (!params) {
            return;
        }

        const currentEngagement = engagements.getCurrentEngagement();

        /**
         * for refresh app in installing state, we don't have queue id here, rather we get current engagement's queueId
         */
        const queueId = params.engagement?.queueId || currentEngagement?.queueId;
        const zoomAppId = params.applicationId;

        delete params.engagement;
        delete params.engagementId;

        const runningAppId = generateRunningAppId({
            appId: zoomAppId,
            runningContext: 'inContactCenter',
        });

        const app = selectContactCenterRunningAppById(runningAppId)(store.getState());

        const zAppController = serviceContainer.getZAppController();

        let appInstance = null;

        /**
         * if we wanna refresh app, we ignore it current state
         * ans start fron 'starting' state
         */
        if (!refreshApp) {
            if (app && app.status === 'starting') {
                store.dispatch(setCurrentRunningAppId(runningAppId));
                return;
            }

            if (app && app.status === 'success') {
                appInstance = zAppController.getAppInstance(app.runningAppId);

                if (appInstance?.instanceId) {
                    zAppController.pickUpAppInstance(appInstance.instanceId);
                }

                store.dispatch(updateRunningApp({ runningAppId, updates: params }));
                store.dispatch(setCurrentRunningAppId(runningAppId));
                return;
            }

            if (app && app.status === 'installing') {
                return;
            }
        }

        // set to initial state 'starting'
        const appInfo = zAppController.getZoomAppInfo(zoomAppId);

        const runningApp = Object.assign(
            {
                runningAppId: runningAppId,
                displayName: appInfo.display_name,
                iconUrl: appInfo.icon,
            },
            params,
            {
                status: 'starting' as const,
                runningContext: 'inContactCenter' as const,
            },
        );

        // it's not meant to add, also update if it exists
        store.dispatch(addRunningApp(runningApp));

        store.dispatch(setCurrentRunningAppId(runningAppId));

        try {
            const launch = await zAppController.launchApp({
                zoomAppId,
                runningContext: 'inContactCenter',
                runningAppId,
                body: {
                    zcc_context: {
                        queue: queueId,
                    },
                },
            });

            // in case we delete it from scheduleDestroyAppInstancesProcess before launch request succeeds
            if (!selectContactCenterRunningAppById(runningAppId)(store.getState())) {
                return;
            }

            const launchResult = launch.result;

            if (launchResult === 'launch') {
                appInstance = launch.data.appInstance;
                store.dispatch(
                    updateRunningApp({
                        runningAppId,
                        updates: { status: 'success' },
                    }),
                );
            }

            if (launchResult === 'install') {
                store.dispatch(
                    updateRunningApp({
                        runningAppId,
                        updates: { status: 'installing' },
                    }),
                );
                // wait for app install event
                // we will open new tab for user to authorize
                // after user allowed, server will push zpns message, then we continue
                this.handleAppEvent();
            }
        } catch (e) {
            if (appInstance?.instanceId) {
                zAppController.destroyAppInstance(appInstance.instanceId, appInstance);
            }

            store.dispatch(
                updateRunningApp({
                    runningAppId,
                    updates: {
                        status: 'error',
                        errorCode: (e as any).errorCode || ZAppErrors.LaunchFailed.code,
                        errorMsg: (e as any).errorCode || ZAppErrors.LaunchFailed.message,
                    },
                }),
            );
        }

        if (appInstance?.instanceId) {
            zAppController.pickUpAppInstance(appInstance.instanceId);
        }

        return;
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppHide)
    async onAppHide(message: ZCCIncomingMessage_AppHide) {
        // zcc doesn't tell us which one to hide
        // we just hide current one
        store.dispatch(setCurrentRunningAppId(''));
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppResize)
    async onAppResize(message: ZCCIncomingMessage_AppResize) {
        const { params } = message;
        const state = store.getState();
        const runningApp = selectContactCenterCurrentRunningApp(state);

        if (!runningApp || runningApp.applicationId !== params?.applicationId) {
            this.ackMessage(message, -1);
            return;
        }

        store.dispatch(updateRunningApp({ runningAppId: runningApp.runningAppId, updates: params }));

        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppCreateEngagement)
    async onCreateEngagement(message: ZCCIncomingMessage_CreateEngagement) {
        const { params } = message;
        if (!params?.engagementId) {
            this.ackMessage(message, -1);
            return;
        }
        const { engagement, engagementId } = params;
        engagements.add(engagementId, engagement);

        engagements.setCurrentEngagementId(params.engagementId);

        const zAppController = serviceContainer.getZAppController();
        if (!zAppController) {
            return;
        }

        zAppController.notifyApps({
            runningContext: 'inContactCenter',
            name: 'onEngagementContextChange',
            event: {
                engagementContext: getContextFromEngagment(engagement),
            },
        });

        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSwitchEngagement)
    async onSwitchEngagement(message: ZCCIncomingMessage_SwitchEngagement) {
        // we don't care it. confirmed with jake.yu Jan/23/2024
        const { params } = message;

        if (!params?.engagementId) {
            this.ackMessage(message);
            return;
        }

        let engagement = engagements.get(params.engagementId);

        // why??
        // zcc only sends switch engagement, but don't ask to add it before!!!!
        if (!engagement) {
            engagements.add(params.engagementId, params.engagement);
            engagement = engagements.get(params.engagementId);
        }

        engagements.setCurrentEngagementId(params.engagementId);

        const zAppController = serviceContainer.getZAppController();
        if (!zAppController) {
            return;
        }

        zAppController.notifyApps({
            runningContext: 'inContactCenter',
            name: 'onEngagementContextChange',
            event: {
                engagementContext: getContextFromEngagment(engagement),
            },
        });

        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdateEngagement)
    async onUpdateEngagement(message: ZCCIncomingMessage_UpdateEngagement) {
        const { params } = message;
        if (!params?.engagementId) {
            this.ackMessage(message, -1);
            return;
        }

        // engamement is in type of diff
        if (!params.engagement) {
            this.ackMessage(message);
            return;
        }

        const { engagement, engagementId } = params;

        // legacy values. we have onCreateEngagement and onEndEngagement events.
        if (['closed', 'created'].includes(engagement.status)) {
            this.ackMessage(message);
            return;
        }

        engagements.update(engagementId, engagement);

        const diffStatus = getStatusFromEngagement(engagement);

        const zAppController = serviceContainer.getZAppController();

        zAppController.notifyApps({
            runningContext: 'inContactCenter',
            name: 'onEngagementStatusChange',
            event: {
                engagementStatus: Object.assign(diffStatus, { engagementId }),
            },
        });

        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppEndEngagement)
    async onEndEngagement(message: ZCCIncomingMessage_EndEngagement) {
        const { params } = message;

        if (!params?.engagementId) {
            this.ackMessage(message, -1);
            return;
        }

        const zAppController = serviceContainer.getZAppController();

        engagements.delete(params.engagementId);

        zAppController.notifyApps({
            runningContext: 'inContactCenter',
            name: 'onEngagementStatusChange',
            event: {
                engagementStatus: Object.assign({
                    engagementId: params.engagementId,
                    state: 'end',
                    endTime: Date.now(),
                }),
            },
        });

        scheduleDestroyAppInstancesProcess({ contactCenterAgent: this });

        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdateEngagementVariables)
    async onUpdateEngagementVariables(message: ZCCIncomingMessage_AppUpdateEngagementVariables) {
        const updates = appVariables.updateEngagementVariables(message.params);
        if (updates.length) {
            const zAppController = serviceContainer.getZAppController();
            if (zAppController) {
                zAppController.notifyApps({
                    runningContext: 'inContactCenter',
                    name: 'onEngagementVariableValueChange',
                    event: {
                        timestamp: Date.now(),
                        variables: updates,
                    },
                });
            }
        }
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdateVariables)
    async onUpdateAppVariables(message: ZCCIncomingMessage_AppUpdateVariables) {
        appVariables.updateAppVariables(message.params);
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppGetFrameState)
    async onGetFrameStatus(message: any) {
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSetPCIPalSecureStatus)
    async onSetPCIPalSecureStatus(message: ZCCIncomingMessage_SetPCIPalSecureStatus) {
        const { params } = message;
        this.ackMessage(message);
        const signal = Signal.get(`getPCIPalSecureStatus-${params.engagementId}`);
        if (!signal) {
            return;
        }
        // Todo: how to determine error happened
        return signal.resolve(params);
    }

    getPCIPalSecureStatus = (props: { engagementId: string }) => {
        const notification = createZCCNotification(
            ZCCOutgoingMessageNotification.CCIUINotifyGetPCIPalSecureStatus,
            props,
        );

        const tag = `getPCIPalSecureStatus-${props.engagementId}`;

        if (Signal.has(tag)) {
            return Signal.get(tag);
        }

        const signal = Signal.create(tag);

        signal.catchTimeout(
            () => {
                throw createError(ZAppErrors.General);
            },
            () => {
                throw createError(ZAppErrors.SecurableStatusFail);
            },
        );

        this.postMessage(notification);

        return signal;
    };

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSetPCIPalMediaRedirectAPIResult)
    async onStartMediaRedirectionApiResult(message: ZCCIncomingMessage_SetStartMediaRedirectionApiResult) {
        const { params } = message;
        this.ackMessage(message);
        const signal = Signal.get(`startMediaRedirection-${params.engagementId}-${params.linkId}`);
        if (!signal) {
            return;
        }

        if (params.message === 'failed') {
            signal.reject(createError(params.errorCode, params.errorMessage));
        }
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdatePCIPalMediaRedirectStatus)
    async onUpdatePCIPalMediaRedirectionStatus(message: ZCCIncomingMessage_UpdatePCIPalMediaRedirectionStatus) {
        const { params } = message;
        this.ackMessage(message);
        const signal = Signal.get(`startMediaRedirection-${params.engagementId}-${params.linkId}`);

        const result = params;
        (result as any).failureReason = params.errorMessage;
        delete result.errorCode;
        delete result.errorMessage;

        if (signal) {
            // resolve 'start' action
            signal.resolve(result);
        }

        const zAppController = serviceContainer.getZAppController();
        if (zAppController) {
            // notify app; 1. response of start; 2. server status updated
            zAppController.notifyApps({
                runningContext: 'inContactCenter',
                name: 'onEngagementMediaRedirect',
                event: result,
            });
        }
    }

    startMediaRedirection = (props: { engagementId: string; linkId: string }) => {
        const notification = createZCCNotification(ZCCOutgoingMessageNotification.CCIUINotifyStartMediaRedirect, props);

        const tag = `startMediaRedirection-${props.engagementId}-${props.linkId}`;

        if (Signal.has(tag)) {
            return Signal.get(tag);
        }

        const signal = Signal.create(tag);

        signal.catchTimeout(() => {
            throw createError(ZAppErrors.General);
        });

        this.postMessage(notification);

        return signal;
    };

    handleAppEvent = () => {
        if (this.hasSubscribedAppEvents) {
            return;
        }
        this.hasSubscribedAppEvents = true;
        const zAppController = serviceContainer.getZAppController();
        zAppController.events.appInstalled.subscribe((event) => {
            const { clientId } = event;
            const reduxStore = serviceContainer.getReduxStore();
            const {
                zoomApps: {
                    runningApps: { entities },
                },
            } = reduxStore.getState();

            for (const app of Object.values(entities)) {
                if (
                    app.status === 'installing' &&
                    app.runningContext === 'inContactCenter' &&
                    app.applicationId === clientId
                ) {
                    this.refreshApp(app);
                }
            }
        });
    };

    //Omit<RunningApp_Starting, 'status'>
    /**
     * when your app needs to re-authorize. eg. your update published a new version, and some previleges require user's authorization.
     * this is different from app's calling authorize directly.
     * we open auth page in a new tab to authorize. after you complete authorization. we will be notified by zpns message. then we need to launch app again.
     */
    refreshApp(app: Omit<RunningApp_Starting, 'status'>) {
        const { runningAppId } = app;
        const reduxStore = serviceContainer.getReduxStore();

        /**
         * let say you have 2 engagemtns now, A and B.
         * if you open app X in engagement A. but if requires to authorize again (not in-client auth)
         * then we jump to zoom's auth page.
         * before you complete authorization.
         * you switch to engagment B.
         * then you authorized and we get zpns notification.
         * by now we need to launch app X again, but launch app needs engagement's queueId.
         * which engagement should we use here? engagemtn A or B?
         * according to alex.zhou@zoom.us, we use current engagement B.
         */

        const currentEngagement = engagements.getCurrentEngagement();

        if (!currentEngagement) {
            // no engagement now
            reduxStore.dispatch(deleteRunningApp(runningAppId));
            return;
        }

        reduxStore.dispatch(
            updateRunningApp({
                runningAppId,
                updates: {
                    status: '' as any,
                },
            }),
        );

        // add engagement, engagementId to comform to handleAppShow's paramters' type.
        this.handleAppShow(
            Object.assign(app, {
                engagement: currentEngagement,
                engagementId: currentEngagement.engagementId,
            }),
        );
    }

    notifyAppClose(props: { appId: string }) {
        const result = {
            appId: props.appId,
            appFrame: {
                applicationId: props.appId,
                paused: true,
                focus: true,
                state: 'close',
            },
        };
        const notification = createZCCNotification(
            ZCCOutgoingMessageNotification.CCIUINotifyZccAppFrameStateChange,
            result,
        );
        this.postMessage(notification);
    }
}
export default ContactCenter_WithZccApp;
