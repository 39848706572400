import { setMessageNotificationSetting, setMessageNotificationSettingFromPolicy } from '.';
import { AppDispatch, AppGetState } from '../../../store';
import { getChromeOSAdminPolicy } from '../../../utils/chrome-policy';
import easyStore from '../../../utils/storage';
import { contactCenterAgent } from '../ContactCenterAgent';
import { ECciMsgNotifyAudioSetting } from '../types';
import { zccNotificationSettingSelector } from './zcc-selectors';

const CCI_MSG_NOTIFY_AUDIO_SETTING_KEY = 'cci_msg_notify_audio_setting';

export const loadNotificationSettingFromLocalStorage = () => {
    return (dispatch: AppDispatch) => {
        const storedValue = easyStore.localStore.easyGet(
            CCI_MSG_NOTIFY_AUDIO_SETTING_KEY,
        ) as ECciMsgNotifyAudioSetting | null;
        let newVal: ECciMsgNotifyAudioSetting | null = null;

        if (storedValue) {
            newVal = storedValue;
        }
        dispatch(setMessageNotificationSetting(newVal || null));
    };
};

export const saveNotificationSettingToLocalStorage = (value: ECciMsgNotifyAudioSetting) => {
    return (_dispatch: AppDispatch) => {
        easyStore.localStore.easySet(CCI_MSG_NOTIFY_AUDIO_SETTING_KEY, value);
    };
};

export const loadNotificationStrategyFromChromeOSAdminPolicy = () => {
    return async (dispatch: AppDispatch) => {
        const policy = await getChromeOSAdminPolicy();
        const strategy = policy?.ZCCMsgAudioSetting || null;
        if (strategy) {
            dispatch(setMessageNotificationSettingFromPolicy(strategy));
        }
    };
};

export const sendMessageNotificationSettingToCCI = () => {
    return async (_dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const setting = zccNotificationSettingSelector(state);
        contactCenterAgent.notifyCCIClientSettingsChange({
            msgNotifyAudioSetting: String(setting),
        });
    };
};
