import { EAudioOccupyStatus, EClientZmCallStatusType } from './incoming-types';

export enum ZCCOutgoingMessageNotification {
    CCIUINotifCallStatus = 'CCIUINotifCallStatus',
    CCIUINotifCallStatus_OBPreviewDialer = 'CCIUINotifCallStatus_OBPreviewDialer',
    CCIUINotifyMuteUnmuteMic = 'CCIUINotifyMuteUnmuteMic',
    CCIUINotifyZoomCallStatus = 'CCIUINotifyZoomCallStatus',
    CCIUINotifySetCCIAudioStatus = 'CCIUINotifySetCCIAudioStatus',
    PWA_CCIUINotify_Destroy = 'PWA_CCIUINotify_Destroy',
    CCIUINotifySearchBuddy = 'CCIUINotifySearchBuddy',
    CCIUINotifyOnBuddyPresenceUpdate = 'CCIUINotifyOnBuddyPresenceUpdate',

    /** zcc app releated  */
    /** PCIPal app related */
    CCIUINotifyGetPCIPalSecureStatus = 'CCIUINotifyGetPCIPalSecureStatus',
    CCIUINotifyStartMediaRedirect = 'CCIUINotifyStartMediaRedirect',
    CCIUIAPI_ZCCAppCloseApp = 'CCIUIAPI_ZCCAppCloseApp',
    CCIUINotifyZccAppFrameStateChange = 'CCIUINotifyZccAppFrameStateChange',
    /** PCIPal app related */
    /** zcc app releated  */
    CCIUINotifyMountPoint_OBPreviewDialer = 'CCIUINotifyMountPoint_OBPreviewDialer',
    CCIUINotifyClientSettingsChange = 'CCIUINotifyCCIGetClientSettings',
}

type AnyObject = any;

interface OutgoingMessageResult extends AnyObject {
    notification: ZCCOutgoingMessageNotification;
}

export enum EClientBtnActionType {
    CCICallAcceptStatus_Unknown = 0,
    CCICallAcceptStatus_Accepted = 1,
    CCICallAcceptStatus_Rejected = 2,
    CCICallAcceptStatus_EndAccepted = 3,
    CCICallAcceptStatus_EndMeetingAccepted = 4,
    CCICallAcceptStatus_HoldAccepted = 5,
    CCICallAcceptStatus_HoldMeetingAccepted = 6,
    CCICallAcceptStatus_Ignore = 7,
    CCICallAcceptStatus_Skiped = 8,
}
export enum ENotifyAvConflictAction {
    Cancel,
    Continue,
}
export interface WithMsgId {
    msgId: string;
}

/*
    response means we respond when we receive request
*/
export interface ZCCOutgoingMessage_Response {
    jsCallId: string;
    result: AnyObject;
    returnCode?: number;
}

export interface ZCCOutgoingMessage_Response_ACK extends ZCCOutgoingMessage_Response {
    result: null;
}

export interface ZCCOutgoingMessage_Response_GetUserProfile extends ZCCOutgoingMessage_Response {
    result: {
        clientTheme: 'dark' | 'light';
        languageID: string;
    };
}

// notification means we notify zcc without his request
export interface ZCCOutgoingMessage_Notification {
    jsCallId: string; // should always be empty string here
    result: OutgoingMessageResult;
    returnCode?: number;
}

export type ZCCOutgoingMessage = ZCCOutgoingMessage_Response;

export interface ZCCOutgoingMessage_Notification_CallAction extends ZCCOutgoingMessage_Notification {
    result: {
        notification:
            | ZCCOutgoingMessageNotification.CCIUINotifCallStatus
            | ZCCOutgoingMessageNotification.CCIUINotifCallStatus_OBPreviewDialer;
        taskSid: string;
        status: EClientBtnActionType;
        skipReasonId?: string;
    };
}

export interface ZCCOutgoingMessage_MountPoint extends ZCCOutgoingMessage_Notification {
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyMountPoint_OBPreviewDialer;
        customerStatusContainer: React.Ref<any>;
        previewContactDetailsUrl: string;
    };
}

export interface ZCCOutgoingMessage_Notification_MuteMic extends ZCCOutgoingMessage_Notification {
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyMuteUnmuteMic;
        taskSid: string;
        isMute: boolean;
    };
}

export interface ZCCOutgoingMessage_Notification_NotifyZoomCallStatus extends ZCCOutgoingMessage_Notification {
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyZoomCallStatus;
        meetingStatus: EClientZmCallStatusType;
        phoneStatus: EClientZmCallStatusType;
    };
}

export interface ZCCOutgoingMessage_Notification_SetCCIAudioStatus extends ZCCOutgoingMessage_Notification {
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifySetCCIAudioStatus;
        audioStatus: 0 | 1; // 0: zcc need to stop use audio, 1: zcc can start to use audio again
    };
}

export interface ZCCOutgoingMessage_Notification_Destroy extends ZCCOutgoingMessage_Notification {
    result: {
        notification: ZCCOutgoingMessageNotification.PWA_CCIUINotify_Destroy;
        reason: string;
    };
}
export interface ZCCOutgoingMessage_Response_AVConflict extends ZCCOutgoingMessage_Response {
    result: {
        action: ENotifyAvConflictAction;
        taskSid: string;
    };
}

export interface ZCCOutgoingMessage_Response_GetAudioOccupancyStatus extends ZCCOutgoingMessage_Response {
    result: {
        status: EAudioOccupyStatus;
    };
}

export interface SearchBuddy {
    GetFirstName: string; // first name
    GetLastName: string; // last name
    GetScreenName: string; // display name
    GetEmail: string; // email
    GetBuddyType: number; // contact type
    cloudSIPCallNumber: {
        GetExtension: number; // extension number
        GetDirectNumber: Array<string>; // direct number
        GetCompanyNumber: string; // company number
    };
    IsReallyExternalContact: boolean; // TBD > false
    GetJid: string; // jid
    GetPictureURL: string; // avatar pic url
}

export interface ZCCOutgoingMessage_Notification_NotifySearchBuddy extends ZCCOutgoingMessage_Notification {
    jsCallId: '';
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifySearchBuddy;
        arrBuddyData: Array<SearchBuddy>;
        searchKey: string;
    };
}

export enum ZoomIMPresence {
    Offline = 0,
    Away = 1,
    DND = 2, //Do Not Disturb
    Online = 3,
    XA = 4,
    Busy = 5,
    OOO = 6, //Out of office
}

export enum ZoomIMPresenceStatus {
    NA = 0,
    ZoomMeeting = 1, //In a zoom meeting
    Meeting = 2, //In a meeting
    PBX = 3, //In a PBX call
    Presenting = 4, //Presenting
    Busy = 5,
    OOO = 6, // Out of office
}

export enum ResourceType {
    NOT_SET,
    DESKTOP,
    PHONE,
    PAD,
    PZR,
}

export interface IBuddyPresnece {
    jid: string;
    presence: ZoomIMPresence;
    presenceStatus: ZoomIMPresenceStatus;
    res_type: ResourceType;
}

type IBuddyPresneceUpdateResult = {
    notification: ZCCOutgoingMessageNotification.CCIUINotifyOnBuddyPresenceUpdate;
} & IBuddyPresnece;

export interface ZCCOutgoingMessage_Notification_BuddyPresenceUpdate extends ZCCOutgoingMessage_Notification {
    jsCallId: '';
    result: IBuddyPresneceUpdateResult;
}

export interface ZCCOutgoingMessage_Notification_GetPCIPalSecureStatus extends ZCCOutgoingMessage_Notification {
    jsCallId: '';
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyGetPCIPalSecureStatus;
        engagementId?: string;
    };
}

export interface ZCCOutgoingMessage_Notification_StartPCIPalMediaRedirection extends ZCCOutgoingMessage_Notification {
    jsCallId: '';
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyStartMediaRedirect;
        engagementId: string;
        linkId: string;
    };
}

export interface ZCCOutgoingMessage_Notification_ClientSettingsChange extends ZCCOutgoingMessage_Notification {
    jsCallId: '';
    result: {
        notification: ZCCOutgoingMessageNotification.CCIUINotifyClientSettingsChange;
        settings: Record<string, any>;
    };
}
