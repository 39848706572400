import serviceContainer from '../container';
import { isDisableInternalPresence } from '../../store/common/common-utils';
import { UserJid, ConnectStatus } from '@zoom/zpns-contact-presence';

const Max_Subs = 40;

enum SubKeys {
    sub5mins = 'sub5mins',
    sub10mins = 'sub10mins',
    sub3hours = 'sub3hours',
}

const subscriptions: Map<SubKeys, Array<UserJid>> = new Map();

const updateSubs = (key: SubKeys, added: Array<UserJid>) => {
    const list = subscriptions.get(key) || [];

    let newList: Array<UserJid> = [];

    list.forEach((v) => {
        if (!v) {
            return;
        }
        if (!added.find((w) => w === v)) {
            newList.push(v);
        }
    });

    newList = newList.concat(added).slice(Max_Subs * -1);

    subscriptions.set(key, newList);
};

const reSubAfterZpnsConnected = () => {
    const presenceManager = serviceContainer.getPresenceManager();

    for (const [sub, list] of subscriptions.entries()) {
        if (!list.length) {
            continue;
        }

        if ('sub5mins' === sub) {
            presenceManager.subscribePresenceFor5mins(list);
        }

        if ('sub10mins' === sub) {
            presenceManager.subscribePresenceFor10mins(list);
        }

        if ('sub3hours' === sub) {
            presenceManager.subscribePresenceFor3Hours(list);
        }
    }
};

let subscribedZpns = false;

const hanldeZpnsReconnect = () => {
    if (subscribedZpns) {
        return;
    }

    subscribedZpns = true;

    const eventBus = serviceContainer.getEventBus();
    eventBus.zpns.connectStatus.subscribe((status) => {
        if (ConnectStatus.Connected === status) {
            setTimeout(reSubAfterZpnsConnected, 1000);
        }
    });
};

export const subscribePresenceFor5mins = (jids: Array<UserJid>, force = false) => {
    if (isDisableInternalPresence()) {
        return;
    }

    hanldeZpnsReconnect();

    updateSubs(SubKeys.sub5mins, jids);

    const presenceManager = serviceContainer.getPresenceManager();
    return presenceManager.subscribePresenceFor5mins(jids, force);
};

export const subscribePresenceFor10mins = (jids: Array<UserJid>, force = false) => {
    if (isDisableInternalPresence()) {
        return;
    }

    hanldeZpnsReconnect();

    updateSubs(SubKeys.sub10mins, jids);

    const presenceManager = serviceContainer.getPresenceManager();
    return presenceManager.subscribePresenceFor10mins(jids, force);
};

export const subscribePresenceFor3Hours = (jids: Array<UserJid>, force = false) => {
    if (isDisableInternalPresence()) {
        return;
    }

    hanldeZpnsReconnect();

    updateSubs(SubKeys.sub3hours, jids);

    const presenceManager = serviceContainer.getPresenceManager();
    return presenceManager.subscribePresenceFor3Hours(jids, force);
};
