import React from 'react';
import { isTeslaMode } from '../utils';
import Pwa from './pwa/Pwa';
import Tesla from './tesla/Tesla';
import '@zoom/icons-react/es/index.css';
import { Modal } from 'Modal';
import ThankYou from '../features/ThankYou/Index';
import { useFeedback } from '../features/ThankYou/hooks/useFeedback';

// set for modal component
Modal.setAppElement('#root');

const App = () => {
    const feedback = useFeedback();

    if (isTeslaMode()) {
        return <Tesla />;
    }

    if (feedback.shouldShowThankYouPage) {
        return <ThankYou feedback={feedback?.feedbackModal} />;
    }

    return <Pwa />;
};

export default App;
