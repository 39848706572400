import React, { useState } from 'react';
import classnames from 'classnames';
import './ContactList.scss';
import { CONTACT_MEMBER_OPTIONS_FROM, GROUP_ID, IContact, JID } from '../../types';
import { getContactDisplayName, isGroupOrContactSelectedSelector } from '../../redux';
import { useAppSelector } from '../../../../store';
import Avatar from '../../../../components/Avatar/Avatar';
import { A11Y_POLICY } from '../../a11y';
import { LEVEL_F, NOT_SELECTED, CONTACT_INDEX_OF_F } from '../../../../resource';
import ContactMemberOperation from './ContactMemberOperation/ContactMemberOperation';

interface Props {
    style: React.CSSProperties;
    contact: IContact;
    setContact(jid: JID, groupId?: GROUP_ID): void;
    groupId?: GROUP_ID;
    a11yIndex?: string;
    a11yGroupIndex?: string;
    renderIndex?: number;
    contactInGroupIndex: number;
    groupTotal: number;
}

const Contact = ({
    style,
    contact,
    setContact,
    groupId = '',
    a11yIndex,
    a11yGroupIndex = '',
    renderIndex,
    contactInGroupIndex,
    groupTotal,
}: Props) => {
    const { jid, picUrl, email } = contact;
    const [showActions, setShowActions] = useState(false);
    const isSelected = useAppSelector((state) => isGroupOrContactSelectedSelector(state, jid, groupId));

    const displayName = getContactDisplayName(contact);

    const setCurrentContact = () => {
        setContact(contact.jid, groupId);
    };

    const getAriaLabelText = () => {
        const level = LEVEL_F(3);
        const selectText = isSelected ? '' : NOT_SELECTED;
        const positionText = CONTACT_INDEX_OF_F(contactInGroupIndex, groupTotal); // `${contactInGroupIndex} of ${groupTotal}`;

        return `${displayName}, ${level}, ${positionText}, ${selectText}`;
    };

    return (
        <div
            className="contacts-contact"
            style={style}
            onClick={setCurrentContact}
            role="treeitem"
            aria-label={getAriaLabelText()}
            aria-selected={isSelected}
            tabIndex={isSelected ? 0 : -1}
            data-a-render-index={renderIndex}
            data-a-own-group={a11yGroupIndex}
            data-a-l={a11yIndex}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_ITEM_POLICY}
        >
            <div className={classnames('contact-container', { 'contact-selected': isSelected })}>
                <Avatar extraClass="contact-avatar" picUrl={picUrl} jid={jid} displayName={displayName} />

                <div className="contact-middle-content">
                    <span className="display-name">{displayName}</span>
                </div>

                <div className={classnames('actions-btns', { 'show-actions': showActions })}>
                    <ContactMemberOperation
                        jid={jid}
                        groupId={groupId}
                        name={displayName}
                        email={email}
                        showActions={setShowActions}
                        from={CONTACT_MEMBER_OPTIONS_FROM.CONTACT}
                    />
                </div>
            </div>
        </div>
    );
};

export default Contact;
