import React from 'react';
import { selectContactByJid } from '../redux';
import { shallowEqual } from 'react-redux';
import '../../../assets/styles/main.scss';
import './ContactDetail.scss';
import { useAppSelector } from '../../../store';
import ContactHead from './ContactHead';
import ContactControl from './ContactControl';
import ContactInfoList from './ContactInfoList';
import { useContactProperty } from '../hooks/useContactProperty';

export default function ContactDetail() {
    const { selectedGroupId, selectedJid } = useAppSelector((state) => {
        return {
            selectedJid: state.contacts.selectedContact?.jid,
            selectedGroupId: state.contacts.selectedContact?.groupId,
        };
    }, shallowEqual);

    const currentContact = useAppSelector((state) => selectContactByJid(state, selectedJid));

    const contactProperty = useContactProperty({
        jid: selectedJid,
        groupdId: selectedGroupId,
    });

    return (
        <div className="contact-detail">
            <div className="contact-detail__content">
                <ContactHead contact={currentContact} {...contactProperty} />

                <ContactControl contact={currentContact} {...contactProperty} />

                <ContactInfoList contact={currentContact} {...contactProperty} />
            </div>
        </div>
    );
}
