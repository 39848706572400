/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event'

import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import './calendar.scss';
import { CALENDAR_APP_NAME, getCalendarUrl } from './constant';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../routes/routePath';
import { SOMETHING_WRONG } from '../../resource';
import { calendarAgent } from './CalendarAgent';
import { useAppSelector } from '../Phone/types';
import { Modal, ModalEvents } from 'Modal';
import Signal from '../../utils/Signal';

const pwaCalendarShadowRootDOMID = 'pwaCalendarShadowRoot';
const pwaCalendarRootInsideDOMID = 'pwaCalendarRootInside';
const pwaCalendarRootInsideDOMCls = 'hide-calendar-app'
const showPWACalendarDialogCls = 'show-pwa-calendar-dialog';

const getCalendarAppDom = () => {
    const calendarApp = document.body.getElementsByTagName('calendar-app')[0];
    const dom = calendarApp.shadowRoot.getElementById(pwaCalendarRootInsideDOMID);
    return dom;
}

export const calendarMountedSingal = new Signal('calendar_mounted');

class CalendarApp extends HTMLElement {
    private _calendarRoot_Inside_ShadowDOM: HTMLDivElement;
    constructor() {
        super();
        this.render();
    }
    render() {
        const shadowDOM = this.attachShadow({mode: 'open'});
        this._calendarRoot_Inside_ShadowDOM = this.createCalendarRootInsideShadowDOM();
        shadowDOM.appendChild(this._calendarRoot_Inside_ShadowDOM);
        this.renderMicroApp();
    }
    createCalendarRootInsideShadowDOM() {
        const _calendarRoot_Inside_ShadowDOM = document.createElement('div');
        _calendarRoot_Inside_ShadowDOM.id = pwaCalendarRootInsideDOMID;
        _calendarRoot_Inside_ShadowDOM.classList.add('pwa-calendar-root__inside');
        return _calendarRoot_Inside_ShadowDOM;
    }
    connectedCallback() {
        const isMatch = this.getAttribute('isMatch');
        
        if (isMatch === 'false') {
            this._calendarRoot_Inside_ShadowDOM.classList.add(pwaCalendarRootInsideDOMCls);
        } else {
            this._calendarRoot_Inside_ShadowDOM.classList.remove(pwaCalendarRootInsideDOMCls);
        }
    }

    renderMicroApp() {
        const calendarApp = <ErrorBoundary fallback={<div>{SOMETHING_WRONG}</div>} onError={(...args) => console.log('calendar error', args)} >
            <micro-app
                name={CALENDAR_APP_NAME}
                baseroute={`/wc/${CALENDAR_APP_NAME}`}
                url={getCalendarUrl()}
                onMounted={() => calendarMountedSingal.resolve(true)}
                inline
                disable-scopecss
                router-mode="pure"
            />
        </ErrorBoundary>
        createRoot(this._calendarRoot_Inside_ShadowDOM).render(calendarApp);
    }
}

customElements.define('calendar-app', CalendarApp);


const Calendar = () => {
    const matchCalendarRoute = useRouteMatch(RoutePath.Calendar);
    const showCalendarSchedule = useAppSelector(state => state.calendar.showCalendarSchedule);
    const showWebclient = useAppSelector(state => state.meeting.showWebclient);
    const [style, setStyle] = useState<React.CSSProperties>({
        display: 'block',
        flex: 'initial',
        width: '100%',
        position: 'relative'
    })

    useEffect(() => {
        const calendarApp = document.body.getElementsByTagName('calendar-app')[0];
        const style = document.createElement( 'style' )
        style.innerHTML = `
            .${pwaCalendarRootInsideDOMCls} micro-app-body > #app {
                display: none !important;
            }
            .pwa-calendar-root__inside {
                height: 100%;
            }
        `;
        calendarApp.shadowRoot.appendChild(style);
    }, [])

    // show calendar or not
    useEffect(() => {
        const calendarApp = getCalendarAppDom();
        if (calendarApp) {
            if (matchCalendarRoute) {
                calendarApp.classList.remove(pwaCalendarRootInsideDOMCls);
            } else {
                calendarApp.classList.add(pwaCalendarRootInsideDOMCls);
            }
        }
    }, [matchCalendarRoute])

    // show calendar dialog or not
    useEffect(() => {
        const calendarApp = getCalendarAppDom();
        if (calendarApp) {
            if (showCalendarSchedule) {
                calendarApp.classList.add(showPWACalendarDialogCls);
            } else {
                calendarApp.classList.remove(showPWACalendarDialogCls);
            }
        }
    }, [showCalendarSchedule])

    useEffect(() => {
        if (showWebclient) {
            return;
        }
        if (showCalendarSchedule) {
            Modal.emit(ModalEvents.BaseZIndexUpdate, { zindex: 3000 });
        } else {
            Modal.emit(ModalEvents.BaseZIndexUpdate, { zindex: 0 });
        }
    }, [showCalendarSchedule, showWebclient])

    useEffect(() => {
        calendarAgent.init();
        calendarAgent.calendarAppInitedPromise.then(() => {
            setStyle({
                ...style,
                position: 'initial'
            })
        })
        return () => {
            calendarAgent.uninit();
        };
    }, []);
    

    return <div style={style} data-main-body>
        {/* @ts-ignore */}
        <calendar-app id={pwaCalendarShadowRootDOMID} isMatch={matchCalendarRoute || showCalendarSchedule} />
    </div>
}

export default React.memo(Calendar);