import styles from './SharedCallsSwitchList.module.scss';
import { FC, useEffect, useRef } from 'react';
import React from 'react';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { RootState, useAppDispatch } from '../../../store';
import { connect } from 'react-redux';
import { Receive_Queue_Calls_Text, Receive_Shared_Calls_Text } from '../../../resource';
import Switch from 'react-bootstrap/Switch';
import { makeA11yListInfoInjector } from '../../../utils';
import { A11Y_FOCUS_WALKER_POLICY } from '../../../utils/constants';
import { IUserCallQueueEx } from '../../Phone/redux/phone-types';
import { getAsyncPhoneModule } from '../../../asyncModulesStore';

type TypeSharedCallsSwitchListProps = {
    containerDataAL: string;
    receiveQueueCalls: number;
    userCallQueueEx: IUserCallQueueEx;
};

const _SharedCallsSwitchList: FC<TypeSharedCallsSwitchListProps> = ({
    containerDataAL,
    receiveQueueCalls,
    userCallQueueEx,
}) => {
    const dispatch = useAppDispatch();
    const [showPopper, setShowPopper] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const containerRef = useRef(null);
    const { dataALGenerator } = makeA11yListInfoInjector(containerDataAL, containerRef);

    const {
        styles: popperStyles,
        attributes,
        update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
        placement: 'left-start',
        modifiers: [
            { name: 'flip' },
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    tether: false,
                },
            },
        ],
    });

    useEffect(() => {
        updatePopper?.();
    }, [receiveQueueCalls, userCallQueueEx?.userCallQueueMembers]);

    const handleReceiveQueueCallsChange = () => {
        getAsyncPhoneModule().then(
            ({ updateFeatureOptions, FEATURE_OPTIONS_CALL_QUEUE_STATUS, setReceiveQueueCalls }) => {
                updateFeatureOptions([
                    {
                        FLAG_OFFSET: FEATURE_OPTIONS_CALL_QUEUE_STATUS,
                        value: !+receiveQueueCalls,
                    },
                ])
                    .then((_res) => {
                        dispatch(setReceiveQueueCalls(!+receiveQueueCalls));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        );
    };

    const handleUserCQChange = (extensionId: string, userCallQueueId: string, isEnable: boolean) => {
        getAsyncPhoneModule().then(({ updateUserCallQueue }) => {
            updateUserCallQueue(extensionId, userCallQueueId, isEnable).catch((error) => {
                console.log(error);
            });
        });
    };

    const _content = (
        <div ref={containerRef} className="info-panel-block 666">
            <div className={`${styles.sharedCallsSwitchList__item}`}>
                <label htmlFor="switch__receive-queue-call" className="info-panel__switch-title">
                    {Receive_Queue_Calls_Text}:
                </label>
                <Switch
                    id="switch__receive-queue-call"
                    data-a-l={dataALGenerator()}
                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                    checked={!!+receiveQueueCalls}
                    onChange={handleReceiveQueueCallsChange}
                ></Switch>
            </div>
            {receiveQueueCalls && <div className="info-panel-divider"></div>}
            {receiveQueueCalls &&
                userCallQueueEx?.userCallQueueMembers.map((userCQ, index) => {
                    return (
                        <>
                            <div className={`${styles.sharedCallsSwitchList__item}`} key={userCQ.userCallQueueId}>
                                <label
                                    htmlFor={`switch__receive-userCQ-${userCQ.userCallQueueId}`}
                                    className="info-panel__switch-title"
                                >
                                    {userCQ.userCallQueueName}
                                </label>
                                <Switch
                                    id={`switch__receive-userCQ-${userCQ.userCallQueueId}`}
                                    data-a-l={dataALGenerator()}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                    checked={userCQ.enableReceiveCall}
                                    onChange={() =>
                                        handleUserCQChange(
                                            userCallQueueEx?.extensionId,
                                            userCQ.userCallQueueId,
                                            !userCQ.enableReceiveCall,
                                        )
                                    }
                                ></Switch>
                            </div>
                            {index !== userCallQueueEx?.userCallQueueMembers.length - 1 && (
                                <div className="info-panel-divider"></div>
                            )}
                        </>
                    );
                })}
        </div>
    );

    return (
        <div onMouseEnter={() => setShowPopper(true)} onMouseLeave={() => setShowPopper(false)}>
            <div className="info-panel-block" ref={setReferenceElement}>
                <button
                    data-a-l={containerDataAL}
                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                    onClick={() => setShowPopper(!showPopper)}
                >
                    {Receive_Shared_Calls_Text}
                </button>
            </div>
            {showPopper && (
                <div>
                    <div
                        className={styles.sharedCallsSwitchList}
                        ref={setPopperElement}
                        style={popperStyles.popper}
                        {...attributes.popper}
                    >
                        {_content}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        phone,
        contacts: { presences },
    } = state;
    return {
        receiveQueueCalls: phone?.receiveQueueCalls,
        userCallQueueEx: phone?.userSipInfo?.userCallQueueEx,
        presencesDuration: presences?.duration,
    };
};

export const SharedCallsSwitchList = connect(mapStateToProps)(_SharedCallsSwitchList);
