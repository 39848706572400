import React, { forwardRef } from 'react';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { CONTACT_MEMBER_OPTIONS_FROM, IContact_MemberOptions } from '../../../types';
import FarEndCameraControl from './OperationMenuItem/FarEndCameraControl';
import DeleteNormalContact from './OperationMenuItem/DeleteNormalContact';
import styles from './operation.module.scss';
import PersonalContactMenuItems from './OperationMenuItem/PersonalContactMenuItems';
import StarContactsMenuItem from './OperationMenuItem/StarContactsMenuItem';
interface Props extends OverlayInjectedProps {
    close(): void;
    ariaId: string;
    jid: string;
    groupId: string;
    name: string;
    email: string;

    from: CONTACT_MEMBER_OPTIONS_FROM;
    options: IContact_MemberOptions;
}

export default forwardRef<HTMLDivElement, Props>(function OperationMemberMenu(
    { close, ariaId, style, jid, email, groupId, from, name },
    ref,
) {
    const menuItems = [
        <StarContactsMenuItem groupId={groupId} jid={jid} closeMenu={close} key="star-contacts" />,
        <FarEndCameraControl
            groupId={groupId}
            jid={jid}
            email={email}
            from={from}
            closeMenu={close}
            name={name}
            key="camera-control"
        />,
        <DeleteNormalContact
            groupId={groupId}
            jid={jid}
            email={email}
            from={from}
            closeMenu={close}
            name={name}
            key="delete-normal-contact"
        />,
        <PersonalContactMenuItems
            groupId={groupId}
            jid={jid}
            email={email}
            from={from}
            name={name}
            closeMenu={close}
            key="personal-contact"
        />,
    ];

    const isEmpty = menuItems.filter(Boolean).length === 0;
    if (isEmpty) {
        return null;
    }

    return (
        <div ref={ref} style={style} id={ariaId} className={styles.menu}>
            {menuItems}
        </div>
    );
});
