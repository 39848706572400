import { useEffect } from 'react';
import serviceContainer from '../../services/container';
import { getUserInfoThunk } from '../../store/common/common-thunk';
import { setUserInfo } from '../../store/common/common-store';

export default () => {
    useEffect(() => {
        const eventBus = serviceContainer.getEventBus();
        const reduxStore = serviceContainer.getReduxStore();
        const subId = eventBus.zpns.message.subscribe((msg) => {
            if (msg.name === '/account/cmr/limit') {
                reduxStore.dispatch(getUserInfoThunk()).then((userInfo) => {
                    reduxStore.dispatch(setUserInfo(userInfo));
                });
            }
        });
        return () => {
            eventBus.zpns.message.unsubscribe(subId);
        };
    }, []);
};
