import React, { CSSProperties, PropsWithChildren } from 'react';
import styles from './dialog.module.scss';
import classNames from 'classnames';

type Props = PropsWithChildren<{
    className?: string;
    style?: CSSProperties;
}>;

const Dialog = ({ className, style, children }: Props) => {
    const cls = classNames(styles.dialog, {
        [className]: className,
    });

    return (
        <div className={cls} style={style}>
            {children}
        </div>
    );
};

export default Dialog;
