import React, { KeyboardEventHandler, useRef, useState } from 'react';
import { showModal } from '../../../../store/modal/modal-helper';
import { GIVE_FEEDBACK_BUTTON_TEXT, HELP_TEXT } from '../../../../resource';
import styles from './help.module.scss';
import { A11Y_FOCUS_WALKER_POLICY } from '../../../../utils/constants';
import { isTeslaMode, makeA11yListInfoInjector } from '../../../../utils';
interface Props {
    containerDataAL: string;
    hideMenu(): void;
}

const HelpItem = ({ containerDataAL, hideMenu }: Props) => {
    const [showMenu, setShowMenu] = useState(false);

    const containerRef = useRef(null);
    const { dataALGenerator } = makeA11yListInfoInjector(containerDataAL, containerRef);

    if (isTeslaMode()) {
        return null;
    }

    const showGiveFeedbackModal = () => {
        showModal('giveFeedback');
        hideMenu();
    };

    const handleESCPressed: KeyboardEventHandler = (e) => {
        if (e.key === 'Escape') {
            setShowMenu(false);
            e.stopPropagation();
        }
    };

    const MenuSection = (
        <div className={styles.menuContainer} ref={containerRef} onKeyDown={handleESCPressed} role="presentation">
            <button
                onClick={showGiveFeedbackModal}
                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                data-a-l={dataALGenerator()}
            >
                {GIVE_FEEDBACK_BUTTON_TEXT}
            </button>
        </div>
    );

    return (
        <div className={styles.helpBtn} onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
            <button data-a-l={containerDataAL} onClick={() => setShowMenu(true)}>
                {HELP_TEXT}
            </button>
            {showMenu ? MenuSection : null}
        </div>
    );
};

export default HelpItem;
