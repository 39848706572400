import { createSlice } from '@reduxjs/toolkit';

export interface ICalendarState {
    showCalendarSchedule: boolean;
    showCalendarSidebar: boolean;
}

const initialState: ICalendarState = {
    showCalendarSchedule: false,
    showCalendarSidebar: false,
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setShowCalendarSchedule(state, { payload }) {
            state.showCalendarSchedule = payload;
        },
        setShowCalendarSidebar(state, { payload }) {
            state.showCalendarSidebar = payload;
        },
    },
});

export const { setShowCalendarSchedule, setShowCalendarSidebar } = calendarSlice.actions;

export default calendarSlice.reducer;
