import React from 'react';
import { useAppSelector } from '../../../../store';
import { IContactRender, selectContactByJid } from '../../redux';
import { JID } from '../../types';
import Contact from './Contact';
import PendingContact from './PendingContact';
import { isPendingContactJid, isPersonalContactGroup } from '../../utils/stringUtils';
import { featureOptionManager } from '../../../../app-init';
import { formatContactGroupByIdSelector } from '../../redux';
import PersonalContact from './PersonalContact';
interface Props {
    style: React.CSSProperties;
    contact: IContactRender;
    setContact(jid: JID): void;
    a11yIndex?: string;
    a11yGroupIndex?: string;
    renderIndex?: number;
}

export default function ContactWrapper({ contact, ...rest }: Props) {
    const { jid, groupId, contactInGroupIndex } = contact;
    const contactData = useAppSelector((state) => selectContactByJid(state, jid));
    const { total } = useAppSelector((state) => formatContactGroupByIdSelector(state, groupId));
    if (!contactData) {
        return null;
    }
    const isPersonalContact = isPersonalContactGroup(groupId);

    if (isPendingContactJid(jid) && featureOptionManager.isInviteContactOn()) {
        return (
            <PendingContact
                contact={contactData}
                {...rest}
                groupId={groupId}
                contactInGroupIndex={contactInGroupIndex}
                groupTotal={total}
            />
        );
    }

    if (isPersonalContact) {
        return (
            <PersonalContact
                contact={contactData}
                {...rest}
                groupId={groupId}
                contactInGroupIndex={contactInGroupIndex}
                groupTotal={total}
            />
        );
    }

    return (
        <Contact
            contact={contactData}
            {...rest}
            groupId={groupId}
            contactInGroupIndex={contactInGroupIndex}
            groupTotal={total}
        />
    );
}
