import React from 'react';
import { useAppSelector } from '../../Phone/types';
import { isPendingContactJid } from '../utils/stringUtils';
import { CONTACTS_NO_CONTACT_SELECTED } from '../../../resource';
import ContactDetail from './ContactDetail';

const ContactDetailWrapper = () => {
    const selectedContact = useAppSelector((state) => state.contacts.selectedContact);

    if (!selectedContact?.jid || isPendingContactJid(selectedContact.jid)) {
        return (
            <div className="contact-detail">
                <div className="contact-detail__empty" tabIndex={0}>
                    <i></i>
                    <p className="contact-detail__empty-txt">{CONTACTS_NO_CONTACT_SELECTED}</p>
                </div>
            </div>
        );
    }

    return <ContactDetail />;
};

export default ContactDetailWrapper;
