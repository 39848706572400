import React, { forwardRef } from 'react';
import { isExternalGroup, isPendingContactJid } from '../../../../utils/stringUtils';
import { CONTACT_MEMBER_OPTIONS_FROM } from '../../../../types';
import { featureOptionManager } from '../../../../../../app-init';
import styles from '../operation.module.scss';
import { DELETE_CONTACT } from '../../../../../../resource';
import { deletePendingContactsThunk } from '../../../../redux/contact-thunk';
import { setModal } from '../../../../../../store/modal/modal-store';
import { InviteContactModalType } from '../../../../../ModalContainer/InviteContactModal/types';
import { useAppDispatch } from '../../../../../../store/store-hooks';
import classNames from 'classnames';
import { OperationMenuItemProps } from '../types';

// delete contact, just external group contact or pending contact display
const getShowDeleteContact = (from: CONTACT_MEMBER_OPTIONS_FROM, groupId: string) => {
    const isFromPendingContact = from === CONTACT_MEMBER_OPTIONS_FROM.PENDIND_CONTACT;
    return (
        (isExternalGroup(groupId) &&
            featureOptionManager.isInviteContactOn() &&
            from === CONTACT_MEMBER_OPTIONS_FROM.CONTACT) ||
        isFromPendingContact
    );
};

export default forwardRef<HTMLButtonElement, OperationMenuItemProps>(function DeleteNormalContact(
    { groupId, email, jid, name, from, closeMenu },
    ref,
) {
    const dispatch = useAppDispatch();
    const showDeleteContact = getShowDeleteContact(from, groupId);
    const isPendingContact = isPendingContactJid(jid);

    if (!showDeleteContact) {
        return null;
    }

    return (
        <button
            ref={ref}
            className={classNames(styles.menuItem, isPendingContact ? '' : styles.dangerous)}
            onClick={(e) => {
                closeMenu();
                if (isPendingContact && email) {
                    dispatch(deletePendingContactsThunk(email));
                } else {
                    dispatch(
                        setModal({
                            name: 'inviteContactModal',
                            data: {
                                show: true,
                                data: { jid, name, modalType: InviteContactModalType.DELETE_CONTACT },
                            },
                        }),
                    );
                }

                // keyboard click
                // https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
                if (e.detail === 0) {
                    // focus on list after delete contact
                    const contactListWrapper = document.getElementById('contactListWrapper');
                    contactListWrapper && contactListWrapper.focus();
                }
            }}
        >
            {DELETE_CONTACT}
        </button>
    );
});
