import { isInStandaloneMode, isTeslaMode } from '../../utils';
import { onLoadLogger, uploadOnloadLogs } from '../pwa-loggers';

export const logOnLoad = () => {
    const urlObj = new URL(window.location.href);
    const params = urlObj.searchParams;

    /**
     * sanitize url search params to hide user's token, pwd,  etc.
     */
    const whiteKeys: { [key: string]: boolean | { mask: boolean } } = {
        fromPWA: true,
        webp: true, // load pwa from web portal dropdown list
        mn: {
            mask: true, // launch pwa to join meeting
        },
        pwd: {
            mask: true,
        },
        tel: {
            mask: true, // registerProtocolHandler
        },
    };

    const paramsKeys = Array.from(params.keys());
    // this will be wrong !!!
    // when you get next element of this iterator after you deleted one from it
    // some elements may be skipped!!!!!!
    // for (const paramName of params.keys()) { xxxx }
    for (const paramName of paramsKeys) {
        if (paramName in whiteKeys) {
            const config = whiteKeys[paramName];
            if (typeof config === 'object') {
                if (config.mask) {
                    params.set(paramName, '******');
                }
            }
        } else {
            params.delete(paramName);
        }
    }

    /**
     * sanitize path to hide user's meeting number
     */
    const path = urlObj.pathname;

    if (path.includes('/join') || path.includes('/start')) {
        urlObj.pathname = path.replace(/\/(\d+)/, '/******');
    }

    if (path.includes('/my/')) {
        const p = path.split('/');
        p.pop();
        p.push('******');
        urlObj.pathname = p.join('/');
    }

    const logInfo = {
        ua: window.navigator.userAgent,
        url: urlObj.toString(),
        uid: window.PwaConfig.uid || '---none---',
        isStandalone: isInStandaloneMode(),
        isTesla: isTeslaMode(),
    };

    const logAndUpload = () => {
        onLoadLogger.log(logInfo).then(() => {
            uploadOnloadLogs();
        });
    };

    if (window.requestIdleCallback) {
        window.requestIdleCallback(logAndUpload);
    } else {
        logAndUpload();
    }
};
