import microApp from '@micro-zoe/micro-app';
import {
    ZCCInComingMessageFunctionName,
    ZCCIncomingMessage,
    EClientBtnActionType,
    WithMsgId,
    ZCCIncomingMessage_IncomingCall,
    ZCCIncomingMessage_UpdateIncomingCall,
    ZCCOutgoingMessageNotification,
    ZCCIncomingMessage_CancelIncomingCall,
    ZCCIncomingMessage_LogMessage,
    ZCCIncomingMessage_PageLoaded,
    ZCCIncomingMessage_SessionExpired,
    ZCCIncomingMessage_CallStartAndUpdate,
    ZCCIncomingMessage_CallEnd,
    ZCCIncomingMessage_GetZoomCallStatus,
    ZCCIncomingMessage_GetAudioOccupancyStatus,
    ZCCIncomingMessage_StartOutboundAudio,
    ZCCIncomingMessage_CloseOutboundAudio,
    ENotifyAvConflictAction,
    ZCCOutgoingMessage_Notification_NotifyZoomCallStatus,
    ZCCOutgoingMessage_Notification_MuteMic,
    ZCCOutgoingMessage_Notification_CallAction,
    ZCCOutgoingMessage_Response_ACK,
    ZCCOutgoingMessage,
    ZCCOutgoingMessage_Response_AVConflict,
    ZCCOutgoingMessage_Response_GetAudioOccupancyStatus,
    EClientZmCallStatusType,
    ZCCIncomingMessage_PresenceStatusSync,
    ZCCIncomingMessage_UseAudio,
    ZCCOutgoingMessage_Notification_SetCCIAudioStatus,
    ZCCIncomingMessage_GetUserProfile,
    ZCCOutgoingMessage_Response_GetUserProfile,
    ZCCOutgoingMessage_Notification_Destroy,
    ZCCIncomingMessage_SearchBuddy,
    ZCCIncomingMessage_SubscribeBuddyPresence,
    ZCCIncomingMessage_ClearPresenceSubscribe,
    ZCCOutgoingMessage_Notification_NotifySearchBuddy,
    IBuddyPresnece,
    ZCCOutgoingMessage_Notification_BuddyPresenceUpdate,
    ZCCOutgoingMessage_MountPoint,
    ZCCIncomingMessage_IncomingCall_OBPreviewDialer,
    ZCCIncomingMessage_UpdateIncomingCall_OBPreviewDialer,
    ZCCIncomingMessage_CancelIncomingCall_OBPreviewDialer,
    ZCCIncomingMessage_IncomingCall_ClickToCall,
    ZCCIncomingMessage_GetClientSettings,
    ZCCOutgoingMessage_Notification_ClientSettingsChange,
} from '../types';
import store from '../../../store';
import { v4 as uuidv4 } from 'uuid';
import {
    removeIncomingCall,
    setCurrentCall,
    updateIncomingCall,
    addIncomingCall,
    stopPlayToneForThisTaskType,
    clearAllIncomingCalls,
    actions,
    addIncomingCall_OBPreviewDialer,
    stopPlayToneOfOBPreview_Dialer_ForThisTaskType,
    clearAllIncomingCalls_OBPreviewDialer,
    updateIncomingCall_OBPreviewDialer,
    removeIncomingCall_OBPreviewDialer,
} from '../redux';

import { setModal } from '../../../store/modal/modal-store';
import { getZoomMeetingStatus, getZoomPhoneStatus, isAudiOccupiedByZoom } from '../otherModuleUtils';
import { isArray, isUndefined } from 'lodash-es';
import { setSuppressMeetingCall } from '../../Meeting/redux';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import { CONTACT_CENTER_NAME } from '../../../store/appModules/contact-center/config';
import { getLanguageInCookie } from '../../../utils/Cookies/utils';
import { contactCenterAgentMessageDecorator } from './messageHandlerDecorator';
import {
    ISearchContactResponse,
    ISearchContactResultItem,
    createSearchContact,
} from '../../../services/http/searchContact';
import PresenceSubscriber from './presence-subscriber/PresenceSubscriber';
import { userSessionLogger } from '../../../logger/pwa-loggers';
import { zccNotificationSettingSelector } from '../redux/zcc-selectors';

const { searchContact } = createSearchContact();
const { onReceiveMessage, handleMessage } = contactCenterAgentMessageDecorator;

export default class ContactCenterAgent {
    private presenceSubscriber: PresenceSubscriber;
    private isReady = false;
    private messageQueue: Array<ZCCOutgoingMessage & WithMsgId>;

    constructor() {
        // no longer used for now
        this.isReady = false;
        this.messageQueue = [];
        this.presenceSubscriber = null;
        this.onMessage = this.onMessage.bind(this);
    }

    private flushMessageQueue() {
        while (this.messageQueue.length) {
            const data = this.messageQueue.shift();
            console.log('[contact center ====>] ', data);
            microApp.setData(CONTACT_CENTER_NAME, data as any);
        }
    }

    init() {
        microApp.addDataListener(CONTACT_CENTER_NAME, this.onMessage);
    }

    uninit() {
        microApp.removeDataListener(CONTACT_CENTER_NAME, this.onMessage);
    }

    patchUUID(data: ZCCOutgoingMessage & Partial<WithMsgId>): ZCCOutgoingMessage & WithMsgId {
        if ('msgId' in data) {
            return data as ZCCOutgoingMessage & WithMsgId;
        } else {
            return Object.assign({ msgId: uuidv4() }, data);
        }
    }

    postMessage(data: ZCCOutgoingMessage & Partial<WithMsgId>) {
        const patchedData = this.patchUUID(data);

        console.log('isReady', this.isReady);
        this.messageQueue.push(patchedData);

        this.flushMessageQueue();

        return patchedData.msgId;
    }

    @onReceiveMessage
    onMessage(message: ZCCIncomingMessage) {
        console.log('[contact center <<<====]', message);
        return message.functionName;
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_GetUserProfile)
    onReceiveGetUserProfile(message: ZCCIncomingMessage_GetUserProfile) {
        const { jsCallId } = message;
        const response: ZCCOutgoingMessage_Response_GetUserProfile = {
            jsCallId,
            returnCode: 0,
            result: {
                clientTheme: 'light',
                languageID: getLanguageInCookie()?.key,
            },
        };
        this.postMessage(response);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_LogMessage)
    onReceiveLogMessage(message: ZCCIncomingMessage_LogMessage) {
        this.isReady = true;
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.PWA_CCIUIAPI_PAGE_LOADED)
    onPageLoaded(message: ZCCIncomingMessage_PageLoaded) {
        this.isReady = true;
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.PWA_CCIUIAPI_SESSION_EXPIRED)
    onSessionExpired(message: ZCCIncomingMessage_SessionExpired) {
        userSessionLogger.log('', ['Session_Expire', 'From_ZCC']);
        this.ackMessage(message);
        store.dispatch(signoutWhenSeesionExpiredThunk());
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_CancelIncomingCall)
    onCancelIncomingCall(message: ZCCIncomingMessage_CancelIncomingCall) {
        const {
            params: { taskSid },
        } = message;
        this.ackMessage(message);
        store.dispatch(removeIncomingCall({ taskSid }));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_CancelIncomingCall_OBPreviewDialer)
    onCancelIncomingCall_OBPreviewDialer(message: ZCCIncomingMessage_CancelIncomingCall_OBPreviewDialer) {
        const {
            params: { taskSid },
        } = message;
        this.ackMessage(message);
        if (!Boolean(taskSid)) {
            store.dispatch(clearAllIncomingCalls_OBPreviewDialer());
            return;
        }

        store.dispatch(removeIncomingCall_OBPreviewDialer({ taskSid }));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_IncomingCall)
    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_IncomingCall_ClickToCall)
    onReceiveIncomingCall(message: ZCCIncomingMessage_IncomingCall | ZCCIncomingMessage_IncomingCall_ClickToCall) {
        const { jsCallId, params } = message;
        const { taskSid, taskType } = params;
        this.ackMessage(message);

        if (Boolean(taskType) && Boolean(taskSid)) {
            // add new one
            store.dispatch(
                addIncomingCall({
                    jsCallId,
                    canPlayRingTone: 'isPlayRingtone' in params ? params.isPlayRingtone : true,
                    ...params,
                }),
            );
        }

        if (Boolean(taskType) && !Boolean(taskSid)) {
            // stop ringing for taskType eg. 'phone'
            store.dispatch(stopPlayToneForThisTaskType({ taskType }));
        }

        if (!Boolean(taskType) && !Boolean(taskSid)) {
            store.dispatch(clearAllIncomingCalls());
        }
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_IncomingCall_OBPreviewDialer)
    onReceiveIncomingCall_OBPreviewDialer(message: ZCCIncomingMessage_IncomingCall_OBPreviewDialer) {
        const { jsCallId, params } = message;
        const { taskSid, taskType } = params;
        this.ackMessage(message);

        if (Boolean(taskType) && Boolean(taskSid)) {
            // add new one
            store.dispatch(
                addIncomingCall_OBPreviewDialer({
                    jsCallId,
                    canPlayRingTone: true,
                    ...params,
                }),
            );
        }

        if (Boolean(taskType) && !Boolean(taskSid)) {
            // stop ringing for taskType eg. 'phone'
            store.dispatch(stopPlayToneOfOBPreview_Dialer_ForThisTaskType({ taskType: 'phone' }));
        }

        if (!Boolean(taskType) && !Boolean(taskSid)) {
            store.dispatch(clearAllIncomingCalls_OBPreviewDialer());
        }
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_UpdateIncomingCall)
    onUpdateIncomingCall(message: ZCCIncomingMessage_UpdateIncomingCall) {
        const { jsCallId, params } = message;
        this.ackMessage(message);
        store.dispatch(updateIncomingCall({ jsCallId, ...params }));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_UpdateIncomingCall_OBPreviewDialer)
    onUpdateIncomingCall_OBPreviewDialer(message: ZCCIncomingMessage_UpdateIncomingCall_OBPreviewDialer) {
        const { jsCallId, params } = message;
        this.ackMessage(message);
        store.dispatch(updateIncomingCall_OBPreviewDialer({ jsCallId, ...params }));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_OnCallStart)
    onCallStart(message: ZCCIncomingMessage_CallStartAndUpdate) {
        this.ackMessage(message);
        store.dispatch(setCurrentCall(message.params));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_OnCallUpdate)
    onCallUpdate(message: ZCCIncomingMessage_CallStartAndUpdate) {
        this.ackMessage(message);
        store.dispatch(setCurrentCall(message.params));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_OnCallEnd)
    onCallEnd(message: ZCCIncomingMessage_CallEnd) {
        this.ackMessage(message);
        store.dispatch(setCurrentCall(null));
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_GetZoomCallStatus)
    onGetZoomCallStatus(message: ZCCIncomingMessage_GetZoomCallStatus) {
        const { jsCallId } = message;

        const response = {
            jsCallId,
            returnCode: 0,
            result: {
                meetingStatus: getZoomMeetingStatus(store),
                phoneStatus: getZoomPhoneStatus(store),
            },
        };

        this.postMessage(response);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_GetAudioOccupancyStatus)
    onGetAudioOccupancyStatus(message: ZCCIncomingMessage_GetAudioOccupancyStatus) {
        const { jsCallId } = message;

        const response: ZCCOutgoingMessage_Response_GetAudioOccupancyStatus = {
            jsCallId,
            result: { status: isAudiOccupiedByZoom(store) },
            returnCode: 0,
        };

        this.postMessage(response);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_StartOutboundAudio)
    onStartOutboundAudio(message: ZCCIncomingMessage_StartOutboundAudio) {
        const { jsCallId } = message;
        // do not ack message, we responds after user make a choice
        store.dispatch(
            setModal({
                name: 'avConflict',
                data: {
                    jsCallId,
                    requester: 'zcc',
                    ...message.params,
                },
            }),
        );
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_CloseOutboundAudio)
    onCloseOutboundAudio(message: ZCCIncomingMessage_CloseOutboundAudio) {
        this.ackMessage(message);
        store.dispatch(
            setModal({
                name: 'avConflict',
                data: null,
            }),
        );
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_PresenceStatusSync)
    onPresenceStatusSync(message: ZCCIncomingMessage_PresenceStatusSync) {
        this.ackMessage(message);
        const { suppressZoomCall } = message.params || {};
        store.dispatch(setSuppressMeetingCall(suppressZoomCall));
        // TODO
        // it seems like, zcc's video and voice status is synced from xmpp automatically
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_UseAudio)
    onUseAudio(message: ZCCIncomingMessage_UseAudio) {
        this.ackMessage(message);
        store.dispatch(actions.setAudioStatus(message.params));
        // TODO what does this messae do?????
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_SearchBuddy)
    async onSearchBuddy(message: ZCCIncomingMessage_SearchBuddy) {
        const {
            params: { searchKey, contactType },
        } = message;

        this.ackMessage(message);
        try {
            const result = await searchContact({ key: searchKey, contactType });
            return this.notifyZCCWhenBuddyUpdate(result);
        } catch (_e) {
            const response: ZCCOutgoingMessage_Notification_NotifySearchBuddy = {
                jsCallId: '',
                result: {
                    notification: ZCCOutgoingMessageNotification.CCIUINotifySearchBuddy,
                    arrBuddyData: [],
                    searchKey,
                },
                returnCode: -1,
            };
            this.postMessage(response);
        }
    }

    notifyZCCWhenBuddyUpdate({ contacts, key }: ISearchContactResponse) {
        const transformContact = (contact: ISearchContactResultItem) => {
            const item = {
                GetFirstName: contact.firstName,
                GetLastName: contact.lastName,
                GetScreenName: contact.displayName,
                GetEmail: contact.snsEmail,
                GetBuddyType: contact.contactType,
                cloudSIPCallNumber: {
                    GetExtension: contact?.pbx.ext,
                    GetDirectNumber: contact?.pbx.dn ?? [],
                    GetCompanyNumber: contact?.pbx.cn,
                },
                IsReallyExternalContact: !contact.isSameAccount,
                GetJid: contact.jid,
                GetPictureURL: contact.picUrl,
            };
            return item;
        };

        const result = contacts.map(transformContact);

        const response: ZCCOutgoingMessage_Notification_NotifySearchBuddy = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifySearchBuddy,
                arrBuddyData: result,
                searchKey: key,
            },
            returnCode: 0,
        };

        this.postMessage(response);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_SubscribeBuddyPresence)
    onSubscribeBuddyPresence(message: ZCCIncomingMessage_SubscribeBuddyPresence) {
        const {
            params: { jids },
        } = message;
        if (!this.presenceSubscriber) {
            this.presenceSubscriber = new PresenceSubscriber({
                notifyBuddyPresneceChange: this.notifyBuddyPresneceChange,
            });
        }
        this.presenceSubscriber.subscribe(jids);
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_ClearPresenceSubscribe)
    onClearSubscribeBuddyPresnece(message: ZCCIncomingMessage_ClearPresenceSubscribe) {
        if (this.presenceSubscriber) {
            this.presenceSubscriber.clearSubscribe();
        }
        this.ackMessage(message);
    }

    @handleMessage(ZCCInComingMessageFunctionName.CCIUIAPI_GetClientSettings)
    onGetClientSettings(message: ZCCIncomingMessage_GetClientSettings) {
        const { jsCallId } = message;
        const requestedSettings = message.params?.settings || [];
        const settings: Record<string, any> = {};
        requestedSettings.forEach((setting) => {
            if (setting === 'msgNotifyAudioSetting') {
                settings[setting] = String(zccNotificationSettingSelector(store.getState()));
            }
        });
        const response = {
            jsCallId,
            returnCode: 0,
            result: { settings },
        };
        this.postMessage(response);
    }

    notifyCCIClientSettingsChange(settings: Record<string, any>) {
        const response: ZCCOutgoingMessage_Notification_ClientSettingsChange = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifyClientSettingsChange,
                settings,
            },
        };
        this.postMessage(response);
    }

    // you receive a message, and you tell him you received
    ackMessage(message: ZCCIncomingMessage, returnCode = 0) {
        console.log('contact center ackMessage', message.functionName);
        const { jsCallId } = message;
        const ack: ZCCOutgoingMessage_Response_ACK = {
            jsCallId,
            result: null,
            returnCode,
        };
        this.postMessage(ack);
    }

    // phone, video, chat, sms
    acceptIncomingCall({ taskSid }: { taskSid: string }) {
        const notification: ZCCOutgoingMessage_Notification_CallAction = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifCallStatus,
                taskSid,
                status: EClientBtnActionType.CCICallAcceptStatus_Accepted,
            },
        };
        this.postMessage(notification);
    }

    acceptIncomingCall_OBPreviewDialer({ taskSid }: { taskSid: string }) {
        const notification: ZCCOutgoingMessage_Notification_CallAction = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifCallStatus,
                taskSid,
                status: EClientBtnActionType.CCICallAcceptStatus_Accepted,
            },
        };
        this.postMessage(notification);
    }

    skipIncomingCall_OBPreviewDialer({ taskSid }: { taskSid: string }, skipReasonId: string) {
        const notification: ZCCOutgoingMessage_Notification_CallAction = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifCallStatus,
                taskSid,
                status: EClientBtnActionType.CCICallAcceptStatus_Skiped,
                skipReasonId,
            },
        };
        this.postMessage(notification);
    }

    sendMountPoint_OBPreviewDialer(dom: React.Ref<any>, previewContactDetailsUrl: string) {
        const notification: ZCCOutgoingMessage_MountPoint = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifyMountPoint_OBPreviewDialer,
                customerStatusContainer: dom,
                previewContactDetailsUrl,
            },
        };
        this.postMessage(notification);
    }

    // phone, video, chat, sms
    rejectIncomingCall({ taskSid }: { taskSid: string }) {
        const notification: ZCCOutgoingMessage_Notification_CallAction = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifCallStatus,
                taskSid,
                status: EClientBtnActionType.CCICallAcceptStatus_Rejected,
            },
        };
        this.postMessage(notification);
    }

    // phone, video, chat, sms
    ignoreIncomingCall({ taskSid }: { taskSid: string }) {
        const notification: ZCCOutgoingMessage_Notification_CallAction = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifCallStatus,
                taskSid,
                status: EClientBtnActionType.CCICallAcceptStatus_Ignore,
            },
        };
        this.postMessage(notification);
    }

    muteCall({ taskSid, mute }: { taskSid: string; mute: boolean }) {
        const notification: ZCCOutgoingMessage_Notification_MuteMic = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifyMuteUnmuteMic,
                taskSid,
                isMute: mute,
            },
        };
        this.postMessage(notification);
    }

    /**
     * when zcc wants to make a video/voice call, he sends a message(CCIUIAPI_GetAudioOccupancyStatus).
     * then we return back pwa's zoom meeting and zoom phone status.
     * if audio is occupied by zoom meeting/phone,
     * then zcc sends another message(CCIUIAPI_StartOutboundAudio) to let us notify user to make a choice.
     * we will get CCIUIAPI_CloseOutboundAudio, when zcc drop that call request and before user give his choice
     * @param willContinue
     * @param data
     */
    notifyAVconflictResult(willContinue: boolean, data: { jsCallId: string; taskSid: string }) {
        const { jsCallId, taskSid } = data;

        const response: ZCCOutgoingMessage_Response_AVConflict = {
            jsCallId,
            result: {
                action: willContinue ? ENotifyAvConflictAction.Continue : ENotifyAvConflictAction.Cancel,
                taskSid,
            },
            returnCode: 0,
        };
        this.postMessage(response);
        store.dispatch(
            setModal({
                name: 'avConflict',
                data: null,
            }),
        );
    }

    /**
     * this happens when we hava new zoom meeting or zoom phone
     */
    notifyZoomCallStatus(props?: { meetingStatus?: EClientZmCallStatusType; phoneStatus?: EClientZmCallStatusType }) {
        const meetingStatus = isUndefined(props?.meetingStatus) ? getZoomMeetingStatus(store) : props.meetingStatus;
        const phoneStatus = isUndefined(props?.phoneStatus) ? getZoomMeetingStatus(store) : props.phoneStatus;

        const notification: ZCCOutgoingMessage_Notification_NotifyZoomCallStatus = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifyZoomCallStatus,
                meetingStatus,
                phoneStatus,
            },
        };
        this.postMessage(notification);
    }

    notifyToStopUsingAudio(stop = true) {
        const notification: ZCCOutgoingMessage_Notification_SetCCIAudioStatus = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.CCIUINotifySetCCIAudioStatus,
                audioStatus: stop ? 0 : 1,
            },
        };

        this.postMessage(notification);
    }

    notifyToDestroy(reason: string) {
        const notification: ZCCOutgoingMessage_Notification_Destroy = {
            jsCallId: '',
            result: {
                notification: ZCCOutgoingMessageNotification.PWA_CCIUINotify_Destroy,
                reason,
            },
        };
        this.postMessage(notification);
    }

    // arrow function, with 'this' bound to contactCenterAgent
    notifyBuddyPresneceChange = (presenceList: Array<IBuddyPresnece>) => {
        const handler = (presence: IBuddyPresnece) => {
            const notification: ZCCOutgoingMessage_Notification_BuddyPresenceUpdate = {
                jsCallId: '',
                result: {
                    notification: ZCCOutgoingMessageNotification.CCIUINotifyOnBuddyPresenceUpdate,
                    ...presence,
                },
            };
            this.postMessage(notification);
        };

        if (isArray(presenceList)) {
            presenceList.forEach(handler);
        }
    };
}
