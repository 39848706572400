import React, { createRef, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { useLocation, useHistory } from 'react-router-dom';
import { A11Y_FOCUS_WALKER_POLICY, headerTabType } from '../../../utils/constants';
import { isTeslaMode, makeA11yListInfoInjector } from '../../../utils';
import { useAppSelector } from '../../../store/store-hooks';
import { REMOVE_PINNED_TEXT, ADD_PINNED_TEXT } from '../../../resource';
import { IconEllipsisHorizontalSmall, IconPinUnpinFill, IconPinFill } from '@zoom/icons-react';
import { keyBoardEventHandler } from '../../../utils/keyboard-event';
import { withFocusWalker } from '../../../hoc/withFocusWalker';
import { ChatPopover } from '../../Chat';
import { shallowEqual } from 'react-redux';
import { RootState } from '../../../store';
import HeaderMoreTab from './HeaderMoreTab';
import _ from 'lodash-es';
import { KEYBOARD_INSTRUCTIONS_NAVBAR, More_Button_Text } from '../../Phone/LanguageResource';
import { OverlayTrigger } from 'react-bootstrap';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { Button } from '@zoom/zoom-react-ui';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import useTabList from '../../../hooks/useTabList';
import { canWeLoadZoomPhone } from '../../../store/appModules/app-modules-selector';
import classNames from 'classnames';

const TAB_WIDTH = 80;
const HEADER_TAB_MARGIN = 5;

const selectPhoneData = (state: RootState) => {
    const phone = state.phone;
    const smsUnreadCount = state.sms?.chats?.ids?.reduce(
        (previousValue: number, currentValue: string) =>
            previousValue + state.sms?.chats?.entities[currentValue].unreadCount,
        0,
    );
    return {
        NESEnabled: phone?.e911?.NESEnabled,
        allowAccessLocation: phone?.e911?.allowAccessLocation,
        voicemailUnreadCount: phone?.voicemailUnreadCount,
        smsUnreadCount,
        callLogUnreadCount: phone?.callLogUnreadCount,
        isE911NotOk: !!phone?.e911?.e911IconClassName,
    };
};
type headRefTypes = {
    midChildrenWidths: { width: number; type: string }[];
    tabsInMore: string[];
    sizeObserver: any;
};

const HeaderTabs = () => {
    const [morePanelStatus, setMorePanelStatus] = useState(false);
    const [activeElement, setActiveElement] = useState('');

    const [showPinUnpin, setShowPinUnpin] = useState('');
    const [initialTab, setInitialTab] = useState(-1);
    const [lastTab, setLastTab] = useState(-1);
    const [isDragging, setIsDragging] = useState(false);

    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    const { data: organizableTabList, setData: setOrganizableTabList } = useTabList();
    const { shown } = useAppSelector((state) => state.chat.unreadCount, shallowEqual);
    const [tabsInMore, setTabsInMore] = useState<string[]>([]);

    const showPhoneIcon = useAppSelector(canWeLoadZoomPhone);

    const history = useHistory();
    const { pathname } = useLocation();

    const containerRef = useRef<HTMLDivElement>(null);
    const outerRef = useRef<HTMLDivElement>(null);
    const liveRegionRef = useRef(null);
    const [focusMoreTabs, setFocusMoreTabs] = useState(false);
    const isTesla = isTeslaMode();

    const { dataALGenerator } = makeA11yListInfoInjector('0', containerRef);

    const { voicemailUnreadCount, smsUnreadCount, callLogUnreadCount } = useAppSelector(selectPhoneData, shallowEqual);

    const phoneUnreadCount = callLogUnreadCount + voicemailUnreadCount + smsUnreadCount;

    const resizeObserver = (resizeCallback: any) => {
        if (!('ResizeObserver' in window)) {
            return {
                observe: () => {},
                unobserve: () => {},
            };
        } else {
            return new window.ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.contentRect) {
                        resizeCallback(entry.contentRect);
                    }
                });
            });
        }
    };

    const tabRefs = organizableTabList.reduce((current, { tag }) => {
        current[tag] = createRef<HTMLButtonElement>();
        return current;
    }, {} as Record<string, React.RefObject<HTMLButtonElement>>);

    const moreRef = useRef<HTMLButtonElement>();
    const pinButton = useRef<HTMLButtonElement>();

    const headerTabs = organizableTabList.filter((item) => item.pinned === true && !tabsInMore.includes(item.tag));
    const moreTabs = organizableTabList.filter((item) => item.pinned === false || tabsInMore.includes(item.tag));

    const findTagInList = (tag: string) => {
        return organizableTabList.findIndex((tab) => tab.tag === tag);
    };

    const onRightClick = (e: React.MouseEvent<HTMLElement>, tag: string) => {
        e.preventDefault();
        if (e.nativeEvent.button === 2 && showPinUnpin === '') {
            setShowPinUnpin(tag);
        } else {
            setShowPinUnpin('');
        }
    };
    const onDragEnd = () => {
        setIsDragging(false);
        setInitialTab(-1);
        setLastTab(-1);
    };

    const onDragStart = (e: React.DragEvent<HTMLElement>, tag: string) => {
        e.stopPropagation();
        const tabIndex = findTagInList(tag);
        setInitialTab(tabIndex);
        setShowPinUnpin('');
    };

    const onDragEnter = (e: React.DragEvent<HTMLElement>, tag: string) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        if (lastTab === findTagInList(tag)) {
            if (initialTab > lastTab) {
                setLastTab((prev) => prev + 1);
            } else if (initialTab < lastTab) {
                setLastTab((prev) => prev - 1);
            }
        } else if (tag === 'more') {
            setMorePanelStatus(true);
        } else {
            const tabIndex = findTagInList(tag);
            setLastTab(tabIndex);
        }
    };

    const handleMove = (e: React.DragEvent<HTMLElement>) => {
        e.stopPropagation();
        setInitialTab(-1);
        setLastTab(-1);
        setIsDragging(false);
        hideMorePanel();
        const initialDraggedTab = organizableTabList[initialTab];
        handlePinUnpin(initialDraggedTab.tag, initialDraggedTab.pinned);
    };

    const handleReorganizeByKeyboard = (currTabIndex: number, direction: string) => {
        let movementIndex = 0;
        if (direction === 'right') {
            movementIndex = 1;
        } else {
            movementIndex = -1;
        }
        const nextTabIndex = currTabIndex + movementIndex;
        if (nextTabIndex < 0 || nextTabIndex >= organizableTabList.length) {
            return;
        } else if (
            organizableTabList[currTabIndex].pinned !== organizableTabList[nextTabIndex]?.pinned ||
            (currTabIndex === organizableTabList.length - 1 && organizableTabList[currTabIndex].pinned === true)
        ) {
            onToggle(false);

            setActiveElement('');
            if (liveRegionRef.current) {
                if (organizableTabList[currTabIndex].pinned === true) {
                    setActiveElement(organizableTabList[0].tag);
                    liveRegionRef.current.innerText = `${organizableTabList[currTabIndex].text} moved into more menu`;
                } else {
                    liveRegionRef.current.innerText = `${organizableTabList[currTabIndex].text} moved to the toolbar`;
                }
            }
            return handlePinUnpin(organizableTabList[currTabIndex].tag, organizableTabList[currTabIndex].pinned);
        }
        const tempLst = produce(organizableTabList, (draft) => {
            const initialDraggedTab = draft.splice(currTabIndex, 1)[0];
            draft.splice(currTabIndex + movementIndex, 0, initialDraggedTab);
        });
        setOrganizableTabList(tempLst);
        setActiveElement(tempLst[currTabIndex + movementIndex].tag);
        if (liveRegionRef.current) {
            liveRegionRef.current.innerText = `${tempLst[currTabIndex + movementIndex].text} moved to position ${
                currTabIndex + movementIndex + 1
            } of ${organizableTabList.length}`;
        }
    };

    const handleReorganize = (e: React.DragEvent<HTMLElement>) => {
        e.stopPropagation();
        setInitialTab(-1);
        setLastTab(-1);
        setIsDragging(false);
        hideMorePanel();
        if (initialTab === lastTab || (headerTabs.length === 1 && initialTab === 0)) {
            setInitialTab(-1);
            setLastTab(-1);
            return;
        }
        // first check if we are moving things while screen is minimized
        // the reason for this is because we want to make sure we show the item
        // that is being moved when it is moved..
        const isInitialTagInMore = tabsInMore.includes(organizableTabList[initialTab].tag);
        const isLastTabInMore = tabsInMore.includes(organizableTabList[lastTab].tag);

        const initialInMore = isInitialTagInMore === true && isLastTabInMore === false;
        const lastInMore = isInitialTagInMore === false && isLastTabInMore === true;

        if (organizableTabList[initialTab].pinned === true && organizableTabList[lastTab].pinned === true) {
            if (initialInMore) {
                setTabsInMore((moreTabs) => {
                    const temp = moreTabs.filter((tag) => tag !== organizableTabList[initialTab].tag);
                    headRef.current.tabsInMore = temp;
                    return temp;
                });
            } else if (lastInMore) {
                setTabsInMore((moreTabs) => {
                    const temp = moreTabs.concat(organizableTabList[initialTab].tag);
                    headRef.current.tabsInMore = temp;
                    return temp;
                });
            }
        }
        // alter the list according to the initial and last tab
        if (initialTab !== -1 && lastTab !== -1) {
            const tempLst = produce(organizableTabList, (draft) => {
                const lastDraggedTab = draft[lastTab];
                const initialDraggedTab = draft.splice(initialTab, 1)[0];

                if (initialDraggedTab.pinned === false && lastDraggedTab.pinned === true) {
                    initialDraggedTab.pinned = true;
                } else if (initialDraggedTab.pinned === true && lastDraggedTab.pinned === false) {
                    initialDraggedTab.pinned = false;
                }
                draft.splice(lastTab, 0, initialDraggedTab);
                if (isTabSelected(initialDraggedTab.tag) && lastDraggedTab.pinned === false) {
                    changeTab(draft[0].tag);
                }
            });
            setOrganizableTabList(tempLst);
        }
    };

    const handlePinUnpin = (tag: string, pinned: boolean, e?: React.MouseEvent<HTMLElement>) => {
        if (e) {
            e.stopPropagation();
        }
        if (headerTabs.length === 1 && pinned === true) {
            return;
        }
        const tabIndex = findTagInList(tag);
        const tempLst = produce(organizableTabList, (draft) => {
            if (pinned) {
                // unpinning
                const findFirstUnpinned = organizableTabList.findIndex((tab) => tab.pinned === false);
                draft[tabIndex].pinned = false;
                const tempTab = draft.splice(tabIndex, 1)[0];
                if (findFirstUnpinned === -1) {
                    draft.push(tempTab);
                } else {
                    draft.splice(findFirstUnpinned - 1, 0, tempTab);
                }
                if (isTabSelected(tag)) {
                    changeTab(draft[0].tag);
                }
            } else {
                // pinning
                draft[tabIndex].pinned = true;
                const tempTab = draft.splice(tabIndex, 1)[0];
                draft.splice(headerTabs.length, 0, tempTab);
            }
        });
        setOrganizableTabList(tempLst);
    };

    const handleMorePinUnpin = (tag: string, pinned: boolean, e?: React.MouseEvent<HTMLElement>) => {
        handlePinUnpin(tag, pinned, e);
    };

    const hideMorePanel = () => {
        setMorePanelStatus(false);
    };

    const pathNameRef = useRef(pathname);
    pathNameRef.current = pathname;

    const isTabSelected = (tag: string): boolean => {
        const index = organizableTabList.findIndex((_) => _.tag === tag);
        if (index < 0) return false;
        const { routePath } = organizableTabList[index];
        return routePath === pathNameRef.current;
    };

    useEffect(() => {
        if (morePanelStatus === false) {
            setFocusMoreTabs(false);
            moreRef?.current.focus();
        }
    }, [morePanelStatus]);

    useEffect(() => {
        if (tabRefs[activeElement]?.current) {
            tabRefs[activeElement]?.current.focus();
        }
    }, [organizableTabList]);

    const changeTab = (tag: string) => {
        hideMorePanel();
        const tabIndex = findTagInList(tag);
        if (organizableTabList[tabIndex].pinned === false) {
            handlePinUnpin(tag, false);
        }
        if (tabsInMore.includes(organizableTabList[tabIndex].tag)) {
            setTabsInMore((moreTabs) => {
                const temp = moreTabs.filter((tag) => tag !== organizableTabList[tabIndex].tag);
                headRef.current.tabsInMore = temp;
                return temp;
            });
            handlePinUnpin(tag, false);
        }
        const ref = tabRefs[tag];
        const { routePath } = organizableTabList[tabIndex];
        history.push(routePath);
        if (ref.current) {
            ref.current.focus();
        }
    };

    const moreListTabsClick = (tag: string) => {
        changeTab(tag);
    };

    const pinOrUnpinRef = useClickOutside({
        callback: (flag: boolean) => {
            if (!flag) {
                setShowPinUnpin('');
            }
        },
        excludeRefs: [],
    });

    const onToggle = (show: boolean) => {
        if (!show) {
            hideMorePanel();
        } else {
            setMorePanelStatus(true);
        }
    };

    const handleTabOnKeyDown = (e: React.KeyboardEvent, tag: string) => {
        if (e.shiftKey && e.key === 'F10') {
            setShowPinUnpin(tag);
        } else if (e.altKey && e.key === 'ArrowDown') {
            const currTabIndex = findTagInList(tag);
            handleReorganizeByKeyboard(currTabIndex, 'right');
        } else if (e.altKey && e.key === 'ArrowUp') {
            const currTabIndex = findTagInList(tag);
            handleReorganizeByKeyboard(currTabIndex, 'left');
        } else if (pinButton?.current && e.key === 'ArrowDown') {
            pinButton?.current.focus();
        }
    };

    const handlePinBtnOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'ArrowUp') {
            tabRefs[showPinUnpin].current.focus();
        } else if (e.key === 'Escape') {
            tabRefs[showPinUnpin].current.focus();
            setShowPinUnpin('');
        }
    };

    const handleMoreOnKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onToggle(!morePanelStatus);
        } else if (e.key === 'ArrowDown') {
            setFocusMoreTabs(true);
        }
    };

    const handleReturnFocusToMore = () => {
        moreRef?.current.focus();
        setFocusMoreTabs(false);
    };

    // Bootstrap sending a warning if I dont spread these props
    const renderPinUnpinButton = (
        { placement, scheduleUpdate, arrowProps, outOfBoundaries, show, hasDoneInitialMeasure, ...props }: any,
        pinned: boolean,
        tag: string,
    ) => (
        <div ref={pinOrUnpinRef} className="home-header__pinUnpin-tab" {...props}>
            <Button
                rootRef={pinButton}
                onClick={(e) => handlePinUnpin(tag, pinned, e)}
                onKeyDown={handlePinBtnOnKeyDown}
                ariaLabel={pinned ? REMOVE_PINNED_TEXT : ADD_PINNED_TEXT}
            >
                {pinned ? <IconPinUnpinFill /> : <IconPinFill />}
                <span> {pinned ? REMOVE_PINNED_TEXT : ADD_PINNED_TEXT}</span>
            </Button>
        </div>
    );

    const tabEles = headerTabs.map((tabData, index, array) => {
        const { text, tag, banner, icon, pinned } = tabData;
        const isSelected = isTabSelected(tag);
        const movingRight = initialTab < index && index <= lastTab && lastTab !== -1;
        const movingLeft = initialTab > index && index >= lastTab && lastTab !== -1;
        const isCurrDragged = initialTab === index;
        const Icon = icon[0];

        const ariaLabel =
            tag === headerTabType.chat
                ? `${tabData.text} ${shown} unread messages tab ${isSelected ? 'selected' : ''} ${index + 1} of ${
                      headerTabs.length
                  }`
                : `${tabData.text} tab ${isSelected ? 'selected' : ''} ${index + 1} of ${headerTabs.length}`;

        let itemView = (
            <button
                draggable
                onDragStart={(e) => onDragStart(e, tag)}
                onDragEnter={(e) => onDragEnter(e, tag)}
                onDrop={(e) => handleReorganize(e)}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDragEnd={onDragEnd}
                data-testid={`${tag} ${index}`}
                feature-type={tag}
                key={`${tag}-${index}`}
                ref={tabRefs[tag]}
                id={`home-tab-${tag}`}
                tabIndex={isSelected ? 0 : -1}
                // role="tab"
                aria-controls={`home-tabpanel-${tag}`}
                // aria-selected={isSelected}
                aria-label={ariaLabel}
                aria-describedby="tabsScreenReaderInfo"
                className={classNames(
                    'home-header__tab',
                    `home-header__tab-${tag}`,
                    { selected: isSelected },
                    { isNotDragging: !isCurrDragged && isDragging },
                    { isStartingDrag: isCurrDragged },
                    { isDragging: isCurrDragged && isDragging },
                    { orderBarRight: movingRight },
                    { orderBarLeft: movingLeft },
                    { whiteText: theme !== 'classic' },
                )}
                data-a-l={dataALGenerator()}
                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.MENU_POLICY}
                onClick={() => changeTab(tag)}
                onContextMenu={(e) => onRightClick(e, tag)}
                onKeyUp={(e) => handleTabOnKeyDown(e, tag)}
            >
                <OverlayTrigger
                    key={`${tag}-options`}
                    overlay={(props: OverlayTriggerRenderProps) => renderPinUnpinButton(props, pinned, tag)}
                    placement="bottom"
                    show={tag === showPinUnpin}
                >
                    <div className="tab-item-wrapper">
                        <span className="header-icon">
                            <Icon />
                            {banner}
                        </span>

                        <span className="header-text">{text}</span>
                    </div>
                </OverlayTrigger>
            </button>
        );

        if (tag === 'chat')
            itemView = (
                <ChatPopover key={`${tag}-popover`} tabsAmount={array.length}>
                    {itemView}
                </ChatPopover>
            );

        return itemView;
    });

    const headRef = useRef<headRefTypes>({
        midChildrenWidths: [],
        tabsInMore: [],
        sizeObserver: {
            observe: (e: any) => {
                return e;
            },
            unobserve: (e: any) => {
                return e;
            },
        },
    });

    const renderMoreMenu = (props: any) => {
        return (
            <HeaderMoreTab
                hideMorePanel={hideMorePanel}
                moreTabs={moreTabs}
                lastTab={lastTab}
                initialTab={initialTab}
                isDragging={isDragging}
                organizableTabList={organizableTabList}
                handleOnClick={moreListTabsClick}
                handleMorePinUnpin={handleMorePinUnpin}
                focusMoreTabs={focusMoreTabs}
                selectedTab={isTabSelected}
                moreButtonRef={moreRef}
                onDragStart={onDragStart}
                onDragEnter={onDragEnter}
                onDragEnd={onDragEnd}
                handleReorganize={handleReorganize}
                handleMove={handleMove}
                phoneUnreadCount={phoneUnreadCount}
                findTagInList={findTagInList}
                handleReorganizeByKeyboard={handleReorganizeByKeyboard}
                handleReturnFocusToMore={handleReturnFocusToMore}
                activeElement={activeElement}
                {...props}
            />
        );
    };

    const handleTabReorder = (misplacedIndex: number) => {
        const temp = [...organizableTabList];
        const tempHolder = temp[misplacedIndex + 1];
        temp[misplacedIndex + 1] = temp[misplacedIndex];
        temp[misplacedIndex] = tempHolder;
        setOrganizableTabList(temp);
    };

    useEffect(() => {
        if (showPhoneIcon) {
            organizableTabList.forEach((tab) => {
                if (isTabSelected(tab.tag) && tab.pinned === false) {
                    handlePinUnpin(tab.tag, false);
                }
            });
        }
    }, [organizableTabList.length]);

    useEffect(() => {
        const outerElement = outerRef.current;
        const innerElement = containerRef.current;
        const headerSizeObserverCallBack = _.throttle(() => {
            const actualMidWidth = innerElement.getBoundingClientRect().width;
            const usableMidWidth = outerElement.getBoundingClientRect().width;
            const distance = actualMidWidth - usableMidWidth;
            const isOutside = distance >= 0;
            if (isOutside) {
                const needToMoveToMore: Array<string> = [];
                const midChildrenWidths = Array.from(innerElement.children).map((_) => ({
                    width: _.getBoundingClientRect().width + HEADER_TAB_MARGIN,
                    type: _.getAttribute('feature-type'),
                }));
                headRef.current.midChildrenWidths = _.uniqBy(
                    midChildrenWidths.concat(headRef.current.midChildrenWidths || []),
                    'type',
                );

                organizableTabList
                    .map((h) => midChildrenWidths.find((item) => item.type === h.tag))
                    .filter((h) => h)
                    .reduceRight((testMidWidth, h) => {
                        if (testMidWidth < usableMidWidth - (headRef.current.tabsInMore.length > 1 ? 0 : TAB_WIDTH)) {
                            return testMidWidth;
                        }
                        if (isTabSelected(h.type) === false) {
                            needToMoveToMore.push(h.type);
                        } else {
                            const headerTagIndex = midChildrenWidths.indexOf(h);
                            if (headerTagIndex > 0) {
                                needToMoveToMore.push(midChildrenWidths[headerTagIndex - 1].type);
                                handleTabReorder(headerTagIndex - 1);
                            }
                        }
                        return testMidWidth - h.width;
                    }, actualMidWidth);
                setTabsInMore((h) => {
                    const temp = h.concat(needToMoveToMore);
                    headRef.current.tabsInMore = temp;
                    return temp;
                });
            } else {
                const needToMoveToHeader: Array<string> = [];

                if (!headRef.current.tabsInMore?.length) {
                    return;
                }
                const tabsInMoreWidth = headRef.current.tabsInMore
                    .map((type) => headRef.current.midChildrenWidths.find((h) => h.type === type))
                    .filter((h) => h);
                organizableTabList
                    .map((type) => tabsInMoreWidth.find((h) => h.type === type.tag))
                    .filter((h) => h)
                    .reduce((testMidWidth, item) => {
                        if (testMidWidth + item.width >= usableMidWidth) {
                            return usableMidWidth;
                        } else {
                            const currTab = organizableTabList.find((tab) => tab.tag === item.type);
                            if (currTab.pinned === true) {
                                needToMoveToHeader.push(item.type);
                            }
                        }

                        return testMidWidth + item.width;
                    }, actualMidWidth);
                setTabsInMore((widths) => {
                    const temp = widths.filter((h) => !needToMoveToHeader.includes(h));
                    headRef.current.tabsInMore = temp;
                    return temp;
                });
            }
        }, 200);
        headRef.current.sizeObserver = resizeObserver(headerSizeObserverCallBack);
        headRef.current.sizeObserver.observe(outerElement);
        headRef.current.sizeObserver.observe(innerElement);
        return () => {
            headerSizeObserverCallBack.cancel();
            headRef.current.sizeObserver.unobserve(outerElement);
            headRef.current.sizeObserver.unobserve(innerElement);
        };
    }, [organizableTabList]);
    // tesla doesn't need tabs
    if (isTesla) return null;

    return (
        <div id="headerTabs" ref={outerRef}>
            <div
                aria-live="polite"
                aria-atomic="true"
                ref={liveRegionRef}
                style={{ position: 'absolute', left: '-99999px' }}
            />
            <div
                className="home-header__tabs"
                role="tablist"
                data-a-l="0"
                ref={containerRef}
                onDrop={(e) => {
                    if (organizableTabList[lastTab].pinned === false) {
                        handleMove(e);
                    } else {
                        handleReorganize(e);
                    }
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {tabEles}
                <span id="tabsScreenReaderInfo" className="screen-reader-hidden-text">
                    {KEYBOARD_INSTRUCTIONS_NAVBAR}
                </span>
                <button
                    ref={moreRef}
                    onDrop={(e) => handleMove(e)}
                    onDragEnter={(e) => onDragEnter(e, 'more')}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onKeyDown={handleMoreOnKeyDown}
                    data-testid={'more-header-tab'}
                    feature-type={'more'}
                    key={`more-${headerTabs.length - 1}`}
                    tabIndex={0}
                    className={classNames('home-header__tab', `home-header__tab-${'more'}`, {
                        whiteText: theme !== 'classic',
                        orderBarLeft: initialTab >= headerTabs.length && isDragging,
                    })}
                    aria-controls={'home-tabpanel-more'}
                    data-a-l={dataALGenerator()}
                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.MENU_POLICY}
                    aria-expanded={morePanelStatus}
                    aria-haspopup={true}
                    aria-label={More_Button_Text}
                >
                    <OverlayTrigger
                        overlay={renderMoreMenu}
                        placement="bottom"
                        trigger={['click']}
                        show={morePanelStatus}
                        onToggle={onToggle}
                        rootClose={true}
                    >
                        <div className="tab-item-wrapper">
                            <span className="header-icon">
                                <IconEllipsisHorizontalSmall />
                            </span>
                            <span className="header-text">{More_Button_Text}</span>
                        </div>
                    </OverlayTrigger>
                </button>
            </div>
        </div>
    );
};

export default withFocusWalker(
    React.memo(HeaderTabs),
    'headerTabs',
    () => true,
    ['.home-header__tabs'],
    keyBoardEventHandler,
);
