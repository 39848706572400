import { Modal } from 'Modal';
import React from 'react';
import Button from '../../../components/Button/Button';
import { useAppSelector } from '../../Phone/types';
import { Meeting_Transfer_Error_Confirm, Meeting_Transfer_Failed_Desc, Meeting_Transfer_Failed_Title } from '../LanguageResource';
import { isTeslaMode } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../store/modal/modal-store';

const MeetingTransferError = () => {
    const transferMeetingError = useAppSelector(state => state.modal.modals.transferMeetingError);
    const dispatch = useDispatch();
    const isTesla = isTeslaMode();

    return <Modal needShowAboveBase={true} isOpen={transferMeetingError?.show} centered={true} needBackdrop={false} isDraggable={true}>
        <div className="trans__meeting-error">
            <h5 className="trans__meeting-error-title">{Meeting_Transfer_Failed_Title}</h5>
            <p className="trans__meeting-error-desc">{Meeting_Transfer_Failed_Desc}</p>
            <Button size={isTesla ? 40 : 24} onClick={() => dispatch(setModal({
                name: 'transferMeetingError',
                data: {
                    show: false
                }
            }))}>
                {Meeting_Transfer_Error_Confirm}
            </Button>
        </div>
    </Modal>
}

export default MeetingTransferError;