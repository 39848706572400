const ZAppErrors = {
    WebSessionExpired: {
        // i add this personally.
        code: 10000,
        message: 'Your web session expired, and you need to sign in again.',
    },
    General: {
        code: 10001,
        message: 'The Zoom client encountered an error while processing the request.',
    },
    InvalidParams: {
        code: 10002,
        message: 'Validation error, please check API parameters.',
    },
    AppSdkDisabled: {
        code: 10003,
        message: 'Configuration was denied, please check that the application has the Zoom App SDK feature enabled.',
    },
    NotConfig: {
        code: 10004,
        message:
            'Authorization not found, please call zoomSdk.config to authorize the use of APIs before calling them.',
    },
    RequestNotSend: {
        code: 10006,
        message: 'The Zoom client failed to send a request to the server.',
    },
    ApiNotExist: {
        code: 10008,
        message: 'API does not exist.',
    },
    GetAppListFailed: {
        code: 10013,
        message: 'Request to get supported APIs list failed.',
    },
    ApiRateLimit: {
        code: 10033,
        message: 'The API request has exceeded the client rate limit, please wait a moment.',
    },
    NotInConfig: {
        code: 10034,
        message: 'The API cannot be executed as it is not listed in config()',
    },
    ServerRateLimit: {
        code: 10035,
        message: 'The API request has exceeded the server rate limit, please wait a moment.',
    },
    LaunchFailed: {
        code: 10037,
        message: 'Failed to open an app',
    },

    SecurableStatusFail: {
        code: 10241,
        message: 'Unable to retrieve Securable Status for phone number.',
    },

    NotAvaliableOutsideZCC: {
        code: 10242,
        message: 'Method not available outside Zoom Contact Center',
    },

    OutsideEngagement: {
        code: 10243,
        message: 'Method not available outside Engagement context.',
    },
};
export type ZAppError = { code: number; message: string };

export default ZAppErrors;
