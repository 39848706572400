import React from 'react';
import { useAppSelector } from '../../../../store/store-hooks';
import { RootState } from '../../../../store';
import { shallowEqual } from 'react-redux';

const selectPhoneData = (state: RootState) => {
    const phone = state.phone;
    const smsUnreadCount = state.sms?.chats?.ids?.reduce(
        (previousValue: number, currentValue: string) =>
            previousValue + state.sms?.chats?.entities[currentValue].unreadCount,
        0,
    );
    return {
        NESEnabled: phone?.e911?.NESEnabled,
        allowAccessLocation: phone?.e911?.allowAccessLocation,
        voicemailUnreadCount: phone?.voicemailUnreadCount,
        smsUnreadCount,
        callLogUnreadCount: phone?.callLogUnreadCount,
        isE911NotOk: !!phone?.e911?.e911IconClassName,
    };
};

const PhoneTabBanner = () => {
    const { allowAccessLocation, NESEnabled, voicemailUnreadCount, smsUnreadCount, callLogUnreadCount, isE911NotOk } =
        useAppSelector(selectPhoneData, shallowEqual);

    const showPhoneRedDot =
        isE911NotOk || (NESEnabled && (allowAccessLocation === 'rejected' || allowAccessLocation === 'pending'));

    const PhoneRedDot = showPhoneRedDot ? <span className="header-icon__red-dot" key="red-dot"></span> : null;

    const phoneUnreadCount = callLogUnreadCount + voicemailUnreadCount + smsUnreadCount;

    const PhoneUnread = phoneUnreadCount ? (
        <span key="unread" className="header-icon__badge">
            {phoneUnreadCount}
        </span>
    ) : null;

    return (
        <>
            {PhoneRedDot}
            {PhoneUnread}
        </>
    );
};

export default PhoneTabBanner;
