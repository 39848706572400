import { DndSnoozePolicy, PolicyName } from '@zoom/zpns-contact-presence';
import { setMySnoozePresenceInfo } from '../../../features/Contacts/redux';
import serviceContainer from '../../container';
import PolicyTimer from './PolicyTimer';

interface IProps {
    whenPolicyDurationEnd(name: PolicyName): void;
}

export default class DndSnoozePolicyTimer extends PolicyTimer {
    whenPolicyDurationEnd: (name: PolicyName) => void;

    constructor(props: IProps) {
        super({
            name: PolicyName.snooze,
            policy: null,
        });
        this.whenPolicyDurationEnd = props.whenPolicyDurationEnd;
    }

    // overide super class's implementation
    setPolicy(policy: DndSnoozePolicy) {
        policy.duration *= 60; // transform minutes to seconds
        super.setPolicy(policy);
    }

    onDurationEnd() {
        if (typeof this.whenPolicyDurationEnd === 'function') {
            this.whenPolicyDurationEnd(this.name);
        }
    }

    onDurationUpdate() {
        const reduxStore = serviceContainer.getReduxStore();
        // F**k, you have to pass copied policy to redux, or immer will make it readonly
        // you can NOT motify it's value anywhere from our class
        reduxStore.dispatch(setMySnoozePresenceInfo({ ...this.policy }));
    }
}
