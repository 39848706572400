import {
    WEB_SESSION_EXPIRED_ERROR,
    InviteContactResponseType,
    WebimInviteContactEvent,
    InviteContactSyncMessageType,
    WebimInviteContactSyncMessageEvent,
} from '@zoom/pwa-webim';
import {
    setSearchResult,
    setSearchStatus,
    updateContacts,
    deleteGroupMembers,
    setPendingContacts,
    setThisGroupContacts,
    setSelectedContact,
} from './contact-store';
import { FetchStatus } from '../../../utils/constants';
import { createEmptyContact, createContactFromXmppContact, createPendingContactFromDB } from './contact-utils';
import { AppDispatch, AppGetState } from '../../../store';
import { IContactProfileParams, IContact, JidArray } from '../types';
import { getJidFromFullJid } from '../../../utils/index';
import { xmppAgent } from '../../../app-init';
import { debounce } from 'lodash-es';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import BrowserNotification, { NOTIFICATION_TAGS } from '../../..//utils/BrowserNotification';
import { NOTIFY_INVITE_ACCEPTED_F, NOTIFY_INVITE_DECLINED_F, NOTIFY_INVITE_ME_F } from '../../../resource';
import { PendingContactDBManager } from '../DBManagers';
import { EXTERNAL_GROUP_ID, createPendingContactJid, isPendingContactJid } from '../utils/stringUtils';
import { ISearchContactResult, createSearchContact } from '../../../services/http/searchContact';
import { searchContactsByKeyLocally } from './contact-search-utils';
import { subscribePresenceFor3Hours, subscribePresenceFor5mins } from '../../../services/zpns/presence-subscribe';

const { searchContact } = createSearchContact();

export const searchContactsThunk =
    ({ key }: { key: string }) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        // first search local xmpp data for exteranl contacts
        const localResult = searchContactsByKeyLocally(key, getState());
        dispatch(setSearchResult({ from: 'local', key, result: localResult }));

        // if key.length is less than 3, do not search from web.
        if (key.length < 3) return Promise.resolve(null);

        dispatch(setSearchStatus(FetchStatus.loading));

        // second search web for internal contacts, that we don't fetch to local.
        return searchContact({ key, contactType: null, phoneNumber: null })
            .then(({ contacts }: ISearchContactResult) => {
                const {
                    common: { userInfo },
                } = getState();
                const result = contacts
                    .filter((i) => i.jid && i.jid !== userInfo?.jid && (i.displayName || i.firstName || i.lastName))
                    .map((i) => {
                        const item = createEmptyContact();
                        item.email = i.snsEmail;
                        item.isExternal = !i.isSameAccount;
                        delete i.snsEmail;
                        Object.assign(item, i);
                        return item;
                    });
                dispatch(setSearchStatus(FetchStatus.succeeded));
                dispatch(setSearchResult({ from: 'web', key, result }));
                dispatch(updateContacts({ contacts: result }));
            })
            .catch(() => {
                dispatch(setSearchStatus(FetchStatus.failed));
            });
    };

/**
 * fetch contact data from xms service
 *
 * @param payload
 * @param forceFetch
 */
export const fetchContactsProfileThunk =
    (payload: JidArray, forceFetch = false, moreProps?: { isForSearch?: boolean }) =>
    (dispatch: AppDispatch) => {
        const fetchParams: Array<IContactProfileParams> = [];
        payload.forEach((data) => {
            const { jid } = data;
            if (jid && !isPendingContactJid(jid)) {
                fetchParams.push({
                    jid: getJidFromFullJid(jid),
                    lastModify: 0,
                });
            }
        });

        const jids = payload.map((v) => v.jid);

        if (moreProps?.isForSearch) {
            subscribePresenceFor5mins(jids);
        } else {
            subscribePresenceFor3Hours(jids);
        }

        if (fetchParams.length <= 0) return Promise.resolve([]);

        return xmppAgent
            .getContactProfile(fetchParams, forceFetch)
            .then((result: any) => {
                console.log('getContactProfile result ==>', result);

                if (Array.isArray(result) && result.length > 0) {
                    const contacts = result
                        .filter((item) => !!item.vcard) // userRemoved will not contain vcard data
                        .map((item: any) => {
                            const { vcard } = item;
                            const contact = createContactFromXmppContact(vcard);
                            return contact;
                        }) as Array<IContact>;

                    console.log('getContactProfile contacts ==>', contacts);
                    dispatch(updateContacts({ contacts }));

                    const emails = contacts.map((item) => item.email);
                    // if current contact includes pending contact, the pending contcact need be deleted
                    dispatch(deletePendingContactsThunk(emails));

                    return contacts;
                }
                return [];
            })
            .catch((error: any) => {
                console.error('getContactProfile error ==>', error);
                const { errorMsg } = error;

                // session expired
                if (errorMsg && errorMsg.indexOf(WEB_SESSION_EXPIRED_ERROR) > -1) {
                    dispatch(signoutWhenSeesionExpiredThunk());
                }

                return [];
            });
    };

const DEBOUNCE_DELAY = 500;
const debounceContactProfileRequest = debounce((payload, forceFetch, dispatch) => {
    dispatch(fetchContactsProfileThunk(payload, forceFetch));
}, DEBOUNCE_DELAY);

/**
 * get contact info and debounce request
 *
 * @param payload
 * @param forceFetch
 */
export const getContactsInfoThunk =
    (payload: JidArray, forceFetch = false) =>
    (dispatch: AppDispatch) => {
        payload.forEach((data) => {
            const { jid } = data;
            if (jid) {
                subscribePresenceFor3Hours([jid]);
            }
        });

        if (payload.length > 0) {
            debounceContactProfileRequest(payload, forceFetch, dispatch);
        }
    };

export const getPendingContactsThunk = () => (dispatch: AppDispatch) => {
    PendingContactDBManager.getPendingContactList()
        .then((data) => {
            const contacts = createPendingContactFromDB(data);
            dispatch(setPendingContactsThunk(contacts));
        })
        .catch((error) => {
            console.error('getPendingContactsThunk error:', error);
        });
};

export const addPendingContactsThunk = (email: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
        contacts: { pendingContacts },
    } = getState();

    const isExist = pendingContacts.map((item) => item.email).includes(email);
    if (isExist) return;

    // store pending contact data
    await PendingContactDBManager.addPendingContact(email);

    // set external external group contact data
    const data = pendingContacts.concat(createPendingContactFromDB([email]));
    dispatch(setPendingContactsThunk(data));
};

export const setPendingContactsThunk = (data: Array<IContact>) => (dispatch: AppDispatch) => {
    if (data.length > 0) {
        dispatch(setPendingContacts(data));
        dispatch(
            setThisGroupContacts({
                groupId: EXTERNAL_GROUP_ID,
                contacts: data,
            }),
        );
    }
};

export const deletePendingContactsThunk =
    (email: string | Array<string>) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const {
            contacts: { pendingContacts },
        } = getState();

        let jids = [];

        const pendingEmails = pendingContacts.map((item) => item.email);

        let repeat;
        let exist = false;
        if (Array.isArray(email)) {
            jids = email.map((item) => createPendingContactJid(item));
            repeat = email.filter((item) => pendingEmails.includes(item));
            exist = repeat.length > 0;
        } else {
            jids = [createPendingContactJid(email)];
            exist = pendingEmails.includes(email);
        }

        if (!exist) return;

        // remove data from redux
        dispatch(
            deleteGroupMembers({
                groupId: EXTERNAL_GROUP_ID,
                jids,
            }),
        );

        try {
            // remove from indexDB
            await PendingContactDBManager.deletePendingContact(email);
        } catch (error) {
            console.error('deletePendingContactsThunk error ==>', error);
        }
    };

// handle invite contact xmpp message
export const handleInviteContactMessageThunk =
    (event: WebimInviteContactEvent, externalGroup: Array<any>) => (dispatch: AppDispatch, getState: AppGetState) => {
        const {
            common: {
                userInfo: { displayName },
            },
        } = getState();

        const notification = new BrowserNotification();
        const { data } = event;
        const screenname = data?.status?.screenname || data.email || '';

        switch (data.type) {
            case InviteContactResponseType.INVITE_CONTACT_ROSTER_ADD: // contact roster change,contact external group need update
                // invitee will be  put in external group default, so pull latest external groups to refresh group members;
                // Both 'Friends' and 'default' group be renamed as 'External'.
                if (data.roster.data.length > 0 && externalGroup.length > 0) {
                    xmppAgent.getGroupMembers(externalGroup);
                }
                break;
            case InviteContactResponseType.INVITE_CONTACT_ROSTER_REMOVE: // remove contact
                const { roster } = data;
                const removeIds = roster.data.map((item: any) => item.jid);

                if (data.roster.data.length > 0 && externalGroup.length > 0) {
                    //  delete contact data by jid from local, and refresh external contact
                    // external group id is 'default'
                    dispatch(
                        deleteGroupMembers({
                            groupId: EXTERNAL_GROUP_ID,
                            jids: removeIds,
                        }),
                    );

                    // refresh external list
                    xmppAgent.getGroupMembers(externalGroup);
                }
                break;
            case InviteContactResponseType.INVITE_CONTACT_BE_ACCEPTED: //  send invite be others accepted
                if (!data.email) return;
                // remove form pending contact
                dispatch(deletePendingContactsThunk(data.email));

                // send sync message
                // tell my other device(web,mobile,desktop). my invte be accepted.
                xmppAgent.sendInviteContactSyncMessage({
                    action: InviteContactSyncMessageType.SUBSCRIBED,
                    inviteeJid: data.from,
                    inviteeName: data?.status?.screenname || '',
                    inviteeEmail: data.email,
                    inviterName: displayName,
                });

                notification.notify({
                    body: NOTIFY_INVITE_ACCEPTED_F(screenname),
                    title: '',
                    onClick: null,
                    onClose: null,
                    tag: NOTIFICATION_TAGS.INVITE_CONTACT,
                });

                break;
            case InviteContactResponseType.INVITE_CONTACT_BE_DECLINED: // send invite be others declined
                // remove form pending contact
                if (data.email) {
                    dispatch(deletePendingContactsThunk(data.email));
                }

                // send sync message
                // cannot get invitee jid and name if declinded
                xmppAgent.sendInviteContactSyncMessage({
                    action: InviteContactSyncMessageType.UNSUBSCRIBED,
                    inviteeJid: '',
                    inviteeName: '',
                    inviteeEmail: data.email,
                    inviterName: displayName,
                });

                notification.notify({
                    body: NOTIFY_INVITE_DECLINED_F(screenname),
                    title: '',
                    onClick: null,
                    onClose: null,
                    tag: NOTIFICATION_TAGS.INVITE_CONTACT,
                });

                break;
            case InviteContactResponseType.INVITE_CONTACT_OTHERS_INVITE_ME: // others invtie me, if same others has invted me before, maybe cannot accepte this message
                notification.notify({
                    body: NOTIFY_INVITE_ME_F(screenname),
                    title: '',
                    onClick: null,
                    onClose: null,
                    tag: NOTIFICATION_TAGS.INVITE_CONTACT,
                });
                break;
            default:
                break;
        }
    };

// handle invite contact sync message. To sync message to my other devices(web,mobile,desktop).
export const handleInviteContactSyncMessageThunk =
    (event: WebimInviteContactSyncMessageEvent) => (dispatch: AppDispatch) => {
        const { action, email } = event.data;

        console.log('handleInviteContactSyncMessageThunk event 1 ==>', event);

        switch (action) {
            case InviteContactSyncMessageType.SUBSENT: // inviter: send invite contact
                // render and store pending contact data
                email && dispatch(addPendingContactsThunk(email));
                break;
            case InviteContactSyncMessageType.SUBSCRIBED: // inviter: invite Be accepted
            case InviteContactSyncMessageType.UNSUBSCRIBED: // inviter: invite Be rejected
                // remove pending contact data,
                email && dispatch(deletePendingContactsThunk(email));
                break;
            // case InviteContactSyncMessageType.DOACCEPT: // invitee: accept others invite
            // case InviteContactSyncMessageType.SUBREJECT: // ivitee:: reject others invite
            //  break;
            default:
                break;
        }
    };

export const tryResetSelectedContactWhenDelete =
    (jids: Array<string> = []) =>
    (dispatch: AppDispatch, getState: AppGetState) => {
        const {
            contacts: { selectedContact },
        } = getState();

        const selectedJid = selectedContact?.jid;

        if (!selectedJid) {
            return;
        }

        if (jids.indexOf(selectedJid) > -1) {
            dispatch(
                setSelectedContact({
                    jid: '',
                    groupId: '',
                }),
            );
        }
    };
