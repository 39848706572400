import React from 'react';

import styles from './MeetingTransferedBanner.module.scss'
import useInMeetingDeviceList from '../Upcoming/useInMeetingDeviceList';
import { From_Text, MEETINGS_TEXT } from '../../../resource';
import { IconCellphoneFill, IconDesktopFill } from '@zoom/icons-react';
import { InProgress_Text, Meeting_Transfer_Switch_Text, View_Text } from '../LanguageResource';
import { useAppDispatch, useAppSelector } from '../../../store';
import { isDuringMeetingSelector } from '../../Meeting/redux';
import Button from '../../../components/Button/Button';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';
import { isTeslaMode } from '../../../utils';
import { Popover, Button as ReactUIButton } from '@zoom/react-ui';
import { transferMeetingThunk } from './redux/transfer-meetings-thunk';

const MeetingTransferedBanner = () => {
    const dispatch = useAppDispatch();
    const { userInfo, isDuringMeeting, isStartDisabled } = useAppSelector((state) => {
        return {
            userInfo: state.common.userInfo,
            isDuringMeeting: isDuringMeetingSelector(state),
            isStartDisabled: state.meeting.isStartDisabled,
        };
    });
    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);
    const isTesla = isTeslaMode();
    const inMeetingDeviceList = useInMeetingDeviceList();

    if (isTesla) {
        return null;
    }
    const inMeetingDeviceListExist = inMeetingDeviceList.map(inMeetingDevice => {
        const { meetingInfo } = inMeetingDevice;
        if (meetingInfo && meetingInfo.length > 0) {
            return inMeetingDevice
        }
        return null;
    }).filter(Boolean);

    // 1 transfered meeting on going
    if (inMeetingDeviceListExist.length === 1) {
        const { meetingInfo, name, resource, deviceId } = inMeetingDeviceListExist[0];
        
        const { number, topic } = meetingInfo[0];
        return <div className={styles.banner1}>
            <div className={styles.details1}>
                <div className={styles.details1Device}>
                    {
                        resource.indexOf('mobile') !== -1 
                            ? <IconCellphoneFill className="trans__meeting-status-icon" /> 
                            : <IconDesktopFill className="trans__meeting-status-icon" />
                    }
                    <span>{From_Text} {name}</span>
                </div>
                <div className={styles.details1Topic}>{topic}</div>
            </div>
            <Button
                disabled={isDuringMeeting || isStartDisabled || isZCCInMeeting}
                size={24}
                onClick={() => {
                    dispatch(transferMeetingThunk({ userInfo, number, deviceId, resource, name }))
                }}
            >
                {Meeting_Transfer_Switch_Text}
            </Button>
        </div>
    }

    // 2 or more transfered meeting on going
    if (inMeetingDeviceListExist.length >= 2) {
        const popupContent = inMeetingDeviceListExist.map(inMeetingDevice => {
            const { meetingInfo, name, resource, deviceId } = inMeetingDevice;
            const { number, topic } = meetingInfo[0];
            return <div className={styles.bannerMoreThan1Popover}>
                <div className={styles.details2Popover}>
                    <div className={styles.details1Device}>
                        {
                            resource.indexOf('mobile') !== -1 
                                ? <IconCellphoneFill className="trans__meeting-status-icon" /> 
                                : <IconDesktopFill className="trans__meeting-status-icon" />
                        }
                        <span>{InProgress_Text}</span>
                    </div>
                    <div className={styles.details1Topic}>{topic}</div>
                    <div>{name}</div>
                </div>
                <Button
                    disabled={isDuringMeeting || isStartDisabled || isZCCInMeeting}
                    size={isTesla ? 40 : 24}
                    onClick={() => {
                        dispatch(transferMeetingThunk({ userInfo, number, deviceId, resource, name }))
                    }}
                >
                    {Meeting_Transfer_Switch_Text}
                </Button>
            </div>
        })
        return <div className={styles.bannerMoreThan1}>
            <div className={styles.details2}>
                {InProgress_Text} | {inMeetingDeviceListExist.length} {(MEETINGS_TEXT as string).toLowerCase()}
            </div>
            <Popover
                content={popupContent}
                placement="bottom"
                trigger='click'
                footer={null}
            >
                <ReactUIButton size="small" >{View_Text}</ReactUIButton>
          </Popover>
        </div>
    }

    return null;
}

export default MeetingTransferedBanner;