import React, { useEffect, useLayoutEffect } from 'react';
import './Meetings.scss';
import '../configure';

import MeetingGroups from './MeetingGroups';
import MeetingHostFilter from './MeetingHostFilter';
import PMISection from './PMISection';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { getCalendarListThunk, getMeetingListThunk } from './redux/meetings-thunk';
import { selectedMeetingItemIdSelector } from './redux/meetings-selector';
import Loading from '../Loading';
import { FetchStatus } from '../../../utils/constants';
import { AddCalendarInMeetingsPanel } from './CalendarIntegration/AddCalendar';
import { showEveryoneMeetingListSeletor } from '../../../store/common/userWebSettingsSelector';

export default function Meetings() {
    const dispatch = useAppDispatch();
    const { loadingStatus, isConnectedToCalendar } = useAppSelector((state) => state.meetings);
    const currentSelectedItemId = useAppSelector(selectedMeetingItemIdSelector);

    const showFilter = useAppSelector(showEveryoneMeetingListSeletor);

    useEffect(() => {
        dispatch(getMeetingListThunk());
        dispatch(getCalendarListThunk());
    }, []);

    useLayoutEffect(() => {
        const selectedItem = document.querySelector(`.meetings [data-select-id='${currentSelectedItemId}']`);
        const items = document.querySelectorAll('.meetings [data-select-id]');
        const index = Array.from(items).findIndex((_) => _ === selectedItem);
        if (index === -1) return;
        // i think if index is 0, 1, we don't need to scroll
        if (index > 1) {
            items[index - 1]?.scrollIntoView();
        }
    }, []);

    return (
        <div className="meetings">
            {loadingStatus === FetchStatus.loading ? (
                <Loading />
            ) : (
                <>
                    <PMISection />
                    {showFilter ? <MeetingHostFilter /> : null}
                    <MeetingGroups />
                    {!isConnectedToCalendar ? <div className="add-calendar__divider"></div> : null}
                    {!isConnectedToCalendar ? <AddCalendarInMeetingsPanel /> : null}
                </>
            )}
        </div>
    );
}
