import { WebimEventType } from '@zoom/pwa-webim';
import serviceContainer from '../container';
import { init, ZpnsConnectStatus } from '@zoom/zpns-contact-presence';
import { setZpns } from '../../store/common/common-store';
import { presenceManagerProps } from './presence-manager-config';

const { zpnsPresenceFactory, zpnsConnection, presenceManager } = init({
    enableUserManualOverridePresence: Boolean(window.PwaConfig.enableUserManualOverridePresence),
    presenceProps: presenceManagerProps,
});

const eventBus = serviceContainer.getEventBus();

serviceContainer.setZpnsPresenceFactory(zpnsPresenceFactory);
serviceContainer.setZpns(zpnsConnection);
serviceContainer.setPresenceManager(presenceManager);

zpnsConnection.events.connectStatus.subscribe((status: ZpnsConnectStatus) => {
    const reduxStore = serviceContainer.getReduxStore();
    reduxStore.dispatch(
        setZpns({
            status: status.statusText,
            networkConnected: navigator.onLine,
        }),
    );
    eventBus.zpns.connectStatus.dispatchEvent(status.statusText);
});

zpnsConnection.events.message.subscribe((msg) => {
    const eventBus = serviceContainer.getEventBus();
    eventBus.zpns.message.dispatchEvent(msg);
});

eventBus.app.userInfoReady.subscribe((userInfo) => {
    presenceManager.setConfig({
        jid: userInfo.jid,
        enableUserManualOverridePresence: Boolean(window.PwaConfig.enableUserManualOverridePresence),
    });
});

let xmppSub = false;
const onXmppConnected = () => {
    presenceManager.handleXmppServerConnected();

    if (xmppSub) {
        return;
    }
    xmppSub = true;

    const xmppAgent = serviceContainer.getXmppAgent();

    // Todo, xmpp may connected many times, multiple subscriptions!!!!
    xmppAgent.webimInstance.on(WebimEventType.PresencePolicy, (e: any) => {
        presenceManager.handleReceiveXmppPolicy(e.data);
    });
};

eventBus.xmpp.xmppReady.subscribe(onXmppConnected);

export default zpnsConnection;
