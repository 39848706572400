import { createSlice } from '@reduxjs/toolkit';
import easyStore from '../../../utils/storage';
import { getOptionValue, AUDIO_OPTIONS } from '@zoom/pwa-settings';

export enum PWA_HID_KEY {
    HID_AVAILABLE = 'PWA_HidAvailable',
}

export enum SETTING_NAV_KEY {
    GENERAL = 'general',
    AUDIO = 'audio',
    VIDEO = 'video',
    CHAT = 'chat',
    PHONE = 'phone',
    STATISTICS = 'statistics',
}

export const defaultActiveKey = SETTING_NAV_KEY.GENERAL;

export interface ISettingHeader {
    tag: string;
    pinned: boolean;
}

const HeaderTabsKey = 'userSettingHeaderTabs';

export const ThemeKey = 'userSettingTheme';

export const getHeaderTabsFromStorage = () => {
    return easyStore.localStore.easyGet(HeaderTabsKey) ?? [];
};

export const getThemeFromStorage = () => {
    return localStorage.getItem(ThemeKey) ?? 'classic';
};

export const setHeaderTabsToStorage = (tabs: Array<ISettingHeader>) => {
    return easyStore.localStore.easySet(HeaderTabsKey, tabs);
};

interface ISettingState {
    showSettingsDialog: boolean;
    currentNavKey: SETTING_NAV_KEY;
    isEnableHID: boolean;
    hidAvalible: boolean;
    userSettingHeaderTabs: Array<ISettingHeader>;
    userSettingTheme: string;
}

const initialState: ISettingState = {
    showSettingsDialog: false,
    currentNavKey: defaultActiveKey,
    // get isEnableHID from @zoom/pwa-settings
    // add getOptionValue condition to fix jest test problem. getOptionValue maybe is undefined, Need fix it in the future.
    isEnableHID: getOptionValue ? getOptionValue(AUDIO_OPTIONS['SYNC_BUTTONS_ON_HEADSET']) || false : false,
    hidAvalible: false,
    userSettingHeaderTabs: getHeaderTabsFromStorage(),
    userSettingTheme: getThemeFromStorage(),
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setShowSettingsDialog(state, { payload }) {
            state.showSettingsDialog = payload;
        },
        setCurrentNavKey(state, { payload }) {
            state.currentNavKey = payload;
        },
        setIsEnableHID(state, { payload }) {
            state.isEnableHID = payload;
        },
        setHidAvalible(state, { payload }) {
            easyStore.localStore.easySet(PWA_HID_KEY.HID_AVAILABLE, payload);
            state.hidAvalible = payload;
        },
        setUserSettingHeaderTabs(state, { payload }) {
            state.userSettingHeaderTabs = payload;
        },
        setUserSettingTheme(state, { payload }) {
            state.userSettingTheme = payload;
        },
    },
});

export const {
    setShowSettingsDialog,
    setCurrentNavKey,
    setIsEnableHID,
    setHidAvalible,
    setUserSettingHeaderTabs,
    setUserSettingTheme,
} = settingsSlice.actions;

export default settingsSlice.reducer;
