import { ZAppError } from './codes';

export type IError = { errorCode: number; errorMsg: string };

export function createError(code: number, message: string): IError;
export function createError(error: ZAppError): IError;
export function createError(code: number | ZAppError, message?: string) {
    if (typeof code === 'object') {
        return {
            errorCode: code.code,
            errorMsg: code.message,
        };
    } else {
        return {
            errorCode: code,
            errorMsg: message,
        };
    }
}

export const isZAppError = (error: any) => {
    return 'errorCode' in error && 'errorMsg' in error;
};
