import { RunningContext } from '../ZApps/types/message';

/**
 * we use it to generate id for app instance
 */
export const generateRunningAppId = (app: { runningContext: RunningContext; appId: string }) => {
    // we share the same app instance within one product. (phone/contact center/meeting etc)
    // eg. contact center's 3 engagements will share the same app instance if they all have the same app.
    // but if phone has the same app, we create another app instance.
    const { runningContext, appId } = app;
    return `${runningContext}-${appId}`;
};

export const createZCCNotification = (functionName: string, result: object, code = 0) => {
    return {
        jsCallId: '',
        result: {
            notification: functionName,
            ...result,
            returnCode: code,
        },
    };
};
