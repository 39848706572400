import React, { Suspense } from 'react';
import SessionExpireTimerBanner from '../SessionExpireTimerBanner';
import { usePhoneModule } from '../../../asyncModulesStore';
import styles from './banner.module.scss';
import { getContactCenterModule } from '../../../store/appModules/contact-center';
import CanNotShowZoomPhoneBanner from '../../ModalContainer/CanNotShowZoomPhone/CanNotShowBanner';
import MeetingTransferedBanner from '../../Meetings/Meetings/MeetingTransferedBanner';

const EmptyComponent = () => null as any;

const PhoneBanner = () => {
    const { CallingBar } = usePhoneModule();
    if (!CallingBar) {
        return null;
    }
    return <CallingBar />;
};

const ZCCCall = () => {
    const Call = React.lazy(() => {
        return getContactCenterModule()
            .then(({ ZCCCallBanner }) => {
                return { default: ZCCCallBanner };
            })
            .catch(() => {
                return { default: EmptyComponent };
            });
    });

    return (
        <Suspense fallback={null}>
            <Call />
        </Suspense>
    );
};

const Banner = () => {
    return (
        <div className={styles.banner}>
            <SessionExpireTimerBanner />
            <PhoneBanner />
            <ZCCCall />
            <CanNotShowZoomPhoneBanner />
            <MeetingTransferedBanner />
        </div>
    );
};

export default React.memo(Banner);
