import {
    MeetingItem,
    CalendarEvent,
    ItemType,
    CalendarEventConferenceProvider,
    PrivateEventCalendarType,
} from './types';
import {
    IsEventDirectMeeting,
    isSimuliveWebinar,
    isPMIMeeting,
    isPMIMeetingSetting,
    isEventSummitConference,
    isWebRecurrenceMeeting,
    isSupportInvite,
    getTimeSpan,
    isPeriodicMeeting,
    isPrivateCalendarMeeting,
    canWeViewDetail,
    isRealWebinarMeeting,
} from './utils';
import { meetingAgent, xmppAgent } from '../../../app-init';
import { getScheduleEditPagePath } from '../../../utils/meeting-url';
import { addSpaceToMeetingId } from '../../../utils';
import { fetchMeetingDetailThunk } from './redux/meetings-store';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import {
    Copy_Invitation_Text,
    Delete_Text,
    Edit_Text,
    Google_Calendar_Private_Text,
    Host_Text,
    Join_Text,
    MeetingID_Text,
    WebinarID_Text,
    My_PMI_Text,
    Not_Zoom_Meeting_Text,
    OutLook_Private_Text,
    Recurring_Text,
    Start_Text,
    View_Event_Text,
} from '../LanguageResource';
import { chatAgent } from '../../Chat';
import { MSG_TO_CHANNEL } from '../../../resource';
import { enableExpandRecurringMeetingsSelector } from '../../../store/common/userWebSettingsSelector';
import { useState } from 'react';
import { openTab } from '../../../utils/index';
import { isChatEnabled } from '../../../utils/featureOptions';

interface IProps {
    item: MeetingItem | CalendarEvent;
}

const getDefaultJoinLink = (mn: string) => {
    const join = `https://zoom.us/j/${mn}`;
    return join;
};

const noop = () => {};

export enum MeetingOperationsType {
    Start = 'start',
    Join = 'join',
    JoinLobby = 'joinlobby',
    ViewOnZoom = 'viewonzoom',
    Copy = 'copy',
    Edit = 'edit',
    Delete = 'delete',
}

function getTopic(topic: string, canViewDetail: boolean, calendarType: PrivateEventCalendarType) {
    if (canViewDetail) {
        return topic;
    }
    if (PrivateEventCalendarType.Google === calendarType) {
        return Google_Calendar_Private_Text;
    }
    if (PrivateEventCalendarType.Outlook === calendarType) {
        return OutLook_Private_Text;
    }
    return '';
}

/**
 * 1. for PMI meetings
 *   a) we show it's meeting number
 *   b) we start with pmi
 *   c) we edit/delete/detail with original meeting number
 *
 * 2. if isPrivateMeeting === true and canViewDetail
 *  => this meeting is mark private/busy in calendar, if we are assistant of meeting's owner(boss)
 *  => we cann't see the topic, meeting number and can not start, join, edit, delete, copy invitation
 */

export default function useMeetingItem({ item }: IProps) {
    const [isFetchingDetail, setIsFetchinggDetail] = useState(false);
    const dispatch = useAppDispatch();
    const currentUserId = useAppSelector((state) => {
        return state.common.userInfo?.userId;
    });
    const startWithPmi = useAppSelector((state) => state.common.userSettings?.video?.startWithPmi);

    const scheduleForList = useAppSelector((state) => {
        return state.common.userInfo?.scheduleForList || [];
    });

    const enableExpandRecurringMeetings = useAppSelector(enableExpandRecurringMeetingsSelector);

    if (!item?.id || !currentUserId) {
        return null;
    }

    const isJoinOnly = window.PwaConfig.isNoMeetingsLicenseTypeUser;
    const { itemType, topic, startTime, duration } = item;
    let topicTxt = topic;
    let timeTxt = getTimeSpan(startTime, duration);
    let hostTxt = '';
    let meetingNoTxt = '';
    let meetingNo = '';
    let isWithZoomMeeting = false;

    let canStart = false;
    let startTxt = '';
    let startHandler = noop;

    let canJoin = false;
    let joinTxt = '';
    let joinHandler = noop;

    let canCopy = false;
    let copyTxt = '';
    let copyHandler = null;

    let canEdit = false;
    let editTxt = '';
    let editHandler = noop;

    let canDelete = false;
    let deleteTxt = '';
    let deleteHandler = noop;

    let canMsgChannel = false;
    let msgChannelTxt = '';
    let msgChannelHandler = noop;

    let canViewOnZoomEvent = false;
    let viewOnZoomEventTxt = '';
    let viewOnZoomEventHandler = noop;
    let isWebinar = false;
    let isPeriodic = false;
    const isPrivateMeeting = isPrivateCalendarMeeting(item);
    const canViewDetail = canWeViewDetail(item);

    if (ItemType.Calendar === itemType) {
        const { conferenceInfo, organizerEmail } = item;
        hostTxt = `${Host_Text}: ${organizerEmail}`;

        if (conferenceInfo) {
            // it relates with an meeting
            const { meetingId, realMeetingNum, meetingPassword, conferenceProvider, onZoomViewLink, onZoomJoinLink } =
                conferenceInfo;
            isWithZoomMeeting = true;
            if (CalendarEventConferenceProvider.OnZoom === conferenceProvider) {
                // on zoom event does contain meeting number
                if (onZoomJoinLink) {
                    canJoin = true;
                    joinTxt = Join_Text;
                    joinHandler = () => {
                        openTab(onZoomJoinLink);
                    };
                }

                if (onZoomViewLink) {
                    canViewOnZoomEvent = true;
                    viewOnZoomEventTxt = View_Event_Text;
                    viewOnZoomEventHandler = () => {
                        openTab(onZoomViewLink);
                    };
                }
            }

            if (CalendarEventConferenceProvider.Zoom === conferenceProvider) {
                meetingNoTxt = `${MeetingID_Text}: ${addSpaceToMeetingId(meetingId)}`;
                meetingNo = meetingId;
                canJoin = true;
                joinTxt = Join_Text;
                joinHandler = () => {
                    const params = { pwd: meetingPassword };
                    // calendar make meetingId === realMeetingNum, if it's not a PMI meeting
                    if (realMeetingNum !== meetingId) {
                        (params as any).omn = realMeetingNum;
                    }
                    meetingAgent.joinMeeting(meetingId, params);
                };
            }
        } else {
            // not releated with an meeting for now
            meetingNoTxt = Not_Zoom_Meeting_Text;
        }
    }

    if (ItemType.Zoom === itemType) {
        const {
            meetingNumber,
            originalMtgNumber,
            eventDirectMeetingUrl,
            occurrence,
            calendarType,
            hostId,
            password,
            meetingMasterEventId,
        } = item;
        const mn = isPMIMeeting(item) ? originalMtgNumber : meetingNumber;
        meetingNo = String(meetingNumber);
        isWithZoomMeeting = true;
        hostTxt = item.hostName ? `${Host_Text}: ${item.hostName}` : '';

        isPeriodic = isPeriodicMeeting(item);
        isWebinar = isRealWebinarMeeting(item) || isSimuliveWebinar(item);

        const isMe = hostId === currentUserId;
        const isMyBoss = scheduleForList.findIndex((item) => item.userId === hostId) > -1;

        if (!(isMe || isMyBoss)) {
            canStart = false;
            canCopy = false;
            canDelete = false;
            canEdit = false;
            canJoin = true;
            joinTxt = Join_Text;
            joinHandler = () => {
                const params = { pwd: password };
                if (isPMIMeeting(item)) {
                    (params as any).omn = originalMtgNumber;
                }
                meetingAgent.joinMeeting(meetingNumber, params);
            };

            if (canViewDetail) {
                meetingNoTxt = `${isWebinar ? WebinarID_Text : MeetingID_Text}: ${addSpaceToMeetingId(meetingNumber)}`;
            } else {
                meetingNoTxt = '';
            }
        } else {
            if (isPMIMeetingSetting(item)) {
                topicTxt = My_PMI_Text;
                meetingNoTxt = addSpaceToMeetingId(String(meetingNumber));
                hostTxt = '';
                timeTxt = '';
            } else {
                topicTxt = getTopic(topic, canViewDetail, calendarType);
                if (isPrivateCalendarMeeting) {
                    if (canViewDetail) {
                        meetingNoTxt = `${isWebinar ? WebinarID_Text : MeetingID_Text}: ${addSpaceToMeetingId(
                            meetingNumber,
                        )}`;
                    } else {
                        meetingNoTxt = '';
                    }
                }
            }

            if (!isPMIMeetingSetting(item) && isPeriodic) {
                if (!enableExpandRecurringMeetings) {
                    timeTxt = Recurring_Text;
                }
                // default time span
            }

            if (IsEventDirectMeeting(item) && isEventSummitConference(item)) {
                // summit conference should join lobby first
                // but pwa doesn't support Lobby
            } else {
                if (isSimuliveWebinar(item)) {
                    canJoin = true;
                    joinTxt = Join_Text;
                    joinHandler = () => {};
                } else {
                    if (!isPMIMeetingSetting(item) || !window.PwaConfig?.lockAlwaysUsePMIInstant || startWithPmi) {
                        canStart = true;
                    }
                    startTxt = Start_Text;
                    const params: any = {};
                    if (String(originalMtgNumber) !== '0') {
                        params.omn = originalMtgNumber;
                    }
                    startHandler = () => {
                        !!meetingNumber && meetingAgent.startMeetingWithMeetingNumber(meetingNumber, params);
                    };
                }
            }

            // include OnZoom Event and Conference
            if (IsEventDirectMeeting(item)) {
                timeTxt = '';
                canViewOnZoomEvent = true;
                viewOnZoomEventTxt = View_Event_Text;
                viewOnZoomEventHandler = () => {
                    !!eventDirectMeetingUrl && openTab(eventDirectMeetingUrl);
                };
            }

            if (!IsEventDirectMeeting(item)) {
                canEdit = true;
                editTxt = Edit_Text;
                editHandler = () => {
                    openTab(getScheduleEditPagePath(mn, isWebinar));
                };
            }

            if ((isWebRecurrenceMeeting(item) || !isWebinar) && isSupportInvite(item)) {
                canCopy = true;
                copyTxt = Copy_Invitation_Text;
                copyHandler = (): Promise<any> => {
                    // for recurring meetings, you could delete only one of them, not all meetings.
                    // the invitations may changed, if you delete one
                    const content = item.inviteEmailWithTime;

                    if (content) {
                        return Promise.resolve(content);
                    }
                    const params = {
                        mn: String(mn),
                        originMn: String(originalMtgNumber),
                        occurrenceTime: occurrence,
                        userId: currentUserId,
                        meetingMasterEventId,
                    };
                    setIsFetchinggDetail(true);
                    return dispatch(fetchMeetingDetailThunk(params))
                        .then((meeting) => {
                            return (
                                meeting?.inviteEmailWithTime ||
                                meeting?.joinUrl ||
                                getDefaultJoinLink(String(meetingNumber))
                            );
                        })
                        .catch(() => {
                            return getDefaultJoinLink(String(meetingNumber));
                        })
                        .finally(() => {
                            setIsFetchinggDetail(false);
                        });
                };
            }

            // webinar can not be deleted, why?
            if (!isPMIMeetingSetting(item) && !isSimuliveWebinar(item) && !isWebinar && !IsEventDirectMeeting(item)) {
                canDelete = true;
                deleteTxt = Delete_Text;
                deleteHandler = () => {
                    dispatch(setModal({ name: 'meetingsDelete', data: item }));
                };
            }
        }

        if (item.channelId) {
            canMsgChannel = isChatEnabled();
            msgChannelTxt = MSG_TO_CHANNEL;
            msgChannelHandler = () => {
                xmppAgent
                    .unhideChannel(item.channelId)
                    .then(() => {
                        chatAgent.chatWithUserOrChannel({ id: item.channelId });
                    })
                    .catch((e: any) => {
                        console.error(e);
                    });
            };
        }
    }

    if (isJoinOnly) {
        canStart = false;
        canEdit = false;
        canDelete = false;
    }

    return {
        isWithZoomMeeting,
        topicTxt,
        timeTxt,
        hostTxt,
        meetingNoTxt,
        meetingNo,
        canStart,
        startTxt,
        startHandler,
        canJoin,
        joinTxt,
        joinHandler,
        canEdit,
        editTxt,
        editHandler,
        canCopy,
        copyTxt,
        copyHandler,
        canDelete,
        deleteTxt,
        deleteHandler,
        canMsgChannel,
        msgChannelTxt,
        msgChannelHandler,
        canViewOnZoomEvent,
        viewOnZoomEventTxt,
        viewOnZoomEventHandler,
        isPrivateMeeting,
        canViewDetail,
        isPeriodic,
        isWebinar,
        isFetchingDetail,
    };
}
