import React, { createRef, useState } from 'react';
import './general.scss';
import {
    AGAVE_THEME_TEXT,
    BLOOM_THEME_TEXT,
    CLASSIC_THEME_TEXT,
    GLOBAL_NAV_SETTING_TEXT,
    NAVIGATION_TEXT,
    RESET_TO_DEFAULT,
    ROSE_THEME_TEXT,
    SETTINGS_THEME_POPOVER_1,
    SETTINGS_THEME_POPOVER_2,
    SETTINGS_THEME_POPOVER_3,
    SETTINGS_THEME_TITLE,
    SETTING_THEME_NOTICE,
} from '../../../resource';
import useTabList from '../../../hooks/useTabList';
import { ReactComponent as ClassicTheme } from '../../../assets/images/classic-theme.svg';
import { ReactComponent as BloomTheme } from '../../../assets/images/bloom-theme.svg';
import { ReactComponent as AgaveTheme } from '../../../assets/images/agave-theme.svg';
import { ReactComponent as RoseTheme } from '../../../assets/images/rose-theme.svg';
import { useAppDispatch, useAppSelector } from '../../Phone/types';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { IconQuestionCircle } from '@zoom/icons-react';
import { setUserSettingThemeThunk } from '../redux/setttings-thunk';
import { Button } from '@zoom/zoom-react-ui';

const General = () => {
    const { setData: setOrganizableTabList } = useTabList();

    const [showThemeNotice, setShowThemeNotice] = useState(false);

    const theme = useAppSelector((state) => state.settings.userSettingTheme);

    const dispatch = useAppDispatch();

    const order = ['classic', 'bloom', 'agave', 'rose'];

    const themeRefs = order.reduce((current, color) => {
        current[color] = createRef<HTMLDivElement>();
        return current;
    }, {} as Record<string, React.RefObject<HTMLDivElement>>);

    const handleOnClickReset = () => {
        setOrganizableTabList('reset');
    };

    const handleClickTheme = (theme: string) => {
        dispatch(setUserSettingThemeThunk(theme));
    };

    const onKeyDownShowMore = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            setShowThemeNotice(!showThemeNotice); // Or whatever state you use to toggle visibility
        } else if (e.key === 'Escape') {
            hideThemeNotice();
        }
    };

    const handleThemeKeyDown = (e: React.KeyboardEvent, theme: string, index: number) => {
        if (e.key === 'Enter' || e.key === ' ') {
            dispatch(setUserSettingThemeThunk(theme));
        } else if (e.key === 'ArrowRight') {
            if (index < Object.keys(order).length - 1) {
                themeRefs[order[index + 1]].current.focus();
            }
        } else if (e.key === 'ArrowLeft') {
            if (index > 0) {
                themeRefs[order[index - 1]].current.focus();
            }
        }
    };

    const hideThemeNotice = () => {
        setShowThemeNotice(false);
    };

    return (
        <>
            <div className="general-settings general-settings__theme">
                <div className="general-header general-header__theme">
                    <span className="theme-text">{SETTINGS_THEME_TITLE}</span>
                    <div className="theme-notice">
                        <span className="theme-notice-text">{SETTING_THEME_NOTICE}</span>
                        <OverlayTrigger
                            placement="bottom"
                            show={showThemeNotice}
                            overlay={
                                <Popover>
                                    <Popover.Body style={{ fontSize: '12px' }}>
                                        {SETTINGS_THEME_POPOVER_1}
                                        <br></br>
                                        <br></br>
                                        {SETTINGS_THEME_POPOVER_2}
                                        <br></br>
                                        <br></br>
                                        {SETTINGS_THEME_POPOVER_3}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div>
                                <IconQuestionCircle
                                    role="button"
                                    aria-label={SETTING_THEME_NOTICE}
                                    tabIndex={0}
                                    onKeyDown={onKeyDownShowMore}
                                    onMouseEnter={() => setShowThemeNotice(true)}
                                    onMouseLeave={hideThemeNotice}
                                    onBlur={hideThemeNotice}
                                />

                                <span className="screen-reader-hidden-text" role="status" aria-live="polite">
                                    {showThemeNotice
                                        ? `
                                              ${SETTINGS_THEME_POPOVER_1}
                                              ${SETTINGS_THEME_POPOVER_2}
                                              ${SETTINGS_THEME_POPOVER_3}
                                          `
                                        : ''}
                                </span>
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="color-themes" role="radiogroup">
                    <div
                        ref={themeRefs['classic']}
                        role="radio"
                        className="theme"
                        onClick={() => handleClickTheme('classic')}
                        onKeyDown={(e) => handleThemeKeyDown(e, 'classic', 0)}
                        aria-label="Classic"
                        aria-checked={theme === 'classic'}
                        aria-live="polite"
                        tabIndex={0}
                    >
                        <ClassicTheme className={classNames('theme-color', { selected: theme === 'classic' })} />
                        <span className="classic">{CLASSIC_THEME_TEXT}</span>
                    </div>
                    <div
                        ref={themeRefs['bloom']}
                        role="radio"
                        className="theme"
                        onClick={() => handleClickTheme('bloom')}
                        onKeyDown={(e) => handleThemeKeyDown(e, 'bloom', 1)}
                        aria-label="Bloom"
                        aria-checked={theme === 'bloom'}
                        aria-live="polite"
                        tabIndex={0}
                    >
                        <BloomTheme className={classNames('theme-color', { selected: theme === 'bloom' })} />
                        <span className="bloom">{BLOOM_THEME_TEXT}</span>
                    </div>
                    <div
                        ref={themeRefs['agave']}
                        role="radio"
                        className="theme"
                        onClick={() => handleClickTheme('agave')}
                        onKeyDown={(e) => handleThemeKeyDown(e, 'agave', 2)}
                        aria-label="Agave"
                        aria-checked={theme === 'agave'}
                        aria-live="polite"
                        tabIndex={0}
                    >
                        <AgaveTheme className={classNames('theme-color', { selected: theme === 'agave' })} />
                        <span className="agave">{AGAVE_THEME_TEXT}</span>
                    </div>
                    <div
                        ref={themeRefs['rose']}
                        role="radio"
                        className="theme"
                        onClick={() => handleClickTheme('rose')}
                        onKeyDown={(e) => handleThemeKeyDown(e, 'rose', 3)}
                        aria-label="Rose"
                        aria-checked={theme === 'rose'}
                        aria-live="polite"
                        tabIndex={0}
                    >
                        <RoseTheme className={classNames('theme-color', { selected: theme === 'rose' })} />
                        <span className="rose">{ROSE_THEME_TEXT}</span>
                    </div>
                </div>
            </div>

            <div className="general-settings general-settings__navigation">
                <div className="general-header general-header__navigation">
                    <span className="navigation-text">{NAVIGATION_TEXT}</span>
                </div>
                <div className="navigation-instructions">
                    <span className="navigation-instructions-text">{GLOBAL_NAV_SETTING_TEXT}</span>
                    <Button className="reset-nav" onClick={handleOnClickReset}>
                        {RESET_TO_DEFAULT}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default General;
