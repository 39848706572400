import React, { ChangeEvent, useState } from 'react';
import styles from './create-peraonal-contact.module.scss';
import { Modal } from 'Modal';
import { hideModal } from '../../../store/modal/modal-helper';
import Button from '../../../components/Button/Button';
import Dialog from './Dialog';
import { isUndefined } from 'lodash-es';
import { getContactDisplayName, selectContactByJid } from '../redux';
import { useAppDispatch, useAppSelector } from '../../Phone/types';
import { getUserIdFromJid } from '../../../utils';
import {
    Be_Valid_Email,
    CONTACTS_EMAIL,
    Company_T,
    Comy_Org_Name,
    Create_A_Personal_Contact,
    Create_T,
    EMAIL_ADDRESS,
    Fax_Num,
    First_Last_Name,
    Home_Num,
    Home_Phone,
    Mobile_Num,
    Mobile_Phone,
    Name_Required,
    Name_T,
    Office_Num,
    Office_Phone,
    Other_Ph_Num,
    SAVE,
    Show_Less,
    Show_More,
} from '../../../resource';
import { Cancel_Text } from '../../Meetings/LanguageResource';
import { createPersonalContactThunk, editPersonalContactThunk } from '../redux/personal-contact-thunks';

const identityFunc = (value: any) => value;
const isValidEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
};

const isValidPhoneNumber = (number: string) => {
    if (/[^0-9-+()]/.test(number)) {
        return false;
    }
    return true;
};

interface IProps {
    data: {
        show: boolean;
        type: 'edit' | 'create';
        info: {
            groupId: string;
            jid: string;
        };
    };
}

const CreatePersonalContactDialog = (props: IProps) => {
    const {
        data: { type, info },
    } = props;

    const dispatch = useAppDispatch();
    const contactData = useAppSelector((state) => selectContactByJid(state, info?.jid));
    const personal = contactData?.personal;

    const [expandMore, updateExpandMore] = useState(false);

    const initialState = {
        name: {
            value: contactData ? getContactDisplayName(contactData) : '',
            error: '',
        },
        email: {
            value: contactData?.email || '',
            error: '',
        },
        office: {
            value: personal?.office.number || '',
            error: '',
        },
        home: {
            value: personal?.home.number || '',
            error: '',
        },
        mobile: {
            value: personal?.mobile.number || '',
            error: '',
        },
        fax: {
            value: personal?.fax.number || '',
            error: '',
        },
        other: {
            value: personal?.phone.number || '',
            error: '',
        },
        company: {
            value: contactData?.company || '',
            error: '',
        },
    };

    type EFormValueKeys = keyof typeof initialState;

    const [formValues, updateFormValues] = useState(initialState);

    const updateItemValue = (key: EFormValueKeys, data: { value?: string; error?: string }) => {
        const oldItemValue = formValues[key];
        const newFormValues = Object.assign({}, formValues, {
            [key]: Object.assign({}, oldItemValue, data),
        });
        updateFormValues(newFormValues);
    };

    const baseItems = [
        {
            key: 'name',
            desc: Name_T,
            placeholder: First_Last_Name,

            required: true,
            value: '',
            onChange: identityFunc,
            validate: (value: string) => {
                if (!value) {
                    return {
                        error: Name_Required,
                    };
                }

                if (!value.trim()) {
                    return {
                        error: 'Name is not valid',
                    };
                }
                return {};
            },
        },
        {
            key: 'email',
            desc: EMAIL_ADDRESS,
            placeholder: CONTACTS_EMAIL,
            required: false,
            onChange: identityFunc,
            validate: (value: string) => {
                if (!value) {
                    return {};
                }
                if (!isValidEmail(value)) {
                    return {
                        error: Be_Valid_Email,
                    };
                }
                return {};
            },
        },
        {
            key: 'office',
            desc: Office_Num,
            placeholder: Office_Phone,
            required: false,
            onChange: (value: string) => {
                if (isValidPhoneNumber(value)) {
                    return value.slice(0, 100);
                }
                return undefined;
            },
        },
        {
            key: 'home',
            desc: Home_Num,
            required: false,
            placeholder: Home_Phone,
            onChange: (value: string) => {
                if (isValidPhoneNumber(value)) {
                    return value.slice(0, 100);
                }
                return undefined;
            },
        },
    ];

    const moreItems = [
        {
            key: 'mobile',
            desc: Mobile_Num,
            required: false,
            placeholder: Mobile_Phone,
            onChange: (value: string) => {
                if (isValidPhoneNumber(value)) {
                    return value.slice(0, 100);
                }
                return undefined;
            },
        },
        {
            key: 'fax',
            desc: Fax_Num,
            required: false,
            placeholder: Fax_Num,
            onChange: (value: string) => {
                if (isValidPhoneNumber(value)) {
                    return value.slice(0, 100);
                }
                return undefined;
            },
        },
        {
            key: 'other',
            desc: Other_Ph_Num,
            required: false,
            placeholder: Other_Ph_Num,
            onChange: (value: string) => {
                if (isValidPhoneNumber(value)) {
                    return value.slice(0, 100);
                }
                return undefined;
            },
        },
        {
            key: 'company',
            desc: Company_T,
            required: false,
            placeholder: Comy_Org_Name,
            onChange: (value: string) => {
                return value.slice(0, 400);
            },
        },
    ];

    const allItems = [].concat(baseItems, moreItems);
    const totalItems = expandMore ? allItems : baseItems;

    let isReadyToCreate = false;
    for (const item of allItems) {
        const { validate, key } = item;
        const { value } = formValues[key as EFormValueKeys];
        if (validate?.(value)?.error) {
            isReadyToCreate = false;
            break;
        } else {
            isReadyToCreate = true;
        }
    }

    const closeMe = () => {
        hideModal('personalContact');
    };

    const create = () => {
        const contact = {
            name: formValues['name'].value,
            company: formValues['company'].value,
            email: formValues['email'].value,
            officePhoneNum: formValues['office'].value,
            homePhoneNum: formValues['home'].value,
            fax: formValues['fax'].value,
            phoneNum: formValues['other'].value,
            mobile: formValues['mobile'].value,
        };

        dispatch(createPersonalContactThunk(contact));

        closeMe();
    };

    const save = () => {
        const contact = {
            name: formValues['name'].value,
            company: formValues['company'].value,
            email: formValues['email'].value,
            officePhoneNum: formValues['office'].value,
            homePhoneNum: formValues['home'].value,
            fax: formValues['fax'].value,
            phoneNum: formValues['other'].value,
            mobile: formValues['mobile'].value,
        };

        dispatch(editPersonalContactThunk({ userId: getUserIdFromJid(info?.jid), jid: info?.jid }, contact));

        closeMe();
    };

    return (
        <Modal
            needShowAboveBase={true}
            isOpen={true}
            centered={true}
            needBackdrop={true}
            isDraggable={false}
            onRequestClose={closeMe}
            key={'personal-contact-from-phone'}
        >
            <Dialog className={styles.dialog}>
                <header>{Create_A_Personal_Contact}</header>

                <main>
                    {totalItems.map(({ key, desc, required, placeholder, onChange, validate }) => {
                        const { value, error } = formValues[key as EFormValueKeys];
                        const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
                            const newValue = onChange(e.target.value);
                            if (isUndefined(newValue)) {
                                return;
                            }
                            updateItemValue(key, { value: newValue, error: '' });
                        };

                        const onBlur = () => {
                            if (!validate) {
                                return;
                            }

                            const result = validate(value);

                            if (!result) {
                                return;
                            }

                            if (result?.error) {
                                updateItemValue(key, { error: result.error });
                            } else {
                                updateItemValue(key, { error: '' });
                            }
                        };

                        return (
                            <label className={styles.item} key={key}>
                                <span className={styles.desc}>
                                    <span>{desc}</span>
                                    {required ? <span className={styles.star}>*</span> : null}
                                </span>
                                <input
                                    className={styles.input}
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={onInputChange}
                                    onBlur={onBlur}
                                />
                                {error ? <span className={styles.error}>{error}</span> : null}
                            </label>
                        );
                    })}
                </main>

                <div className={styles.toggle}>
                    <Button onClick={() => updateExpandMore(!expandMore)} size={Button.Size24} type={Button.Text}>
                        {expandMore ? Show_Less : Show_More}
                    </Button>
                </div>

                <footer className={styles.footer}>
                    <Button size={Button.Size24} type={Button.Dark} onClick={closeMe}>
                        {Cancel_Text}
                    </Button>
                    {type === 'create' ? (
                        <Button size={Button.Size24} type={Button.Normal} onClick={create} disabled={!isReadyToCreate}>
                            {Create_T}
                        </Button>
                    ) : null}

                    {type === 'edit' ? (
                        <Button size={Button.Size24} type={Button.Normal} onClick={save} disabled={!isReadyToCreate}>
                            {SAVE}
                        </Button>
                    ) : null}
                </footer>
            </Dialog>
        </Modal>
    );
};

export default CreatePersonalContactDialog;
