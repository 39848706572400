import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { RoutePath } from '../../routes';
import CalendarSidebarForHomePlaceholder from './CalendarSidebarForHomePlaceholder';
import { useAppSelector } from '../../store';

const CalendarSidebarForNonHomePlaceholder = ({ cacheID }: { cacheID: string }) => {
    const showCalendarSidebar = useAppSelector(state => state.calendar.showCalendarSidebar);
    const style: React.CSSProperties = {
        height: '100%'
    }
    const location = useLocation();
    const isHomePath = matchPath(location.pathname, { path: RoutePath.Home, exact: true });
    
    return (!isHomePath && showCalendarSidebar) 
        ? <CalendarSidebarForHomePlaceholder cacheID={cacheID} style={style} /> 
        : null;
}

export default CalendarSidebarForNonHomePlaceholder;