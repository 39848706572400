import React, { ChangeEvent } from 'react';
import styles from './input.module.scss';
import classNames from 'classnames';

interface InputProps {
    value?: string;
    inline?: boolean;
    placeholder: string;
    ariaLabel: string;
    errorMsg?: string;
    onChange?(input: string): void;
    className?: string;
}

const noop = () => {};

const Input = ({ placeholder, ariaLabel, errorMsg, onChange = noop, inline = true, value, className }: InputProps) => {
    const cls = classNames(styles.container, inline ? styles.inline : styles.block, {
        [styles.errored]: errorMsg,
        [className]: className,
    });

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        onChange(value);
    };

    return (
        <div className={cls}>
            <input
                placeholder={placeholder}
                aria-label={ariaLabel}
                className={styles.input}
                onChange={onChangeHandler}
                value={value}
            />
            {errorMsg ? <span className={styles.error}>{errorMsg}</span> : null}
        </div>
    );
};

export default Input;
