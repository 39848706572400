/**
 * for zcc,
 * customer call (sms/phone/chat) will go throuth flow (prefined routine),
 * and the flow routes this call to certain queue.
 * we assign agents to queue.
 * we add zoom apps to queue.
 *
 * so when you have a call coming in, zcc created an engagement including {queueId, agent}
 * and we fetch apps for this engagement by engagment's queueId
 *
 *
 * customer -> flow -> queue -> agent -> engagement -> apps(by queueId)
 */

export default class ZccQueueAndAppsMap {
    private _queueToAppList: Map<string, Array<string>> = null;

    constructor() {}

    setAppListByQueue(queue: string, list: Array<{ id: string }>) {
        if (!this._queueToAppList) {
            this._queueToAppList = new Map();
        }

        // set directly, override existed one
        this._queueToAppList.set(
            queue,
            list.map(({ id }) => id),
        );
    }

    getAppListByQueue(queue: string) {
        if (!this._queueToAppList) {
            return [];
        }

        return this._queueToAppList.get(queue);
    }

    queueHasThisApp(queue: string, appId: string) {
        if (!this._queueToAppList) {
            return false;
        }

        const appList = this._queueToAppList.get(queue) || [];

        return appList.includes(appId);
    }
}
